/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, FC, Fragment, useState } from 'react';
import { useAuth, usePostApiInterceptor, useRedirection } from '../hooks';
import {
  useLoaderStore,
  useUserInformationStore,
  useMasterAudienceTypeElementsStore,
  useMasterGenderElementsStore,
  useMasterInsurancePlanTypeElementsStore,
  useMasterInsurancePlanProviderElementsStore,
  useMasterOperatorElementsStore,
  useMasterRaceAndEthnicityElementsStore,
  useMasterStateElementsStore,
  useMasterStatusElementsStore,
  useMasterReportsElementsStore,
  useMasterTargetingElementsStore,
  useMasterTimeFrameElementsStore,
  useMasterAdditionalOptionElementsStore,
  useMasterProviderSpecialtyElementsStore,
} from '../stores';
import {
  IEachDropdownElementsType,
  ILoaderState,
  IMasterAdditionalOptionDDState,
  IMasterAudienceTypeDDState,
  IMasterDestinationDDState,
  IMasterGenderDDState,
  IMasterInsurancePlanTypeDDState,
  IMasterInsurancePlanProviderDDState,
  IMasterOperatorDDState,
  IMasterProviderSpecialtyDDState,
  IMasterRaceAndEthnicityDDState,
  IMasterSspDDState,
  IMasterStateDDState,
  IMasterStatusDDState,
  IMasterTargetingDDState,
  IMasterTimeFrameDDState,
  IUserInformationState,
  IMasterReportsDDState,
  IMasterLangugageDDState,
} from '../models/interfaces';
import { RoutesEnum } from '../models/enums/apiRoutes';
import {
  useMasterDestinationElementsStore,
  useMasterLanguageElementStore,
  useMasterSSPElementsStore,
} from '../stores/masterData.store';

const AuthGuard: FC<{ component: JSX.Element }> = ({ component }) => {
  const [status, setStatus] = useState(false),
    [isMounted, setIsMounted] = useState<boolean>(false),
    [data, setData] = useState<any>(null);

  const { setLoaderState } = useLoaderStore((state: ILoaderState) => state),
    { setAudienceTypeDDElements } = useMasterAudienceTypeElementsStore(
      (state: IMasterAudienceTypeDDState) => state
    ),
    { setStatusDDElements } = useMasterStatusElementsStore(
      (state: IMasterStatusDDState) => state
    ),
    { setReportsDDElements } = useMasterReportsElementsStore(
      (state:  IMasterReportsDDState) => state
    ),
   
    { setGenderDDElements } = useMasterGenderElementsStore(
      (state: IMasterGenderDDState) => state
    ),
    { setRaceAndEthnicityDDElements } = useMasterRaceAndEthnicityElementsStore(
      (state: IMasterRaceAndEthnicityDDState) => state
    ),
    { setStateDDElements } = useMasterStateElementsStore(
      (state: IMasterStateDDState) => state
    ),
    { setTargetingDDElements } = useMasterTargetingElementsStore(
      (state: IMasterTargetingDDState) => state
    ),
    { setLanguageDDElements } = useMasterLanguageElementStore(
      (state: IMasterLangugageDDState) => state
    ),
    { setOperatorDDElements } = useMasterOperatorElementsStore(
      (state: IMasterOperatorDDState) => state
    ),
    { setInsurancePlanTypeDDElements } =
      useMasterInsurancePlanTypeElementsStore(
        (state: IMasterInsurancePlanTypeDDState) => state
      ),
    { setInsurancePlanProviderDDElements } =
      useMasterInsurancePlanProviderElementsStore(
        (state: IMasterInsurancePlanProviderDDState) => state
      ),
    { setTimeFrameDDElements } = useMasterTimeFrameElementsStore(
      (state: IMasterTimeFrameDDState) => state
    ),
    { setUserInformation, setUserIsLoggedIn } = useUserInformationStore(
      (state: IUserInformationState) => state
    ),
    { setAdditionalOptionDDElements } = useMasterAdditionalOptionElementsStore(
      (state: IMasterAdditionalOptionDDState) => state
    ),
    { setProviderSpecialtyDDElements } =
      useMasterProviderSpecialtyElementsStore(
        (state: IMasterProviderSpecialtyDDState) => state
      ),
    { setSspDDElements } = useMasterSSPElementsStore(
      (state: IMasterSspDDState) => state
    ),
    { setDestinationDDElements } = useMasterDestinationElementsStore(
      (state: IMasterDestinationDDState) => state
    );

  const { getUserInfo } = useAuth(),
    { redirectLogin } = useRedirection();

  usePostApiInterceptor(
    isMounted,
    null,
    RoutesEnum.GET_MASTER_DATA,
    (pageStatus: number, baseData: any) => {
      if (pageStatus === 200 && baseData) {
        const {
          audience_type,
          gender,
          insurance_plan_type,
          operator,
          race_ethnicity,
          insurance_provider,
          state,
          status,
          language,
          targeting,
          timeframe,
          additional_option,
          provider_specialty,
          ssp,
          activation_destination,
          research_generate_fields
        } = baseData;
        
        setTargetingDDElements(formatMasterData(targeting, 1));
        setStatusDDElements(formatMasterData(status, 2));
        setReportsDDElements(formatMasterData(research_generate_fields, 2));
        setOperatorDDElements(formatMasterData(operator, 3));
        setInsurancePlanTypeDDElements(
          formatMasterData(insurance_plan_type, 4)
        );
        setLanguageDDElements(language);
        setInsurancePlanProviderDDElements(insurance_provider);
        setAudienceTypeDDElements(formatMasterData(audience_type));
        setGenderDDElements(formatMasterData(gender));
        setRaceAndEthnicityDDElements(formatMasterData(race_ethnicity));
        setStateDDElements(formatMasterData(state));
        setTimeFrameDDElements(timeframe);
        setAdditionalOptionDDElements(additional_option);
        setProviderSpecialtyDDElements(provider_specialty);
        setSspDDElements(ssp);
        setDestinationDDElements(activation_destination);

        setData(baseData);
      }
      setIsMounted(false);
      setLoaderState(false);
    }
  );

  useEffect(() => {
    checkCookie();
  }, [component]);

  const checkCookie = async () => {
    try {
      const userInfo = getUserInfo();

      if (!userInfo?.user_id) {
        redirectLogin();
      } else if (!data) {
        setLoaderState(true);
        setIsMounted(true);
      }

      if (userInfo) {
        setUserInformation(userInfo);
        setUserIsLoggedIn(true);
      }

      setStatus(true);
      return;
    } catch (error) {
      redirectLogin();
    }
  };

  return status ? <Fragment>{component}</Fragment> : <></>;
};

export default AuthGuard;

const formatMasterData = (data: any, specificType: 0 | 1 | 2 | 3 | 4 = 0) => {
  switch (specificType) {
    case 1: {
      const temp = data.map((el: IEachDropdownElementsType) => {
        if (el.value === 4 || el.value === 19) {
          return {
            ...el,
            disabled: true,
            enableId: 1,
          };
        }
        if (el.value === 6 || el.value === 7) {
          return {
            ...el,
            disabled: true,
            enableId: 2,
          };
        }

        if (el.value === 8) {
          return {
            ...el,
            disabled: true,
            enableId: 3,
          };
        }
        return {
          ...el,
        };
      });
      return temp;
    }
    case 2: {
      const temp = data.map((el: IEachDropdownElementsType) => {
        return {
          ...el,
          className: el.label.toLowerCase(),
        };
      });
      return temp;
    }
    case 3: {
      const temp = data.map((el: IEachDropdownElementsType) => {
        if (el.order_id === 1) {
          return {
            ...el,
            isDefault: true,
          };
        }
        return {
          ...el,
        };
      });
      return temp;
    }
    case 4: {
      const temp = [{ value: -1, label: 'All', order_id: 0 }, ...data];
      return temp;
    }
    default:
      return data;
  }
};
