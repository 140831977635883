import {
  IMasterAdditionalOptionDDState,
  IMasterDestinationDDState,
  IMasterDmaDDState,
  IMasterLangugageDDState,
  IMasterProviderSpecialtyDDState,
  IMasterSspDDState,
  dmaDDType,
} from './../models/interfaces/store.interface';
import { create } from 'zustand';
import {
  IEachDropdownElementsType,
  IEachInsurancePlanTypeDropdownElementsType,
  IEachInsurancePlanProviderDropdownElementsType,
  IEachStateDropdownElementsType,
  IMasterAudienceTypeDDState,
  IMasterGenderDDState,
  IMasterInsurancePlanTypeDDState,
  IMasterInsurancePlanProviderDDState,
  IMasterOperatorDDState,
  IMasterRaceAndEthnicityDDState,
  IMasterStateDDState,
  IMasterStatusDDState,
  IMasterReportsDDState,
  IMasterTargetingDDState,
  IMasterTimeFrameDDState,
} from '../models/interfaces';

export const useMasterAudienceTypeElementsStore =
  create<IMasterAudienceTypeDDState>((set) => ({
    audienceTypeDDElements: [],
    setAudienceTypeDDElements: (value: IEachDropdownElementsType[]) =>
      set({ audienceTypeDDElements: value }),
  }));

export const useMasterStatusElementsStore = create<IMasterStatusDDState>(
  (set) => ({
    statusDDElements: [],
    setStatusDDElements: (value: IEachDropdownElementsType[]) =>
      set({ statusDDElements: value }),
  })
);
export const useMasterReportsElementsStore = create<IMasterReportsDDState>(
  (set) => ({
    reportsDDElements: [],
    setReportsDDElements: (value: IEachDropdownElementsType[]) =>
      set({ reportsDDElements: value }),
  })
);

export const useMasterGenderElementsStore = create<IMasterGenderDDState>(
  (set) => ({
    genderDDElements: [],
    setGenderDDElements: (value: IEachDropdownElementsType[]) =>
      set({ genderDDElements: value }),
  })
);

export const useMasterRaceAndEthnicityElementsStore =
  create<IMasterRaceAndEthnicityDDState>((set) => ({
    raceAndEthnicityDDElements: [],
    setRaceAndEthnicityDDElements: (value: IEachDropdownElementsType[]) =>
      set({ raceAndEthnicityDDElements: value }),
  }));

export const useMasterStateElementsStore = create<IMasterStateDDState>(
  (set) => ({
    stateDDElements: [],
    setStateDDElements: (value: IEachStateDropdownElementsType[]) =>
      set({ stateDDElements: value }),
  })
);

export const useMasterDmaElementsStore = create<IMasterDmaDDState>((set) => ({
  selectedStates: [],
  setSelectedStates: (value: (number | string)[]) =>
    set({ selectedStates: value }),

  dmaDDElements: {},
  setDmaDDElements: (value: dmaDDType) => set({ dmaDDElements: value }),
  updateDmaDDElements: (
    setId: number,
    selectedState: (number | string)[],
    stateDD: IEachStateDropdownElementsType[]
  ) =>
    set((state) => {
      let dma: IEachDropdownElementsType[] = [];

      stateDD?.forEach((el) => {
        if (
          (selectedState?.length && selectedState.includes(el.value)) ||
          !selectedState?.length
        ) {
          dma = [...dma, ...el.dma];
        }
      });

      dma = findUniqueAmdSortedDma(dma);

      return {
        dmaDDElements: {
          ...state.dmaDDElements,
          [setId]: dma,
        },
      };
    }),
}));

export const useMasterTargetingElementsStore = create<IMasterTargetingDDState>(
  (set) => ({
    targetingDDElements: [],
    setTargetingDDElements: (value: IEachDropdownElementsType[]) =>
      set({ targetingDDElements: value }),
  })
);

export const useMasterLanguageElementStore = create<IMasterLangugageDDState>(
  (set) => ({
    languageDDElements: [],
    setLanguageDDElements: (value: IEachDropdownElementsType[]) =>
      set({ languageDDElements: value }),
  })
)

export const useMasterOperatorElementsStore = create<IMasterOperatorDDState>(
  (set) => ({
    operatorDDElements: [],
    setOperatorDDElements: (value: IEachDropdownElementsType[]) =>
      set({ operatorDDElements: value }),
  })
);

export const useMasterInsurancePlanTypeElementsStore =
  create<IMasterInsurancePlanTypeDDState>((set) => ({
    insurancePlanTypeDDElements: [],
    setInsurancePlanTypeDDElements: (
      value: IEachInsurancePlanTypeDropdownElementsType[]
    ) => set({ insurancePlanTypeDDElements: value }),
  }));

export const useMasterInsurancePlanProviderElementsStore =
  create<IMasterInsurancePlanProviderDDState>((set) => ({
    insurancePlanProviderDDElements: [],
    setInsurancePlanProviderDDElements: (
      value: IEachInsurancePlanProviderDropdownElementsType[]
    ) => set({ insurancePlanProviderDDElements: value }),
  }));

export const useMasterTimeFrameElementsStore = create<IMasterTimeFrameDDState>(
  (set) => ({
    timeFrameDDElements: [],
    setTimeFrameDDElements: (value: IEachDropdownElementsType[]) =>
      set({ timeFrameDDElements: value }),
  })
);

const findUniqueAmdSortedDma = (dma: IEachDropdownElementsType[]) => {
  return dma
    .filter(
      (el, index, self) =>
        index === self.findIndex((t) => t.dma_id === el.dma_id)
    )
    .sort((a: any, b: any) => a.dma_id - b.dma_id);
};

export const useMasterAdditionalOptionElementsStore =
  create<IMasterAdditionalOptionDDState>((set) => ({
    additionalOptionDDElements: [],
    setAdditionalOptionDDElements: (value: IEachDropdownElementsType[]) =>
      set({ additionalOptionDDElements: value }),
  }));

export const useMasterProviderSpecialtyElementsStore =
  create<IMasterProviderSpecialtyDDState>((set) => ({
    providerSpecialtyDDElements: [],
    setProviderSpecialtyDDElements: (value: IEachDropdownElementsType[]) =>
      set({ providerSpecialtyDDElements: value }),
  }));

export const useMasterSSPElementsStore = create<IMasterSspDDState>((set) => ({
  sspDDElements: [],
  setSspDDElements: (value: IEachDropdownElementsType[]) =>
    set({ sspDDElements: value }),
}));

export const useMasterDestinationElementsStore =
  create<IMasterDestinationDDState>((set) => ({
    destinationDDElements: [],
    setDestinationDDElements: (value: IEachDropdownElementsType[]) =>
      set({ destinationDDElements: value }),
  }));
