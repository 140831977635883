import { Route } from 'react-router-dom';
import { UnAuthGuard } from '../guards';
import { LoginPage } from '../components/pages';

const UnAuthRoutes = [
  <Route
    key="Login"
    path="/login"
    element={<UnAuthGuard component={<LoginPage />} />}
  ></Route>,
];

export default UnAuthRoutes;
