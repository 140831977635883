import { FC, Fragment, useEffect, useState } from 'react';
import { PageHeader } from '../../../sharedComponent';
import {
  IResearchListingParam,
  ILoaderState,
} from '../../../../models/interfaces';
import { useLoaderStore } from '../../../../stores';
import { ResearchListingFilter, ResearchListingTable } from './';
import { RoutesEnum } from '../../../../models/enums/apiRoutes';
import { usePostApiInterceptor,useRedirection } from '../../../../hooks';

type researchBodyType = {
  report_id:string|null;
  end_date: string | null;
  start_date: string | null;
  search: string | null;
  order_column: string | null;
  order_direction: string | null;
  limit: number;
  off_set: number;
};

const baseListingParam = {
  report_id: null,
  sortOrder: 'DESC',
  sortColumn: 'created_on',
  pageNumber: 1,
  rowsPerPage: 15,
  dateRange: null,
};

const ResearchListingWrap: FC = () => {
  const [researchListingParam, setResearchListingParam] =
    useState<IResearchListingParam>(baseListingParam);

  const [body, setBody] = useState<researchBodyType | null>(null),
    [isMounted, setIsMounted] = useState<boolean>(false);

  const { data, isLoading } = usePostApiInterceptor(
    isMounted,
    JSON.stringify(body),
    RoutesEnum.GET_RESEARCH_LIST,
    () => {
      setIsMounted(false);
    }
  ),
  { redirectAddResearch } = useRedirection();
  const { loaderState } = useLoaderStore((state: ILoaderState) => state);

  useEffect(() => {
    setIsMounted(true);
  }, [body]);

  useEffect(() => {
    const newParams: researchBodyType = {
      end_date: researchListingParam.dateRange?.length
        ? researchListingParam.dateRange[1]
        : null,
      start_date: researchListingParam.dateRange?.length
        ? researchListingParam.dateRange[0]
        : null,
      report_id: researchListingParam.report_id,
      limit: researchListingParam.rowsPerPage,
      off_set:
        (researchListingParam.pageNumber - 1) *
        researchListingParam.rowsPerPage,
      order_column: researchListingParam.sortColumn,
      order_direction: researchListingParam.sortOrder,
      search: null,
    };

    setBody(newParams);
  }, [researchListingParam]);
  return (
    <div className="audienceCommonWrap">
      <PageHeader title="Research" />
      <div className="audienceListingPageContent">
        
        {!loaderState && (
          <Fragment>
            <div className="filterWrap">
              <ResearchListingFilter
                filterData={researchListingParam}
                setFilterData={setResearchListingParam}
              />
              <button
                className="primaryBtn addAudienceBtn"
                onClick={() => redirectAddResearch()}
              >
                New Research
              </button>
            </div>
            
            <ResearchListingTable
              researchData={data}
              isLoading={isLoading}
              paginationAndSortingData={researchListingParam}
              setPaginationAndSortingData={setResearchListingParam}
            />
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default ResearchListingWrap;
