import React, { Fragment, useEffect, useState } from 'react';
import { PageHeader } from '../../../sharedComponent';
import {
  useFeatureSetDataCheckStore,
  useFeatureSetDataHandlingStore,
  useLoaderStore,
  useMasterAdditionalOptionElementsStore,
  useMasterAudienceTypeElementsStore,
  useUserInformationStore,
} from '../../../../stores';
import {
  IAudienceSetUpState,
  IFeatureSetDataCheckState,
  IFeatureSetDataState,
  IFeatureSetElement,
  ILoaderState,
  IMasterAdditionalOptionDDState,
  IMasterAudienceTypeDDState,
  IUserInformationState,
} from '../../../../models/interfaces';
import {
  DefaultFeatureSet,
  DefinedFeatureSet,
  GlobalFeatureSet,
} from '../../AudienceSetup/AudienceSetupComponents';
import { useAudienceSetUp } from '../../../../stores/audienceSets.store';
import { Button } from 'antd';
import {
  usePostApiInterceptor,
  useRedirection,
  useShowToasterMessage,
} from '../../../../hooks';
import { RoutesEnum } from '../../../../models/enums/apiRoutes';

function separateFeatureSetsByTargetingId(data: any): {
  withTargetingId: any[];
  withoutTargetingId: any[];
} {
  const withTargetingId: any[] = [];
  const withoutTargetingId: any[] = [];

  data.forEach((featureSet: any) => {
    if (
      featureSet.targeting.some(
        (target: any) =>
          target.targeting_id === 1 ||
          target.targeting_id === 2 ||
          target.targeting_id === 3 ||
          target.targeting_id === 4 ||
          target.targeting_id === 5 ||
          target.targeting_id === 15 ||
          target.targeting_id === 19
      )
    ) {
      withTargetingId.push(featureSet);
    } else {
      withoutTargetingId.push(featureSet);
    }
  });

  return { withTargetingId, withoutTargetingId };
}

const AudienceWrap = () => {
  const { loaderState } = useLoaderStore((state: ILoaderState) => state),
    { audienceTypeDDElements } = useMasterAudienceTypeElementsStore(
      (state: IMasterAudienceTypeDDState) => state
    ),
    { additionalOptionDDElements } = useMasterAdditionalOptionElementsStore(
      (state: IMasterAdditionalOptionDDState) => state
    ),
    { ifUpdated } = useFeatureSetDataCheckStore(
      (state: IFeatureSetDataCheckState) => state
    ),
    { updatedDataSet } = useFeatureSetDataHandlingStore(
      (state: IFeatureSetDataState) => state
    ),
    { userInformation } = useUserInformationStore(
      (state: IUserInformationState) => state
    ),
    { redirectAudienceListing } = useRedirection(),
    {
      audienceTitle,
      audienceBrand,
      audienceAgency,
      setAudienceAgency,
      setAudiencebrand,
      setAudienceTitle,
      advertiser,
      setAdvertiser,
    } = useAudienceSetUp((state: IAudienceSetUpState) => state);

  const [selectedAudienceType, setSelectedAudienceType] = useState<
      number | null
    >(null),
    [selectedAdditionalOpts, setSelectedAdditionalOpts] = useState<
      (number | string)[]
    >([]),
    [body, setBody] = useState<string | null>(null),
    [prevSetId, setPrevSetId] = useState<number>(0),
    [featureSetCount, setFeatureSetCount] = useState<number>(1),
    [featureSets, setFeatureSets] = useState<IFeatureSetElement[]>([]),
    [isMounted, setIsMounted] = useState<boolean>(false),
    [messageObj, setMessageObj] = useState<any>({ isShowing: false }),
    [globalFeatureSets, setGlobalFeatureSets] = useState<IFeatureSetElement>({
      setId: 0,
      orderId: 0,
    });

  useEffect(() => {
    return () => {
      setAudienceTitle('');
      setAudiencebrand('');
      setAudiencebrand('');
      setAdvertiser('');
      setAudienceAgency('');
      setFeatureSets([]);
      setFeatureSetCount(1);
      setSelectedAudienceType(null);
      // redirectAudienceListing();
    };
  }, []);

  const { contextHolder } = useShowToasterMessage(messageObj);

  usePostApiInterceptor(
    isMounted,
    body,
    RoutesEnum.SAVE_FEATURE_SET_DATA,
    (status: number, data: any, error: any) => {
      setBody('');
      setIsMounted(false);
      if (data && !error) {
        redirectAudienceListing();
      } else {
        updateErrorMessage('error', 'Error occurred while saving Audience.');
      }
    }
  );

  const updateErrorMessage = (type: string, messagecontent: string) => {
    setMessageObj({
      key: 'save',
      isShowing: true,
      type,
      messagecontent,
      duration: 5,
    });
  };

  useEffect(() => {
    if (ifUpdated) {
      const {
        title,
        audience_type_id,
        additional_option_id,
        agency: audience_agency,
        brand: audience_brand,
        advertiser,
        feature_sets,
      } = updatedDataSet;
      setAudienceAgency(audience_agency);
      setAudiencebrand(audience_brand);
      setAudienceTitle(title);
      setAdvertiser(advertiser);
      setSelectedAudienceType(audience_type_id);
      setSelectedAdditionalOpts(additional_option_id);

      const { withTargetingId, withoutTargetingId } =
        separateFeatureSetsByTargetingId(feature_sets);
      if (feature_sets?.length) {
        const newFeatureSets: IFeatureSetElement[] = [];
        let largestSetId = 0;
        withoutTargetingId
          .sort((a: any, b: any) => a.feature_set_rank - b.feature_set_rank)
          .forEach((el: any, index: number) => {
            if (el.feature_set_id > largestSetId) {
              largestSetId = el.feature_set_id;
            }
            newFeatureSets.push({
              setId: el.feature_set_id,
              orderId: index + 1,
            });
          });
        setPrevSetId(largestSetId + 1);
        setFeatureSetCount(withoutTargetingId.length);
        setGlobalFeatureSets({
          setId: withTargetingId.length ? withTargetingId[0].feature_set_id : 1,
          orderId: 1,
          element: createGlobalElem(
            withTargetingId.length ? withTargetingId[0].feature_set_id : 1,
            1
          ),
        });
        setFeatureSets(
          createFeatureSet(newFeatureSets, withoutTargetingId.length)
        );
      }
    }
  }, [ifUpdated]);

  const createFeatureSet = (fs?: IFeatureSetElement[], fsCount?: number) => {
    const tempFeatureSets: IFeatureSetElement[] = fs?.length
        ? [...fs]
        : [...featureSets],
      newFeatureSets: IFeatureSetElement[] = [];

    for (let i = 0; i < (fsCount ?? featureSetCount); i++) {
      if (i < tempFeatureSets.length) {
        const { setId, orderId } = tempFeatureSets[i];
        newFeatureSets.push({
          setId: setId,
          orderId: orderId,
          element: createNewElem(setId, orderId, fsCount),
        });
      } else {
        const newSetId = prevSetId + 1;
        setPrevSetId(newSetId);

        newFeatureSets.push({
          setId: newSetId,
          orderId: i + 1,
          element: createNewElem(newSetId, i + 1),
        });
      }
    }
    return newFeatureSets;
  };

  const createNewElem = (
    setId: number,
    orderId: number,
    setCount: number | null = null
  ) => {
    return (
      <div>
        <DefinedFeatureSet
          featureSetNumber={setId}
          featureSetOrderNumber={orderId}
          featureSetCount={setCount ?? featureSetCount}
          selectedAudienceType={selectedAudienceType}
          disabled={true}
        />
      </div>
    );
  };

  const handleCancel = () => {
    setAudienceTitle('');
    setAudiencebrand('');
    setAudiencebrand('');
    setAdvertiser('');
    setAudienceAgency('');
    setFeatureSets([]);
    setFeatureSetCount(1);
    setSelectedAudienceType(null);
    redirectAudienceListing();
  };

  const handleSave = () => {
    const overAllData = {
      audience_id: updatedDataSet.audience_id,
      user_id: userInformation.user_id,
      audience_type_id: updatedDataSet.audience_type_id,
      title: audienceTitle,
      agency: audienceAgency,
      brand: audienceBrand,
      advertiser: advertiser,
      status_id: updatedDataSet.status_id,
      additional_option_id: selectedAdditionalOpts,
      feature_sets: updatedDataSet?.feature_sets?.length
        ? updatedDataSet?.feature_sets
        : [],
      is_edited: 1,
    };
    setBody(JSON.stringify(overAllData));
    setIsMounted(true);
  };

  const createGlobalElem = (setId: number, orderId: number) => {
    return (
      <GlobalFeatureSet
        featureSetNumber={setId}
        featureSetOrderNumber={orderId}
        selectedAudienceType={selectedAudienceType}
        disabled={true}
      />
    );
  };

  return (
    <>
      <div className="audienceCommonWrap">
        <PageHeader title="View Audience" />
        {contextHolder}
        {!loaderState && (
          <>
            <DefaultFeatureSet
              disabled={true}
              defaultTitle={audienceTitle}
              setTitle={setAudienceTitle}
              audienceType={selectedAudienceType}
              setAudienceType={(value: any) => setSelectedAudienceType(value)}
              advertiser={advertiser}
              setAdvertiser={setAdvertiser}
              defaultAgency={audienceAgency}
              setAgency={setAudienceAgency}
              defaultBrand={audienceBrand}
              setBrand={setAudiencebrand}
              selectedAdditionalOpts={selectedAdditionalOpts}
              setSelectedAdditionalOpts={setSelectedAdditionalOpts}
              audienceTypeDDElements={audienceTypeDDElements}
              additionalOptionDDElements={additionalOptionDDElements}
            />

            <div className="targetableFeatureAndAudienceInsight">
              <div className="featureSetWhole">
                <div>{globalFeatureSets.element}</div>
                {featureSetCount > 0 &&
                  featureSets.map((el: IFeatureSetElement) => (
                    <Fragment key={el.setId}>{el.element}</Fragment>
                  ))}
              </div>
            </div>
          </>
        )}
      </div>
      <div className="newFeatureSetBtn">
        <Button
          type="text"
          size="large"
          onClick={handleCancel}
          className="noBGBtn"
        >
          Cancel
        </Button>
        <Button
          type="primary"
          size="large"
          disabled={
            !(
              selectedAudienceType &&
              audienceTitle &&
              audienceAgency &&
              audienceBrand &&
              advertiser
            )
          }
          className="dropdownSaveButton primaryBtn"
          onClick={handleSave}
          loading={isMounted}
        >
          Save
        </Button>
      </div>
    </>
  );
};

export default AudienceWrap;
