import {FC} from 'react';
import { LeftPanel } from '../LeftNavPanel';
import { ActiveAudienceListingWrap } from './ListingComponents';

const ActiveAudienceListingPage: FC<{}> = () => {
  document.title = 'Activations - BranchLab';

  return (
    <>
      <LeftPanel defaultSelectedKeys={['3']} />
      <ActiveAudienceListingWrap />
    </>
  );
};

export default ActiveAudienceListingPage;