import { FC, useEffect, useState, Fragment } from 'react';
import DefinedFeatureSet from './DefinedFeatureSet';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import {
  useCheckDependentTargetingSelectedStore,
  useCheckIfDataModifiedStore,
  useCheckSavingEnabilityStore,
  useFeatureSetDataCheckStore,
  useFeatureSetDataHandlingStore,
  useLoaderStore,
  useUserInformationStore,
} from '../../../../stores';
import {
  IAudienceSaveMethods,
  IAudienceSetUpState,
  IAudienceState,
  ICheckIfDataModifiedState,
  IDependentTargetingSelectedState,
  IEnableSaveState,
  IFeatureSetDataCheckState,
  IFeatureSetDataState,
  IFeatureSetElement,
  ILoaderState,
  IUserInformationState,
} from '../../../../models/interfaces';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd';
import {
  useAudienceSaveMethods,
  useAudienceSetUp,
  useAudienceSets,
} from '../../../../stores/audienceSets.store';
import { useParams } from 'react-router-dom';
import {
  useNotificationMessage,
  usePostApiInterceptor,
  useRedirection,
} from '../../../../hooks';
import { RoutesEnum } from '../../../../models/enums/apiRoutes';
import GlobalFeatureSet from './GlobalFeatureSet';

function separateFeatureSetsByTargetingId(data: any): {
  withTargetingId: any[];
  withoutTargetingId: any[];
} {
  const withTargetingId: any[] = [];
  const withoutTargetingId: any[] = [];
  data.forEach((featureSet: any) => {
    if (featureSet?.is_global) {
      withTargetingId.push(featureSet);
    } else {
      withoutTargetingId.push(featureSet);
    }
  });

  return { withTargetingId, withoutTargetingId };
}

const TargetableFeatureSetup: FC<{
  selectedAudienceType: number | null;
  selectedAdditionalOpts: (number | string)[];
  setIsClone: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedAdditionalOpts: React.Dispatch<
    React.SetStateAction<(number | string)[]>
  >;
  setModalToOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setModalToOpenForCancel: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedAudienceType: React.Dispatch<React.SetStateAction<number | null>>;
  setFocusOnTitle: React.Dispatch<React.SetStateAction<0 | 1 | 2>>;
  setMessageObj: React.Dispatch<React.SetStateAction<any>>;
}> = ({
  setIsClone,
  setMessageObj,
  setModalToOpen,
  setFocusOnTitle,
  selectedAudienceType,
  selectedAdditionalOpts,
  setSelectedAudienceType,
  setModalToOpenForCancel,
  setSelectedAdditionalOpts,
}) => {
  const { id } = useParams();

  const { ifUpdated, setIsInitiatedSaving, setIfUpdated } =
      useFeatureSetDataCheckStore((state: IFeatureSetDataCheckState) => state),
    {
      isEnableSaveForEachSet,
      setIsEnableSaveForEachSet,
      setIisFeatureSetsFilled,
      isFeatureSetsFilled,
    } = useCheckSavingEnabilityStore((state: IEnableSaveState) => state),
    {
      isDependentTargetingSelectedForEachSet,
      setIsDependentTargetingSelectedForEachSet,
    } = useCheckDependentTargetingSelectedStore(
      (state: IDependentTargetingSelectedState) => state
    ),
    {
      audienceTitle,
      audienceAgency,
      audienceBrand,
      advertiser,
      setAdvertiser,
      setAudienceAgency,
      setAudiencebrand,
      setAudienceTitle,
      setAudiencePageNumber,
    } = useAudienceSetUp((state: IAudienceSetUpState) => state),
    { isSaveInitiated, setIsSaveInitiated } = useAudienceSaveMethods(
      (state: IAudienceSaveMethods) => state
    ),
    {
      isDuplicated,
      setIsDataSaved,
      globalFeatureData,
      setGlobalFeatureCount,
      selectedSavingOpt,
      setIsDuplicated,
      isCancelled,
      setSelectedSavingOpt,
      setIsCancelled,
      setGlobalFeatureData,
      firstFeatureNumber,
      setFirstFeatureNumber,
      setGlobalFeatureTargetting,
      isBacked,
      setGlobalFeatureSetData,
      globalFeatureCount,
    } = useAudienceSets((state: IAudienceState) => state),
    { updatedDataSet, savingDataSet } = useFeatureSetDataHandlingStore(
      (state: IFeatureSetDataState) => state
    ),
    { userInformation } = useUserInformationStore(
      (state: IUserInformationState) => state
    ),
    { setShowBlankLoaderState } = useLoaderStore(
      (state: ILoaderState) => state
    ),
    { setIfDataModified } = useCheckIfDataModifiedStore(
      (state: ICheckIfDataModifiedState) => state
    ),
    {
      resetAllStates,
      redirectAudienceListing,
      redirectAudienceActivationSetup,
    } = useRedirection();

  const [featureSetCount, setFeatureSetCount] = useState<number>(0),
    [selectedStatus, setSelectedStatus] = useState<number | null>(1),
    [featureSets, setFeatureSets] = useState<IFeatureSetElement[]>([]),
    [prevSetId, setPrevSetId] = useState<number>(0),
    [deletingSetId, setDeletingSetId] = useState<number[] | null>(null),
    [audienceId, setAudienceId] = useState<number>(0),
    [body, setBody] = useState<string | null>(''),
    [isMounted, setIsMounted] = useState<boolean>(false),
    [notificationObj, setNotificationObj] = useState<any>({ isShowing: false }),
    [globalFeatureSets, setGlobalFeatureSets] = useState<IFeatureSetElement>({
      setId: 0,
      orderId: 0,
    });

  const { context } = useNotificationMessage(notificationObj);

  useEffect(() => {
    return () => {
      setIsSaveInitiated(false);
      setFeatureSets([]);
      setFeatureSetCount(0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isBacked && globalFeatureData) {
      setFeatureSetCount(globalFeatureCount);
      setFeatureSets(createFeatureSet(globalFeatureData, globalFeatureCount));
      setPrevSetId(globalFeatureCount);
      setGlobalFeatureSets({
        setId: 0,
        orderId: 1,
        element: createGlobalElem(0, 0),
      });
      setIsEnableSaveForEachSet({});
      setGlobalFeatureCount(0);
      setGlobalFeatureData(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isCancelled) {
      setFeatureSetCount(1);
      setIsCancelled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCancelled]);

  const featureSetBody = (feature_sets: IFeatureSetElement[]) => {
    const { withTargetingId, withoutTargetingId } =
      separateFeatureSetsByTargetingId(feature_sets)
    if (feature_sets?.length) {
      const newFeatureSets: IFeatureSetElement[] = [];
      let largestSetId = 0;
      withoutTargetingId
        .sort((a: any, b: any) => a.feature_set_rank - b.feature_set_rank)
        .forEach((el: any, index: number) => {
          if (el.feature_set_id > largestSetId) {
            largestSetId = el.feature_set_id;
          }
          newFeatureSets.push({
            setId: el.feature_set_id,
            orderId: index + 1,
          });
        });
      setPrevSetId(largestSetId + 1);
      setFeatureSetCount(withoutTargetingId.length);
      setGlobalFeatureSets({
        setId: withTargetingId.length ? withTargetingId[0].feature_set_id : 1,
        orderId: 1,
        element: createGlobalElem(
          withTargetingId.length ? withTargetingId[0].feature_set_id : 1,
          1
        ),
      });
      setFeatureSets(
        createFeatureSet(newFeatureSets, withoutTargetingId.length)
      );
    } else {
      setGlobalFeatureSets({
        setId: 0,
        orderId: 1,
        element: createGlobalElem(0, 0),
      });
    }
  };

  useEffect(() => {
    if (ifUpdated) {
      setShowBlankLoaderState(false);
      const { audience_id, status_id, feature_sets } = updatedDataSet;
      setAudienceId(audience_id);
      setSelectedStatus(status_id);
      featureSetBody(feature_sets);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ifUpdated]);

  useEffect(() => {
    if (body) {
      setShowBlankLoaderState(true);
      setIsMounted(true);
      setNotificationObj({
        isShowing: true,
        type: 'success',
        messageContent: 'Audience Saved',
      });
    }
    body && setIsMounted(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [body]);

  useEffect(() => {
    if (isBacked) {
      setGlobalFeatureCount(featureSetCount);
      setGlobalFeatureData(featureSets);
      setGlobalFeatureSetData(globalFeatureSets);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBacked, featureSetCount]);

  usePostApiInterceptor(
    isMounted,
    body,
    RoutesEnum.SAVE_FEATURE_SET_DATA,
    (status: number, data: any, error: any) => {
      setBody('');
      setIsMounted(false);
      setIsInitiatedSaving(false);
      setNotificationObj({ isShowing: false });
      setIsSaveInitiated(false);
      setShowBlankLoaderState(false);
      if (data && !error) {
        setIsDataSaved(true);
        eventAfterSave(data.audience_id);
      } else {
        updateErrorMessage('error', 'Error occurred while saving Audience.');
      }
    }
  );

  const updateErrorMessage = (type: string, messagecontent: string) => {
    setMessageObj({
      key: 'save',
      isShowing: true,
      type,
      messagecontent,
      duration: 5,
    });
  };

  useEffect(() => {
    if (
      !id &&
      featureSetCount > 0 &&
      featureSetCount > featureSets.length &&
      !globalFeatureData
    ) {
      setFeatureSets(createFeatureSet());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featureSetCount]);

  const createFeatureSet = (fs?: IFeatureSetElement[], fsCount?: number) => {
    const tempFeatureSets: IFeatureSetElement[] = fs?.length
        ? [...fs]
        : [...featureSets],
      newFeatureSets: IFeatureSetElement[] = [];

    for (let i = 0; i < (fsCount ?? featureSetCount); i++) {
      if (i < tempFeatureSets.length) {
        if (i === 0 && !firstFeatureNumber) {
          setFirstFeatureNumber(tempFeatureSets[i].orderId);
        }
        const { setId, orderId } = tempFeatureSets[i];
        newFeatureSets.push({
          setId: setId,
          orderId: orderId,
          element: createNewElem(setId, orderId, fsCount),
        });
      } else {
        const newSetId = prevSetId + 1;
        setPrevSetId(newSetId);
        newFeatureSets.push({
          setId: newSetId,
          orderId: i + 1,
          element: createNewElem(newSetId, i + 1),
        });
      }
    }
    return newFeatureSets;
  };

  useEffect(() => {
    if (deletingSetId?.length) {
      const tempIsEnableSaveForEachSet = { ...isEnableSaveForEachSet },
        tempIsDependentTargetingSelectedForEachSet = {
          ...isDependentTargetingSelectedForEachSet,
        };
      delete isFeatureSetsFilled[`${deletingSetId[0]}`];
      delete tempIsEnableSaveForEachSet[`${deletingSetId[0]}`];
      delete tempIsDependentTargetingSelectedForEachSet[`${deletingSetId[0]}`];

      setIsEnableSaveForEachSet(tempIsEnableSaveForEachSet);
      setIsDependentTargetingSelectedForEachSet(
        tempIsDependentTargetingSelectedForEachSet
      );
      setIisFeatureSetsFilled(isFeatureSetsFilled);

      setFeatureSetCount(featureSetCount - 1);
      const tempFeatureSets = featureSets
        .filter((el: IFeatureSetElement) => !deletingSetId.includes(el.setId))
        .map((el: IFeatureSetElement, index: number) => {
          return {
            setId: el.setId,
            orderId: index + 1,
            element: createNewElem(el.setId, index + 1, featureSetCount - 1),
          };
        });

      setFeatureSets(tempFeatureSets);
      setDeletingSetId(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletingSetId]);

  const createNewElem = (
    setId: number,
    orderId: number,
    setCount: number | null = null
  ) => {
    return (
      <Draggable draggableId={`${setId}`} index={orderId - 1}>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.draggableProps}>
            <DefinedFeatureSet
              featureSetNumber={setId}
              featureSetOrderNumber={orderId}
              setDeletingSetId={setDeletingSetId}
              dragHandleProps={provided.dragHandleProps}
              featureSetCount={setCount ?? featureSetCount}
              selectedAudienceType={selectedAudienceType}
            />
          </div>
        )}
      </Draggable>
    );
  };

  const onDragEnd = (result: DropResult) => {
    const { destination, source } = result;
    if (!destination || destination.index === source.index) {
      return;
    }

    const newItemsTemp = [...featureSets];

    const [removed] = newItemsTemp.splice(source.index, 1);
    newItemsTemp.splice(destination.index, 0, removed);

    const newItems = newItemsTemp.map(
      (el: IFeatureSetElement, index: number) => {
        return {
          setId: el.setId,
          orderId: index + 1,
          element: createNewElem(el.setId, index + 1),
        };
      }
    );

    setFeatureSets(newItems);
  };

  const resetAllLocalStates = () => {
    setAudienceId(0);
    setSelectedAudienceType(null);
    setAudienceTitle('');
    setSelectedStatus(1);
    setSelectedAdditionalOpts([]);
    setFeatureSetCount(0);
    setFeatureSets([]);
    setPrevSetId(0);
    setDeletingSetId(null);
    setModalToOpen(false);
    setAudienceAgency('');
    setAudiencebrand('');
    setModalToOpenForCancel(false);
    setGlobalFeatureTargetting(null);
    setGlobalFeatureCount(0);
    setGlobalFeatureData(null);
  };

  useEffect(() => {
    const isEnableValues = Object.values(isEnableSaveForEachSet);
    if (
      isEnableValues?.length &&
      !isEnableValues.includes(1) &&
      isEnableValues.includes(2) &&
      isEnableValues.find((el) => el !== 0)
    ) {
      getOverAllData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savingDataSet]);

  useEffect(() => {
    const isEnableValues = Object.values(isEnableSaveForEachSet);
    if (
      !(featureSets?.length && isEnableValues.find((el) => el !== 0)) &&
      !isEnableValues.includes(1) &&
      isSaveInitiated
    ) {
      getOverAllData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaveInitiated]);

  useEffect(() => {
    if (!id && !globalFeatureData) {
      setGlobalFeatureSets({
        setId: 0,
        orderId: 1,
        element: createGlobalElem(0, 0),
      });
    }
  }, []);

  const createGlobalElem = (setId: number, orderId: number) => {
    return (
      <GlobalFeatureSet
        featureSetNumber={setId}
        featureSetOrderNumber={orderId}
        selectedAudienceType={selectedAudienceType}
      />
    );
  };

  const getOverAllData = () => {
    const newFeatureSets = Object.values(savingDataSet),
      isEnableValues = Object.values(isEnableSaveForEachSet);
    if (
      newFeatureSets.length === featureSets?.length + 1 ||
      !(featureSets?.length && isEnableValues.find((el) => el !== 0))
    ) {
      const featureSetDetails = newFeatureSets.filter(
        (el: any) => el.targeting?.length
      );
      if (isSaveInitiated) {
        const overAllData = {
          audience_id: audienceId,
          user_id: userInformation.user_id,
          audience_type_id: selectedAudienceType ?? 0,
          title: audienceTitle,
          agency: audienceAgency,
          brand: audienceBrand,
          advertiser: advertiser,
          status_id: selectedStatus,
          additional_option_id: selectedAdditionalOpts,
          feature_sets: featureSetDetails?.length ? featureSetDetails : [],
          is_edited : 0
        };
        setBody(JSON.stringify(overAllData));
      }
    }
  };

  const resetIsDataSavedState = () => {
    setTimeout(() => {
      setIsDataSaved(false);
      setShowBlankLoaderState(false);
    }, 500);
  };

  const eventAfterSave = (audienceId: number) => {
    const usableSelectedSavingOpt = selectedSavingOpt;
    switch (usableSelectedSavingOpt) {
      case '1': {
        setShowBlankLoaderState(false);
        setAudienceTitle('');
        setIsDataSaved(false);
        setAudienceAgency('');
        setAudiencebrand('');
        setAdvertiser('');
        resetAllLocalStates();
        setSelectedSavingOpt('');
        setAudiencePageNumber(1);
        redirectAudienceListing();
        break;
      }
      case '2': {
        setIsClone(true);
        resetAllStates();
        setIfUpdated(false);
        resetAllLocalStates();
        resetIsDataSavedState();
        setAudienceAgency('');
        setAudiencebrand('');
        setAdvertiser('');
        setFocusOnTitle(1);
        setSelectedSavingOpt('');
        setAudiencePageNumber(1);
        break;
      }
      default:
        setShowBlankLoaderState(false);
        redirectAudienceActivationSetup(audienceId);
        setAudienceTitle('');
        setIsDataSaved(false);
        setAudienceAgency('');
        setAdvertiser('');
        setAudiencebrand('');
        resetAllLocalStates();
        setSelectedSavingOpt('');
        setAudiencePageNumber(1);
        break;
    }
  };

  useEffect(() => {
    if (isDuplicated) {
      setFeatureSetCount(featureSetCount + 1);
      setFeatureSets(createFeatureSet(featureSets, featureSetCount + 1));
      setIsDuplicated(false);
    }
  }, [isDuplicated]);

  const handleAddFeature = () => {
    setFeatureSetCount(featureSetCount + 1);
    setFeatureSets(createFeatureSet([], featureSetCount + 1));
  };

  useEffect(() => {
    if(ifUpdated && featureSetCount && featureSets && globalFeatureSets){
      setIfDataModified(true)
    }
  },[featureSetCount, featureSets, globalFeatureSets])

  return (
    <div className="featureSetWholeWrapBlock">
      {context}
      <div className="featureSetWhole">
        <div>{globalFeatureSets.element}</div>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" type="FEATURE_SET">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {featureSetCount > 0 &&
                  featureSets.map((el: IFeatureSetElement) => (
                    <Fragment key={el.setId}>{el.element}</Fragment>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <div className="addNewFeaturesetbtn">
        <Button onClick={handleAddFeature} className="secondaryBtn">
          <PlusOutlined />
          Add a FeatureSet
        </Button>
      </div>
    </div>
  );
};

export default TargetableFeatureSetup;
