import { Dispatch, FC, SetStateAction, useEffect, useRef } from 'react';
import { InputRef, Checkbox, Divider } from 'antd';
import {
  IEachDropdownElementsType,
  ILoaderState,
} from '../../../../models/interfaces';
import { useLoaderStore } from '../../../../stores';
import {
  CommonElementLabel,
  InputField,
  Single,
} from '../../../../components/sharedComponent';

const CheckboxGroup = Checkbox.Group;

const DefaultFeatureSet: FC<{
  defaultTitle: string;
  setTitle: (value: string) => void;
  advertiser : string;
  setAdvertiser : (value: string) => void;
  audienceType: number | null;
  defaultAgency: string;
  defaultBrand: string;
  setAudienceType: Dispatch<SetStateAction<number | null>>;
  setAgency: (value: string) => void;
  setBrand: (value: string) => void;
  selectedAdditionalOpts: (number | string)[];
  setSelectedAdditionalOpts: Dispatch<SetStateAction<(number | string)[]>>;
  audienceTypeDDElements: IEachDropdownElementsType[];
  additionalOptionDDElements: IEachDropdownElementsType[];
  focusOnTitle?: 0 | 1 | 2;
  disabled?: boolean;
}> = ({
  defaultTitle,
  defaultAgency,
  defaultBrand,
  advertiser,
  setAdvertiser,
  setAgency,
  setBrand,
  setTitle,
  audienceType,
  setAudienceType,
  selectedAdditionalOpts,
  setSelectedAdditionalOpts,
  audienceTypeDDElements,
  additionalOptionDDElements,
  focusOnTitle,
  disabled,
}) => {
  const titleRef = useRef<InputRef>(null);
  const agencyRef = useRef<InputRef>(null);

  const { showPageLoaderState } = useLoaderStore(
    (state: ILoaderState) => state
  );

  useEffect(() => {
    if (!showPageLoaderState && titleRef.current) {
      setTimeout(() => {
        agencyRef.current?.focus({
          cursor: 'end',
        });
      }, 300);
    }
  }, [focusOnTitle, showPageLoaderState]);

  return (
    <div className="defaultFeatureSetSection">
      <InputField
        label="Agency"
        isMandatory
        inputPlaceholder="Enter agency"
        onChange={(data: any) => setAgency(data)}
        defaultValue={defaultAgency}
        props={{ ref: agencyRef }}
      />
      <InputField
        label="Advertiser"
        isMandatory
        inputPlaceholder="Enter advertiser name"
        onChange={(data: any) => setAdvertiser(data)}
        defaultValue={advertiser}
      />
      <InputField
        label="Brand"
        isMandatory
        inputPlaceholder="Enter brand name"
        onChange={(data: any) => setBrand(data)}
        defaultValue={defaultBrand}
      />
      <Divider />

      <div className="defaultFeatureSetSection">
        <InputField
          label="Title"
          isMandatory
          inputPlaceholder="Enter Title"
          onChange={(data: any) => setTitle(data)}
          defaultValue={defaultTitle}

          props={{ ref: titleRef }}
        />

        <div>
          <CommonElementLabel label="Audience Type" isMandatory={true} />
          <Single
            options={audienceTypeDDElements}
            allowClear={false}
            placeholder="Select Audience Type"
            onChange={setAudienceType}
            componentSpecificClasses={['checkboxListDropdownElem']}
            props={{ value: audienceType }}
            isDisabled={disabled ?? false}
          />
        </div>
        <CheckboxGroup
          options={additionalOptionDDElements}
          onChange={setSelectedAdditionalOpts}
          value={selectedAdditionalOpts}
          className="checkboxListWrap"
          disabled={disabled ?? false}
        />
      </div>
    </div>
  );
};

export default DefaultFeatureSet;
