import { FC } from 'react';
import { useRedirection } from '../../../hooks';

const LeftPanelMainMenu: FC<{}> = () => {
  const { redirectResearch} = useRedirection();

  return (
    <ul>
      <li onClick={() => redirectResearch()}>Research</li>
    </ul>
  );
};
export default LeftPanelMainMenu;

