import React, { FC, useState } from 'react';
import { CommonElementLabel, ModalComponent } from '../../../sharedComponent';
import {
  IDebounceDropdownElements,
  IDeleteParams,
  IEsParams,
} from '../../../../models/interfaces';
import { Select, Spin } from 'antd';
import apiService from '../../../../services/Api.service';
import { RoutesEnum } from '../../../../models/enums/apiRoutes';
import { useFeatureSets } from '../../../../stores/newFeatureSets.store';
import { DeleteOutlined, WarningOutlined } from '@ant-design/icons';

const NewFeatureResearchSelectLists: FC<{
  setId: number;
  label: string;
  placeholder: string;
  esParams: IEsParams;
  index: number;
  value: IDebounceDropdownElements[];
  operatorValue: string;
  ifShowError: boolean;
}> = ({
  setId,
  label,
  placeholder,
  esParams,
  value,
  index,
  operatorValue,
  ifShowError,
}) => {

  const [options, setOptions] = useState(),
    [fetching, setFetching] = useState(false),
    [modalToOpen, setModalToOpen] = useState(false);

  const {
    featureSetMasterArray,
    setFeatureSetMasterArray,
    setAddedTargeting,
    addedTargeting,
    setIsResearchFeatureSetdeleted,
  } = useFeatureSets((state: any) => state);
  const formatLabel = (
    data: any,
    mp: string[],
    sp1: string[],
    sp2: string[],
    op: string
  ) => {
    const mainLabelArray: string[] = [],
      subLabelArray1: string[] = [],
      subLabelArray2: string[] = [];

    mp.forEach((el) => {
      const d = data[el];
      if (d) {
        mainLabelArray.push(d);
      }
    });

    if (sp1?.length) {
      sp1.forEach((el) => {
        const d = data[el];
        if (d) {
          subLabelArray1.push(d);
        }
      });
    }

    if (sp2?.length) {
      sp2.forEach((el) => {
        const d = data[el];
        if (d) {
          subLabelArray2.push(d);
        }
      });
    }

    const mainLabel = mainLabelArray.join(op),
      subLabel1 = subLabelArray1?.length ? '~' + subLabelArray1.join(' ') : '',
      subLabel2 = subLabelArray2?.length
        ? '~(' + subLabelArray2.join(' ') + ')'
        : '';

    return mainLabel + subLabel1 + subLabel2;
  };

  const getDropdownOptions = async (
    searchParam: string
  ): Promise<IDebounceDropdownElements[]> => {
    setFetching(true);
    const payload = JSON.stringify({
      index: esParams.esIndex,
      query: {
        multi_match: {
          query: searchParam,
          fields: esParams.esSearchParam,
          type: 'phrase_prefix',
        },
      },
      size: 50,
    });

    return apiService
      .post<any>(
        process.env.REACT_APP_BASE_URL + RoutesEnum.GET_ES_RESULT,
        payload
      )
      .then((res) => {
        const { body, statusCode } = res;

        if (statusCode === 200) {
          const parsedBody = JSON.parse(body),
            uniqueElem = parsedBody.filter(
              (value: any, index: number, self: any) =>
                index ===
                self.findIndex(
                  (t: any) =>
                    t[esParams.esKeyParam] === value[esParams.esKeyParam]
                )
            ),
            tempData = uniqueElem.map((el: any) => ({
              value: formatLabel(el, esParams.esValueParam, [], [], '~'),
              label: formatLabel(
                el,
                esParams.esMainLabelParam,
                esParams.esSubLabelParam1,
                esParams.esSubLabelParam2,
                ' '
              ),
              ...el,
            }));
          setOptions(tempData);
          setFetching(false);
          return tempData;
        } else {
          return [];
        }
      })
      .catch((error) => []);
  };
  const handleSelectChangeValue = (value: any) => {
    featureSetMasterArray[index].value = value;
    setFeatureSetMasterArray(featureSetMasterArray);
  };
  
  const handleDelete = () => {
    const tempFeatureSetMasterArray = featureSetMasterArray.filter(
      (e: any, i: number) => i !== index
    );
    setFeatureSetMasterArray(tempFeatureSetMasterArray);
    const tempAddedTargeting = addedTargeting.filter(
      (e: any, i: number) => i !== index
    );
    setAddedTargeting(tempAddedTargeting);
    setIsResearchFeatureSetdeleted(true);
  };
  const deleteSetParam: IDeleteParams = {
    setId: setId,
    name: label,
    handleDelete: handleDelete,
  };
  const handleOperatorChangeValue = (value: any) => {
    featureSetMasterArray[index].operatorValue = value;
    setFeatureSetMasterArray(featureSetMasterArray);
  };
  const handleSelectOnFocus = () => {
    featureSetMasterArray[index].isError = false;
    setFeatureSetMasterArray(featureSetMasterArray);
  };
  const renderOptions = (data: any, key: string) => {
    const { label } = data,
      labelArr = label?.split('~');

    return (
      <div title={label?.replaceAll('~', ' ')}>
        <div className="debounceDDOptionElemWrap">
          <div>{data[key]}:</div>
          <div>{labelArr[0]}</div>
        </div>
        {labelArr[1] ? (
          <div>
            <i>{labelArr[1]}</i> {labelArr[2]}
          </div>
        ) : (
          ''
        )}
      </div>
    );
  };

  return (
    <div className="targetBlock">
      <CommonElementLabel label={label} />

      <Select
        labelInValue
        key={index}
        // maxTagCount={5}
        defaultValue={value}
        showSearch={true}
        filterOption={false}
        onSearch={getDropdownOptions}
        notFoundContent={fetching ? <Spin size="small" /> : null}
        className="multiSelectDropdown"
        options={options}
        onSelect={handleSelectChangeValue}
        optionRender={({ data }) => renderOptions(data, esParams.esKeyParam)}
        value={value}
        suffixIcon={
          ifShowError ? (
            <WarningOutlined className="validationErrorIcon" />
          ) : null
        }
        status={ifShowError ? 'error' : ''}
        onFocus={handleSelectOnFocus}
      />
      {featureSetMasterArray.length - 1 !== index && (
        <Select
          key={index}
          defaultValue="5"
          value={operatorValue}
          onChange={handleOperatorChangeValue}
          className="newFeatureOptions"
          options={[
            { value: '5', label: 'AND' },
            { value: '6', label: 'OR' },
            { value: '7', label: 'AND NOT' },
          ]}
        />
      )}
      <DeleteOutlined
        className="deleteIconForElements"
        onClick={() => setModalToOpen(true)}
      />
      <ModalComponent
        modalHeader="Delete Element"
        modalToOpen={modalToOpen}
        setModalToOpen={setModalToOpen}
        deleteSetParams={deleteSetParam}
      />
    </div>
  );
};

export default NewFeatureResearchSelectLists;
