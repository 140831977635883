import { FC, Fragment, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Dropdown, Menu, MenuProps } from 'antd';
import Table, { TableProps } from 'antd/es/table';
import {
  usePostApiInterceptor,
  useShowToasterMessage,
} from '../../../../hooks';
import dayjs from 'dayjs';

import {
  IActiveAudienceListingParam,
  ILoaderState,
  IUserInformationState,
} from '../../../../models/interfaces';
import { SortOrder } from 'antd/es/table/interface';
import { useLoaderStore } from '../../../../stores';
import { RoutesEnum } from '../../../../models/enums/apiRoutes';
import { moreIconVertical } from '../../../../assets/images';

type OnChange = NonNullable<TableProps<DataType>['onChange']>;

const items: MenuProps['items'] = [
  {
    key: '1',
    label: 'View Audience',
  },
];

function extractParenthesesContent(inputString: string) {
  const match = inputString.match(/\(([^)]+)\)/);
  return match ? match[1] : inputString;
}

interface DataType {
  title: string;
  act_partners: string[];
  audience_id: number;
  agency: string;
  advertiser: string;
  brand: string;
  type: string;
  act_audience_sizes: number[];
  act_creation_dates: string[];
}

const ActiveAudienceListingTable: FC<{
  activationData: any;
  isLoading: boolean;
  paginationAndSortingData: IActiveAudienceListingParam;
  setPaginationAndSortingData: React.Dispatch<
    React.SetStateAction<IActiveAudienceListingParam>
  >;
}> = ({
  activationData,
  isLoading,
  paginationAndSortingData,
  setPaginationAndSortingData,
}) => {
  const [body, setBody] = useState<string>(''),
    [isMounted, setIsMounted] = useState<boolean>(false),
    [messageObj, setMessageObj] = useState<any>({ isShowing: false });

  const { setShowPageLoaderState } = useLoaderStore(
      (state: ILoaderState) => state
    );

  const { contextHolder } = useShowToasterMessage(messageObj),
    navigate = useNavigate();

  usePostApiInterceptor(
    isMounted,
    body,
    RoutesEnum.DUPLICATE_ACTIVATION,
    (status: number, data: any, error: any) => {
      setBody('');
      setIsMounted(false);

      if (!error && data) {
        setPaginationAndSortingData({
          ...paginationAndSortingData,
          pageNumber: 1,
        });
        updateErrorMessage(
          'success',
          'Audience Activation has been duplicated'
        );
      } else {
        updateErrorMessage(
          'error',
          'Error occurred while duplicating Audience Activation Information'
        );
      }
      setShowPageLoaderState(false);
    }
  );

  const handleChange: OnChange = (pagination, filter, sorter: any) => {
    const { sortOrder, sortColumn, pageNumber, rowsPerPage } =
      paginationAndSortingData;

    setPaginationAndSortingData({
      ...paginationAndSortingData,
      sortOrder:
        sorter?.order === undefined ? sortOrder : getSortOrder(sorter.order),
      sortColumn: sorter?.columnKey ?? sortColumn,
      pageNumber: pagination?.current ?? pageNumber,
      rowsPerPage: pagination?.pageSize ?? rowsPerPage,
    });
  };

  const getSortOrder = (order: SortOrder) => {
    if (order === 'ascend') return 'ASC';
    if (order === 'descend') return 'DESC';
    return null;
  };

  const updateErrorMessage = (type: string, messageContent: string) => {
    setMessageObj({
      key: 'clone',
      isShowing: true,
      type,
      messageContent,
    });
  };

  const handleActions = (e: any, data: DataType) => {
    navigate('/view-audience/' + data.audience_id);
  };

  function formatEstimatedSizes(sizes: number[]): string[] {
    return sizes.map((size) => {
      const newSize = size * 10;
      return newSize.toLocaleString();
    });
  }

  function manipulateDataArray(dataArray: DataType[]) {
    return dataArray?.map((item: any) => {
      return {
        ...item,
        act_audience_sizes: formatEstimatedSizes(item.act_audience_sizes),
        audience_type: extractParenthesesContent(item.audience_type),
      };
    });
  }

  const columns = [
    {
      title: 'Audience & Partner',
      dataIndex: 'title',
      key: 'audiencePartner',
      render: (
        text: any,
        record: {
          title: string;
          act_partners: string[];
          audience_id: number;
        }
      ) => (
        <>
          <NavLink to={`/audience-activation/${record.audience_id}/1`}>
            <h4>{record.title}</h4>
          </NavLink>
          <ul className="partnerList">
            {record.act_partners.map(
              (partner: string, index: React.Key | null | undefined) => (
                <li key={index}>&rarr;{partner}</li>
              )
            )}
          </ul>
        </>
      ),
      showSorterTooltip: false,
      sorter: true,
    },
    {
      title: 'Agency',
      dataIndex: 'agency',
      key: 'agency',
      className: 'topAligned',
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: 'Advertiser',
      dataIndex: 'advertiser',
      key: 'advertiser',
      className: 'topAligned',
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: 'Brand',
      dataIndex: 'brand',
      key: 'brand',
      className: 'topAligned',
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: 'Type',
      dataIndex: 'audience_type',
      key: 'type',
      className: 'topAligned',
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: 'Size',
      dataIndex: 'act_audience_sizes',
      key: 'size',
      render: (act_audience_sizes: number[]) => (
        <>
          {act_audience_sizes.map(
            (size: number, index: React.Key | null | undefined) => (
              <span key={index}>{size}</span>
            )
          )}
        </>
      ),
      className: 'bottomAligned',
      showSorterTooltip: false,
    },
    {
      title: 'Date',
      dataIndex: 'act_creation_dates',
      key: 'date',
      render: (act_creation_dates: string[]) => (
        <>
          {act_creation_dates.map(
            (date: string, index: React.Key | null | undefined) => (
              <span key={index}>{dayjs(date).format('M/D/YYYY')}</span>
            )
          )}
        </>
      ),
      className: 'bottomAligned',
      showSorterTooltip: false,
    },
    {
      title: '',
      key: 'action',
      width: '50px',
      render: (_: any, record: DataType) => (
        <Dropdown
          trigger={['click']}
          overlay={
            <Menu
              items={items}
              onClick={(e) => {
                handleActions(e, record);
              }}
            ></Menu>
          }
          placement="bottomRight"
        >
          <img src={moreIconVertical} className="actionMenuIcon" alt="more" />
        </Dropdown>
      ),
      showSorterTooltip: false,
    },
  ];

  return (
    <Fragment>
      {contextHolder}
      <Table
        columns={columns}
        dataSource={manipulateDataArray(activationData?.rows) ?? []}
        onChange={handleChange}
        className={`antTableElement audienceListTable ${
          isLoading ? ' hideNoDataLabel' : ''
        }`}
        pagination={{
          current: paginationAndSortingData.pageNumber,
          total: activationData?.total_row_count ?? 0,
          showSizeChanger: false,
          pageSize: paginationAndSortingData.rowsPerPage,
        }}
        loading={isLoading}
        bordered
        scroll={{ x: 'max-content' }}
        sortDirections={['ascend', 'descend', 'ascend']}
      />
    </Fragment>
  );
};

export default ActiveAudienceListingTable;
