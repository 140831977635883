import { Dayjs } from 'dayjs';
import { ReactNode } from 'react';
import {
  IEachDropdownElementsType,
  ICustomNPIList,
  IEsParams,
  IDebounceDropdownElements,
  DateRangeType,
} from '../../../../models/interfaces';
import {
  AgeSlider,
  CommonElementLabel,
  DateDropdown,
  DebounceDropdown,
  InputField,
  Multi,
  Single,
  UploadUserFiles,
} from '../../../../components/sharedComponent';
import DMASelector from '../../../../components/pages/AudienceSetup/AudienceSetupComponents/DMASelector';
import InsuranceSelector from '../../../../components/pages/AudienceSetup/AudienceSetupComponents/InsuranceSelector';
import DeleteTargetingElement from '../../../../components/sharedComponent/Delete/DeleteTargetingElementV1';

type FeatureSetType = {
  value: number;
  title: string;
  fieldName: string;
  component: Function;
};

export const featureSetList: FeatureSetType[] = [
  {
    value: 1,
    title: 'Timeframes',
    component: callTimeframeDropdown,
    fieldName: 'timeFrame',
  },
  {
    value: 2,
    title: 'Patient Gender',
    component: callSingleSelector,
    fieldName: 'patientGender',
  },
  {
    value: 3,
    title: 'Patient Age',
    component: callPatientAgeSlider,
    fieldName: 'selectedAge',
  },
  {
    value: 4,
    title: 'Race & Ethnicity',
    component: callMultiSelector,
    fieldName: 'selectedRaceAndEthnicity',
  },
  {
    value: 5,
    title: 'Geography(State)',
    component: callMultiSelector,
    fieldName: 'selectedGeographyStates',
  },
  {
    value: 6,
    title: 'Provider Specialty',
    component: callMultiSelector,
    fieldName: 'selectedProviderSpecialty',
  },
  {
    value: 7,
    title: 'Custom NPI List',
    component: callUploadNpiList,
    fieldName: 'customNPIList',
  },
  {
    value: 8,
    title: 'Hospital',
    component: callMultiSelector,
    fieldName: 'selectedHospitals',
  },
  {
    value: 9,
    title: 'Diagnosis',
    component: callDebounceSelector,
    fieldName: 'selectedDiagnosis',
  },
  {
    value: 10,
    title: 'Drug',
    component: callDebounceSelector,
    fieldName: 'selectedDrugs',
  },
  {
    value: 11,
    title: 'Procedure',
    component: callDebounceSelector,
    fieldName: 'selectedProcedures',
  },
  {
    value: 12,
    title: 'Insurance',
    component: callInsuranceSelector,
    fieldName: 'selectedInsurancePlanType',
  },
  {
    value: 13,
    title: 'Additional Options',
    component: callMultiSelector,
    fieldName: 'selectedAdditionalOpts',
  },
  {
    value: 14,
    title: 'Additional Information',
    component: callInputField,
    fieldName: 'additionalInfo',
  },
  {
    value: 15,
    title: 'Geography(DMA)',
    component: callDMASelector,
    fieldName: 'selectedGeographyDmas',
  },
  {
    value: 18,
    title: 'Generic Drug',
    component: callDebounceSelector,
    fieldName: 'genericDrug',
  },
  {
    value: 19,
    title: 'Language',
    component: callMultiSelector,
    fieldName: 'selectedGeographyStates',
  },
];

function callSingleSelector(props: {
  setId: number;
  label: string;
  isMandatory: boolean;
  options: IEachDropdownElementsType[];
  placeholder: string;
  isSearchable: boolean;
  componentSpecificClasses?: string[];
  isDisabled?: boolean;
  onChange: React.Dispatch<React.SetStateAction<number | null>>;
  handleSetDelete: Function;
  otherProps?: any;
  featureSetNumber: number;
}) {
  const {
    setId,
    label,
    isMandatory,
    options,
    placeholder,
    isSearchable,
    componentSpecificClasses,
    isDisabled,
    onChange,
    handleSetDelete,
    otherProps,
    featureSetNumber,
  } = props;

  return (
    <div>
      <div className="featuresetElementSingle">
        <div className="featureSetElementSingleLeft">
          <CommonElementLabel label={label} isMandatory={isMandatory} />
          <Single
            options={options}
            placeholder={placeholder}
            isSearchable={isSearchable}
            onChange={onChange}
            componentSpecificClasses={componentSpecificClasses}
            isDisabled={isDisabled}
            props={otherProps}
            targetingSetNumber={setId}
            featureSetNumber={featureSetNumber}
          />
        </div>

        
        {!isDisabled && (
          <DeleteTargetingElement
            deleteSetParams={{
              setId,
              name:
                featureSetList.find((el: FeatureSetType) => el.value === setId)
                  ?.title ?? '',
              showRemove: true,
              handleDelete: handleSetDelete,
              fieldName: featureSetList.find(
                (el: FeatureSetType) => el.value === setId
              )?.fieldName,
            }}
          />
        )}
      </div>
    </div>
  );
}

function callTimeframeDropdown(props: {
  setId: number;
  label: string;
  isMandatory: boolean;
  options: IEachDropdownElementsType[];
  placeholder: string;
  isSearchable: boolean;
  componentSpecificClasses?: string[];
  isDisabled?: boolean;
  timeFrame: number | null;
  dateRange: DateRangeType;
  setTimeFrame: React.Dispatch<React.SetStateAction<number | null>>;
  setDateRange: React.Dispatch<React.SetStateAction<DateRangeType>>;
  handleSetDelete: Function;
  minCustomDate?: Dayjs;
  featureSetNumber: number;
}) {
  const {
    setId,
    label,
    isMandatory,
    options,
    placeholder,
    isSearchable,
    timeFrame,
    dateRange,
    setTimeFrame,
    setDateRange,
    handleSetDelete,
    minCustomDate,
    featureSetNumber,
    isDisabled
  } = props;
  return (
    <div>
      <div className="featuresetElementSingle">
        <div className="featureSetElementSingleLeft">
          <DateDropdown
            label={label}
            isMandatory={isMandatory}
            options={options}
            placeholder={placeholder}
            isSearchable={isSearchable}
            timeFrame={timeFrame}
            dateRange={dateRange}
            setTimeFrame={setTimeFrame}
            setDateRange={setDateRange}
            minDate={minCustomDate}
            isDisabled={isDisabled}
            targetingSetNumber={setId}
            featureSetNumber={featureSetNumber}
          />
        </div>

        
       {!isDisabled && (
          <DeleteTargetingElement
            deleteSetParams={{
              setId,
              name:
                featureSetList.find((el: FeatureSetType) => el.value === setId)
                  ?.title ?? '',
              showRemove: true,
              handleDelete: handleSetDelete,
              fieldName: featureSetList.find(
                (el: FeatureSetType) => el.value === setId
              )?.fieldName,
            }}
          />
        )}
      </div>
    </div>
  );
}

function callPatientAgeSlider(props: {
  setId: number;
  label: string;
  isMandatory: boolean;
  defaultValue: number[];
  onChange: React.Dispatch<React.SetStateAction<number[]>>;
  handleSetDelete: Function;
  isDisabled?:boolean
}) {
  const {
    setId,
    label,
    isMandatory,
    defaultValue,
    onChange,
    handleSetDelete,
    isDisabled
  } = props;
  return (
    <div>
      <div className="featuresetElementSingle">
        <div className="featureSetElementSingleLeft">
          <CommonElementLabel
            label={label}
            isMandatory={isMandatory}
            specificWrapperClass="ageSliderLabel"
          />
          <AgeSlider defaultValue={defaultValue} onChange={onChange} isDisabled={isDisabled}/>
        </div>

        
        {!isDisabled && (
          <DeleteTargetingElement
            deleteSetParams={{
              setId,
              name:
                featureSetList.find((el: FeatureSetType) => el.value === setId)
                  ?.title ?? '',
              showRemove: true,
              handleDelete: handleSetDelete,
              fieldName: featureSetList.find(
                (el: FeatureSetType) => el.value === setId
              )?.fieldName,
            }}
          />
        )}
      </div>
    </div>
  );
}

function callMultiSelector(props: {
  setId: number;
  label: string;
  isMandatory: boolean;
  options: IEachDropdownElementsType[];
  placeholder: string;
  isSearchable?: boolean;
  componentSpecificClasses?: string[];
  isDisabled?: boolean;
  defaultPopupWidth?: number | boolean;
  defaultPopupClassName?: string;
  otherProperties?: any;
  value: (number | string)[];
  onChange: React.Dispatch<React.SetStateAction<(number | string)[]>>;
  handleSetDelete: Function;
  featureSetNumber: number;
}) {
  const {
    setId,
    label,
    isMandatory,
    options,
    placeholder,
    isSearchable,
    componentSpecificClasses,
    isDisabled,
    defaultPopupWidth,
    defaultPopupClassName,
    otherProperties,
    onChange,
    handleSetDelete,
    featureSetNumber,
  } = props;
  return (
    <div>
      <div className="featuresetElementSingle">
        <div className="featureSetElementSingleLeft">
          <CommonElementLabel label={label} isMandatory={isMandatory} />
          <Multi
            options={options}
            placeholder={placeholder}
            isSearchable={isSearchable}
            componentSpecificClasses={componentSpecificClasses}
            isDisabled={isDisabled}
            defaultPopupWidth={defaultPopupWidth}
            defaultPopupClassName={defaultPopupClassName}
            props={otherProperties}
            onChange={onChange}
            targetingSetNumber={setId}
            featureSetNumber={featureSetNumber}
          />
        </div>

        
        {!isDisabled && (
          <DeleteTargetingElement
            deleteSetParams={{
              setId,
              name:
                featureSetList.find((el: FeatureSetType) => el.value === setId)
                  ?.title ?? '',
              showRemove: true,
              handleDelete: handleSetDelete,
              fieldName: featureSetList.find(
                (el: FeatureSetType) => el.value === setId
              )?.fieldName,
            }}
          />
        )}
      </div>
    </div>
  );
}

function callUploadNpiList(props: {
  setId: number;
  label: string;
  isMandatory: boolean;
  uuid: number;
  componentSpecificClasses?: string[];
  isDisabled?: boolean;
  customNPIList: ICustomNPIList[];
  setCustomNPIList: React.Dispatch<React.SetStateAction<ICustomNPIList[]>>;
  handleSetDelete: Function;
  featureSetNumber: number;
}) {
  const {
    setId,
    label,
    isMandatory,
    uuid,
    componentSpecificClasses,
    isDisabled,
    customNPIList,
    setCustomNPIList,
    handleSetDelete,
    featureSetNumber,
  } = props;
  return (
    <div>
      <div className="featuresetElementSingle">
        <div className="featureSetElementSingleLeft">
          <CommonElementLabel label={label} isMandatory={isMandatory} />
          <UploadUserFiles
            uuid={uuid}
            componentSpecificClasses={componentSpecificClasses}
            customNPIList={customNPIList}
            setCustomNPIList={setCustomNPIList}
            isDisabled={isDisabled}
            targetingSetNumber={setId}
            featureSetNumber={featureSetNumber}
          />
        </div>

        {!isDisabled && (
          <DeleteTargetingElement
            deleteSetParams={{
              setId,
              name:
                featureSetList.find((el: FeatureSetType) => el.value === setId)
                  ?.title ?? '',
              showRemove: true,
              handleDelete: handleSetDelete,
              fieldName: featureSetList.find(
                (el: FeatureSetType) => el.value === setId
              )?.fieldName,
            }}
          />
        )}
      </div>
    </div>
  );
}

function callInsuranceSelector(props: {
  setId: number;
  selectedPlanType: number | null;
  selectedProviders: (number | string)[];
  setSelectedPlanType: React.Dispatch<React.SetStateAction<number | null>>;
  setSelectedProviders: React.Dispatch<
    React.SetStateAction<(number | string)[]>
  >;
  isDisabled?: boolean
  handleSetDelete: Function;
  featureSetNumber: number;
}) {
  const {
    setId,
    selectedPlanType,
    selectedProviders,
    setSelectedPlanType,
    setSelectedProviders,
    handleSetDelete,
    featureSetNumber,
    isDisabled
  } = props;
  return (
    <div>
      <div className="featuresetElementSingle">
        <div className="featureSetElementSingleLeft">
          <InsuranceSelector
            planType={selectedPlanType}
            provider={selectedProviders}
            setPlanType={setSelectedPlanType}
            setProvider={setSelectedProviders}
            targetingSetNumber={setId}
            featureSetNumber={featureSetNumber}
            isDisabled={isDisabled}
          />
        </div>

        
        {!isDisabled && (
          <DeleteTargetingElement
            deleteSetParams={{
              setId,
              name:
                featureSetList.find((el: FeatureSetType) => el.value === setId)
                  ?.title ?? '',
              showRemove: true,
              handleDelete: handleSetDelete,
              fieldName: featureSetList.find(
                (el: FeatureSetType) => el.value === setId
              )?.fieldName,
            }}
          />
        )}
      </div>
    </div>
  );
}

function callInputField(props: {
  setId: number;
  label: string;
  isMandatory?: boolean;
  inputWrapperClasses?: string[];
  fieldType?: 'input' | 'textarea';
  inputPlaceholder?: string;
  ifAllowCharCount?: boolean;
  ifAllowClear?: boolean;
  additionalInfo: string;
  onChange: React.Dispatch<React.SetStateAction<string>>;
  fieldVariant?: 'outlined' | 'borderless' | 'filled';
  prefix?: ReactNode;
  suffix?: ReactNode;
  isDisabled?: boolean;
  otherProperties?: any;
  handleSetDelete: Function;
  featureSetNumber: number;
}) {
  const {
    setId,
    label,
    isMandatory = false,
    inputWrapperClasses = [],
    fieldType = 'input',
    inputPlaceholder = 'Enter text',
    ifAllowCharCount = false,
    ifAllowClear = false,
    additionalInfo,
    onChange,
    fieldVariant = 'outlined',
    prefix = <></>,
    suffix = <></>,
    isDisabled = false,
    otherProperties = {},
    handleSetDelete,
    featureSetNumber,
  } = props;
  return (
    <div>
      <div className="featuresetElementSingle">
        <div className="featureSetElementSingleLeft">
          <InputField
            label={label}
            isMandatory={isMandatory}
            inputWrapperClasses={inputWrapperClasses}
            ifAllowCharCount={ifAllowCharCount}
            ifAllowClear={ifAllowClear}
            fieldType={fieldType}
            inputPlaceholder={inputPlaceholder}
            onChange={onChange}
            fieldVariant={fieldVariant}
            prefix={prefix}
            suffix={suffix}
            isDisabled={isDisabled}
            props={otherProperties}
            defaultValue={additionalInfo}
            targetingSetNumber={setId}
            featureSetNumber={featureSetNumber}
          />
        </div>

        
        {!isDisabled && (
          <DeleteTargetingElement
            deleteSetParams={{
              setId,
              name:
                featureSetList.find((el: FeatureSetType) => el.value === setId)
                  ?.title ?? '',
              showRemove: true,
              handleDelete: handleSetDelete,
              fieldName: featureSetList.find(
                (el: FeatureSetType) => el.value === setId
              )?.fieldName,
            }}
          />
        )}
      </div>
    </div>
  );
}

function callDebounceSelector(props: {
  setId: number;
  label: string;
  isMandatory: boolean;
  placeholder: string;
  esParams: IEsParams;
  value: IDebounceDropdownElements[];
  onChange: React.Dispatch<React.SetStateAction<IDebounceDropdownElements[]>>;
  handleSetDelete: Function;
  featureSetNumber: number;
  isDisabled?: boolean;
}) {
  const {
    setId,
    label,
    isMandatory,
    placeholder,
    esParams,
    value,
    onChange,
    handleSetDelete,
    featureSetNumber,
    isDisabled,
  } = props;
  return (
    <div>
      <div className="featuresetElementSingle">
        <div className="featureSetElementSingleLeft">
          <CommonElementLabel label={label} isMandatory={isMandatory} />
          <DebounceDropdown
            placeholder={placeholder}
            esParams={esParams}
            onChange={onChange}
            defaultValue={value}
            targetingSetNumber={setId}
            isDisabled={isDisabled}
            featureSetNumber={featureSetNumber}
          />
        </div>

        
        {!isDisabled && (
          <DeleteTargetingElement
            deleteSetParams={{
              setId,
              name:
                featureSetList.find((el: FeatureSetType) => el.value === setId)
                  ?.title ?? '',
              showRemove: true,
              handleDelete: handleSetDelete,
              fieldName: featureSetList.find(
                (el: FeatureSetType) => el.value === setId
              )?.fieldName,
            }}
          />
        )}
      </div>
    </div>
  );
}

function callDMASelector(props: {
  setId: number;
  label: string;
  isMandatory: boolean;
  isSearchable?: boolean;
  componentSpecificClasses?: string[];
  isDisabled?: boolean;
  defaultPopupWidth?: number | boolean;
  defaultPopupClassName?: string;
  onChange: React.Dispatch<React.SetStateAction<(number | string)[]>>;
  handleSetDelete: Function;
  featureSetNumber: number;
  value: (number | string)[];
}) {
  const {
    setId,
    label,
    isMandatory,
    isSearchable,
    componentSpecificClasses,
    isDisabled,
    defaultPopupWidth,
    defaultPopupClassName,
    onChange,
    handleSetDelete,
    featureSetNumber,
    value,
  } = props;
  return (
    <div>
      <div className="featuresetElementSingle">
        <div className="featureSetElementSingleLeft">
          <CommonElementLabel label={label} isMandatory={isMandatory} />
          <DMASelector
            isSearchable={isSearchable}
            componentSpecificClasses={componentSpecificClasses}
            isDisabled={isDisabled}
            defaultPopupWidth={defaultPopupWidth}
            defaultPopupClassName={defaultPopupClassName}
            onChange={onChange}
            featureSetNumber={featureSetNumber}
            targetingSetNumber={setId}
            value={value}
          />
        </div>

        
        {!isDisabled && (
          <DeleteTargetingElement
            deleteSetParams={{
              setId,
              name:
                featureSetList.find((el: FeatureSetType) => el.value === setId)
                  ?.title ?? '',
              showRemove: true,
              handleDelete: handleSetDelete,
              fieldName: featureSetList.find(
                (el: FeatureSetType) => el.value === setId
              )?.fieldName,
            }}
          />
        )}
      </div>
    </div>
  );
}
