/* eslint-disable react-hooks/exhaustive-deps */
import { WarningOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { FC, ReactNode, useEffect, useState } from 'react';
import { CommonElementLabel } from '../';
import {
  IDeleteParams,
  IEnableSaveState,
  ISaveAudienceActivationState,
  activationKeyType,
} from '../../../models/interfaces';
import { useCheckSavingEnabilityStore } from '../../../stores';
import { useSaveAudienceActivationStore } from '../../../stores/saveAndUpdateData.store';

const InputField: FC<{
  label: string;
  isMandatory?: boolean;
  inputWrapperClasses?: string[];
  fieldType?: 'input' | 'textarea';
  inputPlaceholder?: string;
  ifAllowCharCount?: boolean;
  ifAllowClear?: boolean;
  onChange?: React.Dispatch<React.SetStateAction<string>>;
  fieldVariant?: 'outlined' | 'borderless' | 'filled';
  prefix?: ReactNode;
  suffix?: ReactNode;
  isDisabled?: boolean;
  props?: any;
  deleteSetParams?: IDeleteParams;
  defaultValue?: string;
  targetingSetNumber?: number;
  featureSetNumber?: number;
  specificWrapperClass?: string;
  activationElemKey?: activationKeyType;
}> = ({
  label,
  isMandatory = false,
  inputWrapperClasses,
  fieldType = 'input',
  inputPlaceholder = 'Enter text',
  ifAllowCharCount = false,
  ifAllowClear = false,
  onChange,
  fieldVariant = 'outlined',
  prefix = <></>,
  suffix = <></>,
  isDisabled = false,
  props = {},
  deleteSetParams,
  defaultValue = '',
  targetingSetNumber,
  featureSetNumber,
  specificWrapperClass,
  activationElemKey,
}) => {
  const [state, setState] = useState<string>(defaultValue ?? ''),
    [ifShowError, setIfShowError] = useState<boolean>(false);

  const { isFeatureSetsFilled, updateIisFeatureSetsFilled } =
      useCheckSavingEnabilityStore((state: IEnableSaveState) => state),
    { ifAllMandatoryFieldsPopulated, updateIfAllMandatoryFieldsPopulated } =
      useSaveAudienceActivationStore(
        (state: ISaveAudienceActivationState) => state
      );

  const onFocus = () => {
    if (ifShowError) {
      featureSetNumber &&
        targetingSetNumber &&
        updateIisFeatureSetsFilled(featureSetNumber, targetingSetNumber, 0);

      activationElemKey &&
        updateIfAllMandatoryFieldsPopulated({ [activationElemKey]: false });
    }
  };

  useEffect(() => {
    setIfShowError(
      Boolean(
        featureSetNumber &&
          targetingSetNumber &&
          isFeatureSetsFilled[`${featureSetNumber}`][
            `${targetingSetNumber}`
          ] === 1
      )
    );
  }, [isFeatureSetsFilled]);

  useEffect(() => {
    setIfShowError(
      Boolean(
        activationElemKey &&
          ifAllMandatoryFieldsPopulated[activationElemKey] === 1
      )
    );
  }, [ifAllMandatoryFieldsPopulated]);

  useEffect(() => {
    setState(defaultValue);
  }, [defaultValue]);

  return (
    <div>
      <CommonElementLabel
        label={label}
        isMandatory={isMandatory}
        deleteSetParams={deleteSetParams}
        specificWrapperClass={specificWrapperClass}
      />
      <div
        className={`commonInputFieldBodyWrapper${
          inputWrapperClasses?.length ? ' ' + inputWrapperClasses.join(' ') : ''
        }`}
      >
        <Input
          type={fieldType}
          placeholder={inputPlaceholder}
          showCount={ifAllowCharCount}
          allowClear={ifAllowClear}
          defaultValue={defaultValue}
          value={state}
          onChange={(e) => {
            setState(e.target.value);
            onChange && onChange(e.target.value);
          }}
          variant={fieldVariant}
          prefix={prefix}
          disabled={isDisabled}
          onFocus={onFocus}
          status={ifShowError ? 'error' : ''}
          suffix={
            ifShowError ? (
              <WarningOutlined className="validationErrorIcon" />
            ) : (
              ''
            )
          }
          {...props}
        />
      </div>
    </div>
  );
};

export default InputField;
