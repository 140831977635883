import React, { FC } from 'react';
import { breadcrumbSeparator } from '../../../assets/images';

interface BreadcrumbProps {
  items: any;
  activeIndex: number;
}

const Breadcrumb: FC<BreadcrumbProps> = ({ items, activeIndex }) => {
  return (
    <div className="breadCrumbContainer">
      {items.map((item: any) => (
        <span key={item.key}>
          <span
            style={{
              color:
                item.key === activeIndex
                  ? 'var(--bl-header)'
                  : 'var(--bl-grey-2)',
            }}
            className="breadCrumbLink"
          >
            {item.key !== 1 && (
              <span className="breadCrumbSeparator">
                <img src={breadcrumbSeparator} alt="arrow" />
              </span>
            )}
            {item.title}
          </span>
        </span>
      ))}
    </div>
  );
};

export default Breadcrumb;
