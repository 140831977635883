/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { formatDate } from '..';
import { useCheckSavingEnabilityStore } from '../../../stores';
import { DateRangeType, IEnableSaveState } from '../../../models/interfaces';
import { CalendarOutlined, WarningOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;

type RangeValue = [Dayjs | null, Dayjs | null] | null;

const DateRangePicker: FC<{
  dateFormat?: string;
  ifDisabled?: boolean;
  dateRange: DateRangeType;
  setDateRange: React.Dispatch<React.SetStateAction<DateRangeType>>;
  specificWrapperClass?: string;
  targetingSetNumber?: number;
  featureSetNumber?: number;
  props?: any;
}> = ({
  dateFormat = 'M/D/YY',
  ifDisabled,
  dateRange,
  setDateRange,
  specificWrapperClass,
  targetingSetNumber,
  featureSetNumber,
  props,
}) => {
  const [state, setState] = useState<RangeValue>(
      dateRange ? [dayjs(dateRange[0]), dayjs(dateRange[1])] : null
    ),
    [ifShowError, setIfShowError] = useState<boolean>(false);

  const { isFeatureSetsFilled, updateIisFeatureSetsFilled } =
    useCheckSavingEnabilityStore((state: IEnableSaveState) => state);

  const onFocus = () => {
    ifShowError &&
      updateIisFeatureSetsFilled(featureSetNumber, targetingSetNumber, 0);
  };

  useEffect(() => {
    if (
      featureSetNumber !== undefined &&
      targetingSetNumber !== undefined &&
      isFeatureSetsFilled[`${featureSetNumber}`][`${targetingSetNumber}`] === 1
    ) {
      setIfShowError(true);
      return;
    }
    setIfShowError(false);
  }, [isFeatureSetsFilled]);

  useEffect(() => {
    if (ifDisabled) {
      setDateRange(null);
      setState(null);
    }
  }, [ifDisabled]);

  return (
    <div
      className={`eachCommonElementWrapper${
        specificWrapperClass ? ' ' + specificWrapperClass : ''
      }`}
    >
      <RangePicker
        value={state}
        onChange={(data) => {
          setState(data);
          if (data?.[0] && data?.[1]) {
            setDateRange([formatDate(data[0]), formatDate(data[1])]);
            return;
          }
          setDateRange(null);
        }}
        disabled={ifDisabled}
        className="datePickerWrapper"
        popupClassName='datePickerPopup'
        format={dateFormat}
        inputReadOnly={true}
        style={{ cursor: 'pointer' }}
        onFocus={onFocus}
        status={ifShowError ? 'error' : ''}
        suffixIcon={
          ifShowError ? <WarningOutlined className="validationErrorIcon" /> : <CalendarOutlined />
        }
        {...props}
      />
    </div>
  );
};

export default DateRangePicker;
