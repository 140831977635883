import { FC } from 'react';
import { help, logout as logout_image } from '../../../assets/images';
import { useAuth } from '../../../hooks';

const LeftPanelSettingsMenu: FC = () => {
  const { logout } = useAuth();

  return (
    <ul className="leftPanelSettingsMenu">
      <li onClick={()=>  window.open('https://branchlab.atlassian.net/wiki/spaces/platform/', '_blank')}>
        <span>Help</span>
      </li>
      <li onClick={() => logout()}>
        {/* <img src={logout_image} alt="" /> */}
        <span>Logout</span>
      </li>
      
    </ul>
  );
};

export default LeftPanelSettingsMenu;
