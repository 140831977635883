import { FC, Fragment, useEffect, useState } from 'react';
import { PageHeader } from '../../../sharedComponent';
import {
  IActiveAudienceListingParam,
  ILoaderState,
} from '../../../../models/interfaces';
import { useLoaderStore } from '../../../../stores';
import { ActiveAudienceListingFilter, ActiveAudienceListingTable } from './';
import { RoutesEnum } from '../../../../models/enums/apiRoutes';
import { usePostApiInterceptor } from '../../../../hooks';

type activationAudBodyType = {
  end_date: string | null;
  start_date: string | null;
  status_id: string | null;
  limit: number;
  off_set: number;
  order_column: string | null;
  order_direction: string | null;
  search: string | null;
  partner : number | null;
};

const baseListingParam = {
  sortOrder: 'DESC',
  sortColumn: 'created_on',
  pageNumber: 1,
  rowsPerPage: 15,
  dateRange: null,
  sspName: null,
  status: null,
  partner : null
};

const ActiveAudienceListingWrap: FC = () => {
  const [activationAudListingParam, setActivationAudListingParam] =
    useState<IActiveAudienceListingParam>(baseListingParam);

  const [body, setBody] = useState<activationAudBodyType | null>(null),
    [isMounted, setIsMounted] = useState<boolean>(false);

  const { data, isLoading } = usePostApiInterceptor(
    isMounted,
    JSON.stringify(body),
    RoutesEnum.GET_ALL_ACTIVATIONS,
    () => {
      setIsMounted(false);
    }
  );
  
  const { loaderState } = useLoaderStore((state: ILoaderState) => state);

  useEffect(() => {
    setIsMounted(true);
  }, [body]);

  useEffect(() => {
    const newParams: activationAudBodyType = {
      partner : activationAudListingParam.partner,
      end_date: activationAudListingParam.dateRange?.length
        ? activationAudListingParam.dateRange[1]
        : null,
      start_date: activationAudListingParam.dateRange?.length
        ? activationAudListingParam.dateRange[0]
        : null,
      status_id: activationAudListingParam.status,
      limit: activationAudListingParam.rowsPerPage,
      off_set:
        (activationAudListingParam.pageNumber - 1) *
        activationAudListingParam.rowsPerPage,
      order_column: activationAudListingParam.sortColumn,
      order_direction: activationAudListingParam.sortOrder,
      search: null,
    };

    setBody(newParams);
  }, [activationAudListingParam]);

  return (
    <div className="audienceCommonWrap activeAudienceCommonWrap">
      <PageHeader title="Activations" />
      <div className="audienceListingPageContent">
        
        {!loaderState && (
          <Fragment>
            <div className="filterWrap">
              <ActiveAudienceListingFilter
                filterData={activationAudListingParam}
                setFilterData={setActivationAudListingParam}
              />
            </div>
            <ActiveAudienceListingTable
              activationData={data}
              isLoading={isLoading}
              paginationAndSortingData={activationAudListingParam}
              setPaginationAndSortingData={setActivationAudListingParam}
            />
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default ActiveAudienceListingWrap;
