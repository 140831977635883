import { create } from 'zustand';

export const useFeatureSets = create((set) => ({
  featureSetMasterArray: [],
  setFeatureSetMasterArray: (value: any[]) => {
    set({ featureSetMasterArray: value });
  },
  selectedReports: [],
  setSelectedReports: (value: number[]) => {
    set({ selectedReports: value });
  },
  isResearchFeatureSetdeleted: false,
  setIsResearchFeatureSetdeleted: (value: boolean) => {
    set({ isResearchFeatureSetdeleted: value });
  },
  addedTargeting: [],
  setAddedTargeting: (value: any) => {
    set({ addedTargeting: value });
  },
}));
