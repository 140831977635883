import { FC, useEffect, useState } from 'react';
import LeftPanel from '../LeftNavPanel/LeftPanel';
import AddResearchComponent from './AddResearchComponent';
import { useParams } from 'react-router-dom';
import {
  useResearchDataCheckStore,
  useResearchDataHandlingStore,
  useLoaderStore,
} from '../../../stores';
import {} from '../../../hooks';
import {
  IResearchDataCheckState,
  IResearchDataState,
  ILoaderState,
} from '../../../models/interfaces';
import { usePostApiInterceptor } from '../../../hooks';
import { RoutesEnum } from '../../../models/enums/apiRoutes';
import { useFeatureSets } from '../../../stores/newFeatureSets.store';
import SaveComponent from './NewResearchElasticSearchComponents/SaveComponent';
const AddResearch: FC = () => {
  const { id } = useParams();
  document.title = (id ? 'Update Research' : 'Create Research') + ' - BranchLab';
  const newTitle = id ? 'Update Research' : 'Create Research';

  const [body, setBody] = useState<{ research_id: string } | null>(null),
    [isMounted, setIsMounted] = useState<boolean>(false),
    [isError, setIsError] = useState<boolean>(false),
    [title, setTitle] = useState<string>('');

  const { setUpdateLoaderState } = useLoaderStore(
      (state: ILoaderState) => state
    ),
    { setIfUpdated } = useResearchDataCheckStore(
      (state: IResearchDataCheckState) => state
    ),
    { setUpdatedDataSet } = useResearchDataHandlingStore(
      (state: IResearchDataState) => state
    ),
    {
      setAddedTargeting,
      setFeatureSetMasterArray,
      setIsResearchFeatureSetdeleted,
      setSelectedReports,
    } = useFeatureSets((state: any) => state);

  usePostApiInterceptor(
    isMounted,
    JSON.stringify(body),
    RoutesEnum.GET_RESEARCH_INFO,
    (status: number, data: any, error: any) => {
      setIsMounted(false);
      setUpdateLoaderState(false);
      if (error || status !== 200 || !data || (data && !data.research_info)) {
        setIsError(true);
        setUpdateLoaderState(false);
        return;
      }
      setUpdatedDataSet(data.research_info);
      setIfUpdated(true);
    }
  );

  useEffect(() => {
    if (id) {
      isError && setIsError(false);
      setUpdateLoaderState(true);
      setBody({ research_id: id });
      setIsMounted(true);
    }
    setUpdatedDataSet({});
    setFeatureSetMasterArray([]);
    setAddedTargeting([]);
    setIsResearchFeatureSetdeleted(false);
    setSelectedReports([]);
  }, [id]);
  return (
    <div className="audienceSetupPage">
      <LeftPanel defaultSelectedKeys={['4']} />
      <AddResearchComponent
        pageHeader={newTitle}
        title={title}
        setTitle={setTitle}
      />
      <SaveComponent title={title} />
    </div>
  );
};

export default AddResearch;
