import { useNavigate } from 'react-router-dom';
import {
  useCheckDependentTargetingSelectedStore,
  useCheckIfAllMessageAreCreatedStore,
  useCheckSavingEnabilityStore,
  useFeatureSetDataCheckStore,
  useFeatureSetDataHandlingStore,
  useCheckIfDataModifiedStore,
} from '../stores';
import {
  ICheckIfAllMessageAreCreatedState,
  ICheckIfDataModifiedState,
  IDependentTargetingSelectedState,
  IEnableSaveState,
  IFeatureSetDataCheckState,
  IFeatureSetDataState,
} from '../models/interfaces';

const useRedirection = () => {
  const navigate = useNavigate();

  const { setIsInitiatedSaving, setIfUpdated, setIfUpdatedDataPopulated } =
      useFeatureSetDataCheckStore((state: IFeatureSetDataCheckState) => state),
    { setIsEnableSaveForEachSet } = useCheckSavingEnabilityStore(
      (state: IEnableSaveState) => state
    ),
    { setSavingDataSet, setUpdatedDataSet } = useFeatureSetDataHandlingStore(
      (state: IFeatureSetDataState) => state
    ),
    { setIsDependentTargetingSelectedForEachSet } =
      useCheckDependentTargetingSelectedStore(
        (state: IDependentTargetingSelectedState) => state
      ),
    { setIfAllMessageAreCreated } = useCheckIfAllMessageAreCreatedStore(
      (state: ICheckIfAllMessageAreCreatedState) => state
    ),
    { setIfDataModified } = useCheckIfDataModifiedStore(
      (state: ICheckIfDataModifiedState) => state
    );

  const resetSavingStates = () => {
    setIfDataModified(false);
    setIsInitiatedSaving(false);
    setIfAllMessageAreCreated({});
    setIsDependentTargetingSelectedForEachSet({});
    setIsEnableSaveForEachSet({});
    setSavingDataSet({});
  };

  const resetUpdatingStates = () => {
    setIfUpdated(false);
    setUpdatedDataSet({});
    setIfUpdatedDataPopulated(false);
  };

  const resetAllStates = () => {
    setIfDataModified(false);
    setIsInitiatedSaving(false);
    setIfAllMessageAreCreated({});
    setIsDependentTargetingSelectedForEachSet({});
    setSavingDataSet({});
    setUpdatedDataSet({});
    setIfUpdatedDataPopulated(false);
  };

  const redirectAudienceListing = () => {
    resetSavingStates();
    resetUpdatingStates();
    navigate('/');
  };

  const redirectAudienceUpdate = (id:number) => {
    navigate('/update-audience/'+ id)
  }

  const redirectPixelListing = () => {
    navigate('/pixel-listing');
  };

  const redirectPixelSetup = (id?: number) => {
    if (id) {
      navigate('/update-pixel/' + id);
    } else {
      navigate('/create-Pixel');
    }
  };

  const redirectAudienceSetup = (id?: number) => {
    resetSavingStates();
    if (id) {
      navigate('/update-audience/' + id);
    } else {
      navigate('/create-audience');
    }
  };

  const redirectAudienceView = (id:number) => {
    navigate('/view-audience/' + id)
  }

  const redirectAddResearch = (id?: number) => {
    resetSavingStates();
    if (id) {
      navigate('/update-research/' + id);
    } else {
      navigate('/add-research');
    }
  };

  const redirectAudienceActivations = () => {
    resetAllStates();
    resetSavingStates();
    resetUpdatingStates();
    navigate('/activations');
  };

  const redirectResearch = () => {
    resetSavingStates();
    resetUpdatingStates();
    navigate('/research');
  //  navigate('/add-research');
  };

  const redirectAudienceActivationSetup = (audId: number, actId?: number) => {
    resetAllStates();
    resetSavingStates();
    resetUpdatingStates();
    navigate('/audience-activation/' + audId + (actId ? '/' + actId : ''));
  };
  const redirectLogin = () => {
    resetSavingStates();
    resetUpdatingStates();
    navigate('/login');
  };

  return {
    redirectLogin,
    resetAllStates,
    redirectPixelSetup,
    redirectPixelListing,
    redirectAudienceSetup,
    redirectAudienceView,
    redirectAudienceListing,
    redirectAudienceUpdate,
    redirectAudienceActivations,
    redirectAudienceActivationSetup,
    redirectResearch,
    redirectAddResearch,
  };
};

export default useRedirection;
