import React, { FC, Fragment, useEffect } from 'react';
import ResearchTitle from './ResearchTitle';
import NewFeatureSets from './NewFeatureSets';
import ResearchToGenerate from '../ResearchToGenerate';
import { useFeatureSets } from '../../../../stores/newFeatureSets.store';
import { useResearchDataHandlingStore } from '../../../../stores';
import { IResearchDataState } from '../../../../models/interfaces';

const NewFeatureSetsWrap: FC<{
  title: string;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
}> = ({ title, setTitle }) => {
  const { updatedDataSet } = useResearchDataHandlingStore(
    (state: IResearchDataState) => state
  );
  const { setSelectedReports } = useFeatureSets((state: any) => state);

  useEffect(() => {
    setTitle(updatedDataSet.title ?? '');
    setSelectedReports([]);
  }, [updatedDataSet]);

  return (
    <div className="updateResearchContentWrap">
      <ResearchTitle setTitle={setTitle} title={title} defaultTitleValue="" />
      <NewFeatureSets />
      <ResearchToGenerate setParentSelectedReports={setSelectedReports} />
    </div>
  );
};

export default NewFeatureSetsWrap;
