import { FC, useState } from 'react';
import { MenuProps, Button, Menu, Tooltip } from 'antd';
import {
  LeftPanelHeader,
  LeftPanelSettingsMenu,
  LeftPanelMainMenu,
  LeftPanelActiveAudience,
  LeftPanelPixelMenu,
  LeftPanelResearch,
} from './';
import { useRedirection } from '../../../hooks';
import {
  audience,
  collapsedLogo,
  collapsedArrow,
  activeAudience,
  research,
  pixel
} from '../../../assets/images';

type MenuItem = Required<MenuProps>['items'][number];
function getItem(
  
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  disabled?: boolean,
  onClick? : () => void
): MenuItem {
  return {
    label,
    key,
    icon,
    disabled,
    onClick
  } as MenuItem;
}

interface LeftPanelProps {
  defaultSelectedKeys?: string[];
}

const LeftPanel: FC<LeftPanelProps> = ({ defaultSelectedKeys }) => {
  let getIsCollapseStorage = JSON.parse(localStorage.getItem('collapse') ?? '0');
  const [collapsed, setCollapsed] = useState(getIsCollapseStorage === 0 ? false : true);

  const { redirectAudienceListing, redirectAudienceActivations, redirectPixelListing, redirectResearch } = useRedirection();

  const items: MenuItem[] = [
    getItem(
      <LeftPanelHeader />,
      '1',
      <img src={collapsedLogo} className="collapsedLogo" alt="" />,
      true
    ),
    getItem(
      <LeftPanelMainMenu />,
      '2',
     <Tooltip placement="right" title="Audiences">
       <img
        src={audience}
        className=""
        alt=""
      />
     </Tooltip>,
     false,
    redirectAudienceListing
    ),
    getItem(
      <LeftPanelActiveAudience />,
      '3',
      <Tooltip placement="right" title="Activation">
        <img
        src={activeAudience}
        className=""
        alt=""
      />
      </Tooltip>,
      false,
      redirectAudienceActivations
    ),
    getItem(
      <LeftPanelResearch />,
      '4',
      <Tooltip placement="right" title="Research">
         <img
        src={research}
        className=""
        alt=""
      />
      </Tooltip>,
      false,
      redirectResearch
    ),
    getItem(
      <LeftPanelPixelMenu />,
      '5',
      <Tooltip placement="right" title="Pixels">
        <img
        src={pixel}
        className=""
        alt=""
      />
      </Tooltip>,
      false,
      redirectPixelListing
    ),
    getItem('', 'setting', <LeftPanelSettingsMenu />),
  ];

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
    localStorage.setItem('collapse', !collapsed === true ? '1' : '0');
  };

  return (
    <div
      style={{ width: collapsed ? '100px' : '282px' }}
      className={`leftPanelWrap${collapsed ? ' leftPanelCollapsed' : ''}`}
    >
      <Button
        type="primary"
        onClick={toggleCollapsed}
        className={` ${
          collapsed ? 'collapsedStatecCollapseBtn' : 'collapseBtn'
        }`}
      >
        <img src={collapsedArrow} alt="" />
      </Button>
      <Menu
        defaultSelectedKeys={defaultSelectedKeys}
        mode="inline"
        theme="dark"
        inlineCollapsed={collapsed}
        items={items}
        className="leftPanelMenu"
      />
    </div>
  );
};

export default LeftPanel;
