import { create } from 'zustand';
import {
  IUserInformationState,
  UserInformationType,
} from '../models/interfaces';

export const useUserInformationStore = create<IUserInformationState>((set) => ({
  userInformation: { user_id: null, email: null, timestamp: null },
  setUserInformation: (value: UserInformationType) =>
    set({ userInformation: value }),

  userIsLoggedIn: false,
  setUserIsLoggedIn: (value: boolean) => set({ userIsLoggedIn: value }),
}));
