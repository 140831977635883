import { FC } from "react"
import { emptyData } from "../../../assets/images"

const CustomEmptyTableComponent: FC<{ isEstimate: boolean }> = ({ isEstimate }) => {
    return (
        <span className="emptyTableDataWrap">
            <img src={emptyData} alt="" />
            {
                <p>{isEstimate ? "This audience has not been estimated yet." : "This audience has not been activated yet."}</p>
            }
        </span>
    )
}

export default CustomEmptyTableComponent