export enum RoutesEnum {
  // User information
  USER_INFORMATION = 'get-user-info',

  // Audience Listing
  AUDIENCE_LISTING_DATA = 'get-audience-list',

  // Pixel Listing 
  PIXEL_LISTING_DATA = 'get-pixel-list',
  SAVE_PIXEL_DATA = 'save-pixel',
  GET_PIXEL_DATA = 'get-pixel',
  DELETE_PIXEL_DATA = 'delete-pixel',

  // Audience Setting
  GET_MASTER_DATA = 'get-master-data',
  GET_FEATURE_SET_SUMMARY = 'get-feature-summery',
  GET_FEATURE_SET_DATA = 'get-audience-info',
  SAVE_FEATURE_SET_DATA = 'save-audience',
  DELETE_FEATURE_SET_DATA = 'delete-audience',
  DUPLICATE_AUDIENCE = 'duplicate-audience',
  GET_PRESIGNED_URL = 'get-presigned-url',
  GET_ES_RESULT = 'get-elasticsearch-result',

  // Audience Activation
  GET_ALL_ACTIVATIONS = 'get-activation-list',
  GET_ACTIVATION = 'get-activation-info',
  SAVE_ACTIVATION = 'save-activation',
  DUPLICATE_ACTIVATION = 'clone-activation',
  SAVE_ESTIMATE = 'save-estimate',
  SAVE_ACTIVATES = 'save-activated-audiences',

  //Research List
 GET_RESEARCH_LIST= "get-researche-list",
GET_RESEARCH_INFO="get-research-info",
SAVE_RESEARCH="save-research",
 DELETE_RESEARCH="delete-research"
}
