/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { Button, Upload } from 'antd';
import {
  DeleteOutlined,
  FileAddOutlined,
  PaperClipOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import type { UploadFile } from 'antd';
import { ICustomNPIList, IEnableSaveState } from '../../../models/interfaces';
import { useCheckSavingEnabilityStore } from '../../../stores';

const UploadUserFiles: FC<{
  uuid: number;
  componentSpecificClasses?: string[];
  isDisabled?: boolean;
  customNPIList: ICustomNPIList[];
  setCustomNPIList: React.Dispatch<React.SetStateAction<ICustomNPIList[]>>;
  targetingSetNumber?: number;
  featureSetNumber?: number;
}> = ({
  uuid,
  componentSpecificClasses,
  isDisabled = false,
  customNPIList,
  setCustomNPIList,
  targetingSetNumber,
  featureSetNumber,
}) => {
  const [fileState, setFileState] = useState<UploadFile[]>([]),
    [displayState, setDisplayState] = useState<ICustomNPIList[]>([]),
    [keyCount, setKeyCount] = useState<number>(customNPIList.length),
    [ifShowError, setIfShowError] = useState<boolean>(false);

  const { isFeatureSetsFilled, updateIisFeatureSetsFilled } =
    useCheckSavingEnabilityStore((state: IEnableSaveState) => state);

  const onFocus = () => {
    ifShowError &&
      updateIisFeatureSetsFilled(featureSetNumber, targetingSetNumber, 0);
  };

  useEffect(() => {
    if (
      featureSetNumber &&
      targetingSetNumber &&
      isFeatureSetsFilled[`${featureSetNumber}`][`${targetingSetNumber}`] === 1
    ) {
      setIfShowError(true);
      return;
    }
    setIfShowError(false);
  }, [isFeatureSetsFilled]);

  useEffect(() => {
    if (customNPIList?.length) {
      setDisplayState(customNPIList);
    }
  }, [customNPIList]);

  const handleDelete = (ce: ICustomNPIList) => {
    const newDisplay = displayState
      .filter((el) => el.key !== ce.key)
      .map((el, index) => ({
        ...el,
        key: index,
      }));

    setDisplayState(newDisplay);
    setCustomNPIList(newDisplay);

    if (ce.file) {
      const index = fileState.indexOf(ce.file),
        newFileList = fileState.slice();

      newFileList.splice(index, 1);
      setFileState(newFileList);
    }
  };

  return (
    <div className="eachCommonElementWrapper uploadFileWrapper">
      <Upload
        accept=".csv"
        showUploadList={false}
        fileList={fileState}
        beforeUpload={(file) => {
          const timeStamp = new Date().valueOf(),
            fileNameArray = file.name.split('.'),
            newKey = keyCount + 1,
            newFileState = [...fileState, file],
            newDisplayState = [
              ...displayState,
              {
                key: newKey,
                uuid: uuid,
                name: `${fileNameArray[0]}_${timeStamp}.${fileNameArray[1]}`,
                displayName: file.name,
                file: file,
              },
            ];

          setKeyCount(newKey);
          setFileState(newFileState);
          setDisplayState(newDisplayState);
          setCustomNPIList(newDisplayState);
          return false;
        }}
        className={`uploadFileBaseStyle${
          fileState?.length ? ' showUploadBorder' : ''
        }${
          componentSpecificClasses?.length
            ? ' ' + componentSpecificClasses.join(' ')
            : ''
        }`}
        disabled={isDisabled}
      >
        <Button
          type="primary"
          danger={Boolean(ifShowError)}
          ghost
          icon={
            ifShowError ? (
              <WarningOutlined className="validationErrorIcon" />
            ) : (
              <FileAddOutlined />
            )
          }
          onFocus={onFocus}
        >
          Select File
        </Button>
      </Upload>
      <div
        className="npiDisplayContainer"
        style={!displayState.length ? { display: 'none' } : {}}
      >
        {displayState.map((el: ICustomNPIList) => (
          <div key={el.key} className="npiDisplayEachElemWrap">
            <div>
              <PaperClipOutlined className="npiDisplayElemPaperClip" />
              {el.displayName}
            </div>
            <div>
              <DeleteOutlined
                className="npiDisplayElemBin"
                onClick={() => handleDelete(el)}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UploadUserFiles;
