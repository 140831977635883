import { FC } from 'react';
import { Link } from 'react-router-dom';
import { collapsedArrow, logo } from '../../../assets/images';

const LeftPanelHeader: FC = () => {
  return (
    <div className="left-panel-header">
      <Link to="/">
        {' '}
        <img src={logo} alt="Logo" />{' '}
      </Link>
      <img src={collapsedArrow} className="collapsedArrow" alt="" />
    </div>
  );
};

export default LeftPanelHeader;
