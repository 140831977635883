import { create } from 'zustand';
import {
  IAudienceSaveMethods,
  IAudienceSetUpState,
  IAudienceState,
} from '../models/interfaces/store.interface';
import { IFeatureSetBaseData } from '../models/interfaces';

export const useAudienceSetUp = create<IAudienceSetUpState>((set) => ({
  audienceTitle: '',
  setAudienceTitle: (value: string) => {
    set({ audienceTitle: value });
  },
  audienceAgency: '',
  setAudienceAgency: (value: string) => {
    set({ audienceAgency: value });
  },
  audienceBrand: '',
  setAudiencebrand: (value: string) => {
    set({ audienceBrand: value });
  },
  audiencePageNumber: 1,
  setAudiencePageNumber: (value: number) => {
    set({ audiencePageNumber: value });
  },
  advertiser : '',
  setAdvertiser : (value: string) => {
    set({ advertiser : value });
  }
}));

export const useAudienceSaveMethods = create<IAudienceSaveMethods>((set) => ({
  isSaveInitiated: false,
  setIsSaveInitiated: (value: boolean) => {
    set({ isSaveInitiated: value });
  },
}));

export const useAudienceSets = create<IAudienceState>((set) => ({
  isDataSaved: false,
  setIsDataSaved: (value: boolean) => {
    set({ isDataSaved: value });
  },
  selectedSavingOpt: '1',
  setSelectedSavingOpt: (value: string) => {
    set({ selectedSavingOpt: value });
  },
  deletedIds: null,
  setDeletedIds: (value: number[] | null) => {
    set({ deletedIds: value });
  },
  copyFeatureSetData: null,
  setCopyFeatureSetData: (data: any) => {
    set({ copyFeatureSetData: data });
  },
  tempFeatureSet: null,
  setTempFeatureSetData: (data: any) => {
    set({ tempFeatureSet: data });
  },
  tempAddTargetting: [],
  setTempAddTargetting: (data: number[]) => {
    set({ tempAddTargetting: data });
  },
  globalFeatureCount: 0,
  setGlobalFeatureCount: (data: number) => {
    set({ globalFeatureCount: data });
  },
  isDuplicated: false,
  setIsDuplicated: (data: boolean) => {
    set({ isDuplicated: data });
  },
  globalFeatureData: null,
  setGlobalFeatureData: (data: any) => {
    set({ globalFeatureData: data });
  },
  isCancelled: false,
  setIsCancelled: (value: boolean) => {
    set({ isCancelled: value });
  },
  firstFeatureNumber: null,
  setFirstFeatureNumber: (value: number) => {
    set({ firstFeatureNumber: value });
  },
  globalFeatureTargetting: null,
  setGlobalFeatureTargetting: (value: any) => {
    set({ globalFeatureTargetting: value });
  },
  updateGlobalFeature: (
    featureSetNumber: number,
    targeting: number[],
    featureSetValues: IFeatureSetBaseData,
    operator: number
  ) => {
    set((state) => {
      return {
        globalFeatureTargetting: {
          ...state.globalFeatureTargetting,
          [featureSetNumber]: {
            targeting,
            featureSetValues,
            operator,
          },
        },
      };
    });
  },
  globalFeaturSetData: null,
  setGlobalFeatureSetData: (value: any) => {
    set({ globalFeaturSetData: value });
  },
  isBacked: false,
  setIsBacked: (value: any) => {
    set({ isBacked: value });
  },
  isCollapsed: false,
  setIsCollapsed: (value: boolean) => {
    set({ isCollapsed: value });
  },

  isClone: false,
  setIsClone: (value: any) => {
    set({ isClone: value });
  },
}));
