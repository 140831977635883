import { Dispatch, FC, SetStateAction } from 'react';
import Modal from 'antd/es/modal/Modal';
import { IDeleteParams } from '../../../models/interfaces';
import { modalDeleteIcon } from '../../../assets/images';

const ModalComponent: FC<{
  modalHeader: string;
  modalToOpen: boolean;
  setModalToOpen: Dispatch<SetStateAction<boolean>>;
  deleteSetParams?: IDeleteParams;
}> = ({ modalHeader, modalToOpen, setModalToOpen, deleteSetParams }) => {
  const removeTargetingElement = () => {
    if (deleteSetParams?.handleDelete) {
      deleteSetParams.handleDelete([deleteSetParams.setId, deleteSetParams.fieldName],deleteSetParams.index);
    }

    if (deleteSetParams?.handelOk) {
      deleteSetParams.handelOk();
    }

    setModalToOpen(false);
  };

  const cancelModal = () => {
    if (deleteSetParams?.handleCancel) {
      deleteSetParams.handleCancel();
    }

    setModalToOpen(false);
  };

  return (
    <Modal
      centered
      open={modalToOpen}
      onOk={removeTargetingElement}
      onCancel={cancelModal}
      okText={deleteSetParams?.okText ? deleteSetParams.okText : 'Delete'}
      cancelText={
        deleteSetParams?.cancelText ? deleteSetParams.cancelText : 'Cancel'
      }
      className="commonModal"
      width={'330px'}
    >
      <div className="modalGenericContent">
        {/* <img src={modalDeleteIcon} alt="" /> */}
        <div className="modalGenericTextContent">
          <p>{modalHeader}</p>
          {deleteSetParams?.message ? (
            <p>{deleteSetParams.message}</p>
          ) : (
            <p>Are you sure you want to delete {deleteSetParams?.name}?</p>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ModalComponent;
