/* eslint-disable react-hooks/exhaustive-deps */
import { CSSProperties, FC } from 'react';
import { Slider, SliderSingleProps } from 'antd';

const AgeSlider: FC<{
  limits?: { min: number; max: number };
  defaultValue: number[];
  componentSpecificClasses?: string[];
  isDisabled?: boolean;
  onChange: React.Dispatch<React.SetStateAction<number[]>>;
}> = ({
  limits = { min: 13, max: 115 },
  defaultValue,
  componentSpecificClasses,
  isDisabled = false,
  onChange,
}) => {
  const marksStyle: CSSProperties = {
    fontWeight: 600,
    color: '#b5b5b5',
    paddingTop: 0,
  };

  const marks: SliderSingleProps['marks'] = {
    [limits.min]: {
      style: { ...marksStyle },
      label: limits.min,
    },
    [limits.max]: {
      style: { ...marksStyle },
      label: limits.max,
    },
  };

  return (
    <div className="eachCommonElementWrapper commonSliderComponent">
      <Slider
        range={{ draggableTrack: false }}
        min={limits.min}
        max={limits.max}
        marks={marks}
        defaultValue={defaultValue}
        disabled={isDisabled}
        onChange={onChange}
        className={`sliderBody ${
          componentSpecificClasses?.length
            ? ' ' + componentSpecificClasses.join(' ')
            : ''
        }`}
        tooltip={{
          open: true,
          placement: 'bottom',
          rootClassName: 'sliderTooltipWrap',
          autoAdjustOverflow: false,
        }}
      />
    </div>
  );
};

export default AgeSlider;
