import { LeftOutlined } from '@ant-design/icons';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  PieChart,
  Pie,
  AreaChart,
  Area,
  Cell,
  ResponsiveContainer,
  Legend,
} from 'recharts';
import { useAudienceSets } from '../../../../../stores/audienceSets.store';
import { IAudienceState } from '../../../../../models/interfaces';

const COLORS = ['#B1D1B7', '#C05F52', '#3170EC'];

const LineData = [
  {
    name: '1',
    pv: 1,
    amt: 0,
  },
  {
    name: '2',
    pv: 3,
    amt: 3,
  },
  {
    name: '3',
    pv: 2,
    amt: 2,
  },
  {
    name: '4',
    pv: 4,
    amt: 4,
  },
  {
    name: '5',
    pv: 5,
    amt: 4,
  },
];

const AreaData = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'Page G',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

const PieData = [
  { name: 'Group A', value: 400 },
  { name: 'Group B', value: 300 },
  { name: 'Group C', value: 300 },
];

const AudienceInsightsWrap = () => {
  const { isCollapsed, setIsCollapsed } = useAudienceSets(
    (state: IAudienceState) => state
  );
  return (
    <div
      className={`rightChartWrap${isCollapsed ? ' collapsedRightPanel' : ''}`}
    >
      <p>
        <LeftOutlined
          onClick={() => {
            setIsCollapsed(!isCollapsed);
          }}
        />
        Audience Insights
      </p>

      <div className="chartElemWrap">
        <div className="chart1">
          <b>Lorem Ipsum</b>
          <b>
            1000 <span>lorem</span>
          </b>
          <ResponsiveContainer width="100%" height="80%">
            <AreaChart
              data={LineData}
              syncId="anyId"
              margin={{
                top: 10,
                right: 0,
                left: -50, // Set left margin to 0
                bottom: 0,
              }}
              className="areaChart1"
            >
              <defs>
                <linearGradient
                  id="myGradient"
                  x1="0%"
                  y1="0%"
                  x2="0%"
                  y2="100%"
                >
                  <stop offset="0%" stopColor="#DBE7FF" stopOpacity={1} />
                  <stop offset="100%" stopColor="#fff" stopOpacity={1} />
                </linearGradient>
              </defs>
              <CartesianGrid
                vertical={true}
                horizontal={false}
                stroke="#B1B1B1"
                strokeDasharray="0.5"
              />
              <XAxis dataKey="name" axisLine={false} tickLine={false} />{' '}
              <YAxis />
              <Tooltip />
              <Area
                type="monotone"
                dataKey="pv"
                stroke="#3170EC"
                fill="url(#myGradient)"
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>

        <div className="chart2">
          <ResponsiveContainer width="100%" height="80%">
            <PieChart>
              <Pie
                data={PieData}
                cx={0}
                innerRadius={60}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={5}
                dataKey="value"
                cornerRadius={10}
                label={false}
              >
                {PieData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Legend
                layout="vertical"
                verticalAlign="bottom"
                align="left"
                iconType="square"
                wrapperStyle={{
                  paddingLeft: 20,
                }}
              />
            </PieChart>
          </ResponsiveContainer>
        </div>

        <div className="chart3">
          <ResponsiveContainer width="100%" height="80%">
            <AreaChart
              data={AreaData}
              margin={{
                top: 10,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Area
                type="monotone"
                dataKey="uv"
                stroke="#8884d8"
                fill="#8884d8"
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default AudienceInsightsWrap;
