import Cookies from 'js-cookie';
import { useCookies, useRedirection } from './';
import { useUserInformationStore } from '../stores';
import { IUserInformationState } from '../models/interfaces';

type UserType = { user_id: number; email: string; timestamp?: number };

const useAuth = () => {
  const { redirectLogin, redirectAudienceListing } = useRedirection(),
    { createCookie, deleteCookie } = useCookies();

  const { setUserIsLoggedIn } = useUserInformationStore(
    (state: IUserInformationState) => state
  );

  const login = (user: UserType) => {
    createCookie(user);
    redirectAudienceListing();
  };

  const logout = () => {
    setUserIsLoggedIn(false);
    deleteCookie();
    redirectLogin();
  };

  const getUserInfo = () => {
    try {
      const token = Cookies.get('branch-lab');

      if (token) {
        const user: any = JSON.parse(atob(token));

        Cookies.remove('branch-lab');
        createCookie(user);

        return { ...user, timeStamp: Date.now() };
      }
      return null;
    } catch (error) {
      return null;
    }
  };

  return {
    login,
    logout,
    getUserInfo,
    createCookie,
    deleteCookie,
  };
};

export default useAuth;
