/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import {
  DateRangeType,
  IResearchListingParam,
  IMasterReportsDDState,
} from '../../../../models/interfaces';
import { useMasterReportsElementsStore } from '../../../../stores';
import { Col, Row } from 'antd';
import {
  CommonElementLabel,
  DateRangePicker,
  Multi,
} from '../../../sharedComponent';

const ResearchListingFilter: FC<{
  filterData: IResearchListingParam;
  setFilterData: React.Dispatch<React.SetStateAction<IResearchListingParam>>;
}> = ({ filterData, setFilterData }) => {
  const [dateRange, setDateRange] = useState<DateRangeType>(null),
    [selectedReports, setSelectedReports] = useState<(number | string)[]>([]),
    [isInitialLoading, setIsInitialLoading] = useState<boolean>(true);
  const { reportsDDElements } = useMasterReportsElementsStore(
    (state: IMasterReportsDDState) => state
  );

  const updateFilters = () => {
    setFilterData({
      ...filterData,
      dateRange,
      report_id: selectedReports?.length ? selectedReports.toString() : null,
      pageNumber: 1,
    });
  };

  useEffect(() => {
    if (isInitialLoading) {
      setIsInitialLoading(false);
      return;
    }
    updateFilters();
  }, [dateRange, selectedReports]);

  return (
    <Row className="audienceListDateRangeFilterWrap">
      <Col span={8}>
        <CommonElementLabel label="Created On" />
        <DateRangePicker
          dateRange={dateRange}
          ifDisabled={false}
          setDateRange={setDateRange}
          props={{ autoFocus: false }}
        />
      </Col>
      <Col span={8}>
        <CommonElementLabel label="Report Types" />
        <Multi
          options={reportsDDElements}
          placeholder="Report Types"
          onChange={setSelectedReports}
        />
      </Col>
    </Row>
  );
};

export default ResearchListingFilter;
