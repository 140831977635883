/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, FC, Fragment, useState } from 'react';
import { useAuth, useRedirection } from '../hooks';

const UnAuthGuard: FC<{ component: JSX.Element }> = ({ component }) => {
  const [status, setStatus] = useState(false);

  const { getUserInfo, logout } = useAuth(),
    { redirectAudienceListing } = useRedirection();

  useEffect(() => {
    checkCookie();
  }, [component]);

  const checkCookie = () => {
    try {
      const userInfo = getUserInfo();
      if (!userInfo?.user_id) {
        logout();
      } else {
        redirectAudienceListing();
      }
      setStatus(true);
      return;
    } catch (error) {
      logout();
    }
  };

  return status ? <Fragment>{component}</Fragment> : <></>;
};

export default UnAuthGuard;
