import { Button, Table, TableColumnsType } from 'antd';
import dayjs from 'dayjs';
import React, { FC } from 'react';
import CustomEmptyTableComponent from '../../../sharedComponent/Empty/CustomEmptyTableComponent';

interface DTCdataType {
  date: string;
  audience_size: number;
  patient_population: number;
  passing_score: number;
}

interface HCPdataType {
  date: string;
  audience_size: number;
}

const AudienceEstimateTable: FC<{
  selectedAudienceType: number | null;
  isLoading: boolean;
  data: [];
  isActive: boolean;
  generateEstimate: () => void;
}> = ({
  isLoading,
  data,
  selectedAudienceType,
  generateEstimate,
  isActive,
}) => {
  const dtcColumns: TableColumnsType<DTCdataType> = [
    {
      title: 'Date',
      dataIndex: 'processed_at',
      key: 'processed_at',
      render: (text) => dayjs(text).format('M/D/YYYY'),
      width: '180px',
      showSorterTooltip: false,
    },
    {
      title: 'Audience Size',
      dataIndex: 'size',
      key: 'size',
      width: '130px',
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: 'Patient Population',
      dataIndex: 'population',
      key: 'population',
      width: '130px',
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: 'Passing Score',
      dataIndex: 'score',
      key: 'score',
      width: '130px',
      ellipsis: true,
      showSorterTooltip: false,
    },
  ];

  const hcpColumns: TableColumnsType<HCPdataType> = [
    {
      title: 'Date',
      dataIndex: 'processed_at',
      key: 'Date',
      width: '180px',
      showSorterTooltip: false,
      render: (text) => dayjs(text).format('M/D/YYYY'),
    },
    {
      title: 'Audience Size',
      dataIndex: 'size',
      key: 'size',
      width: '130px',
      ellipsis: true,
      showSorterTooltip: false,
    },
  ];

  function formatEstimatedSizes(size: number) {
    const newSize = size * 10;
    return newSize.toLocaleString();
  }

  function manipulateDTCArray(dataArray: DTCdataType[]) {
    return dataArray?.map((item: any) => {
      return {
        ...item,
        size: formatEstimatedSizes(item.size),
        population: formatEstimatedSizes(item.population),
        score: formatEstimatedSizes(item.score),
      };
    });
  }

  function manipulateHCPArray(dataArray: HCPdataType[]) {
    return dataArray?.map((item: any) => {
      return {
        ...item,
        size: formatEstimatedSizes(item.size),
      };
    });
  }

  return (
    <>
      <div className="sectionHeader audienceEstimate">
        <h2>Audience Estimates</h2>
        <Button
          className="primaryBtn"
          disabled={isActive}
          onClick={generateEstimate}
          size="large"
        >
          Generate Estimate
        </Button>
      </div>
      {selectedAudienceType === 1 && (
        <Table
          columns={dtcColumns}
          dataSource={manipulateDTCArray(data) ?? []}
          className={`antTableElement audienceListTable${
            isLoading ? ' hideNoDataLabel' : ''
          }`}
          locale={{
            emptyText: <CustomEmptyTableComponent isEstimate />,
          }}
          loading={isLoading}
          pagination={false}
          sortDirections={['ascend', 'descend', 'ascend']}
          scroll={data.length > 0 ? { x: 'max-content' } : undefined}
        />
      )}
      {selectedAudienceType === 2 && (
        <Table
          columns={hcpColumns}
          dataSource={manipulateHCPArray(data) ?? []}
          locale={{
            emptyText: <CustomEmptyTableComponent isEstimate />,
          }}
          className={`antTableElement audienceListTable${
            isLoading ? ' hideNoDataLabel' : ''
          }`}
          pagination={false}
          loading={isLoading}
          sortDirections={['ascend', 'descend', 'ascend']}
          scroll={data.length > 0 ? { x: 'max-content' } : undefined}
        />
      )}
    </>
  );
};

export default AudienceEstimateTable;
