import {FC} from 'react';
import { LeftPanel } from '../LeftNavPanel';
import { ResearchListingWrap } from './ResearchListingComponents';

const ResearchListingPage: FC<{}> = () => {
  document.title = 'Research - BranchLab';

  return (
    <>
      <LeftPanel defaultSelectedKeys={['4']} />
      < ResearchListingWrap />
    </>
  );
};

export default ResearchListingPage;