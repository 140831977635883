import { Route } from 'react-router-dom';
import { AuthGuard } from '../guards';
import {
  ActiveAudienceListingPage,
  AudienceActivationPage,
  AudienceListingPage,
  AudienceSetupPage,
  PixelListingPage,
  PixelSetupPage,
  ResearchListingPage,
} from '../components/pages';
import AddResearch from '../components/pages/Research/AddResearch';
import ViewAudiencePage from '../components/pages/ViewAudience/ViewAudiencePage';

const AuthRoutes = [
  <Route
    key="Audience Listing"
    path="/"
    element={<AuthGuard component={<AudienceListingPage />} />}
  />,
  <Route
    key="Create Audience Listing"
    path="/create-audience"
    element={<AuthGuard component={<AudienceSetupPage />} />}
  />,
  <Route
    key="Update Audience Listing"
    path="/update-audience/:id"
    element={<AuthGuard component={<AudienceSetupPage />} />}
  />,
  <Route
    key="View Audience"
    path="/view-audience/:id"
    element={<AuthGuard component={<ViewAudiencePage />} />}
  />,
  <Route
    key="Audience Activation"
    path="/activations"
    element={<AuthGuard component={<ActiveAudienceListingPage />} />}
  />,
  <Route
    key="Activate Audiences"
    path="/audience-activation/:audId"
    element={<AuthGuard component={<AudienceActivationPage />} />}
  />,
  <Route
    key="Activate Audiences"
    path="/audience-activation/:audId/:actId"
    element={<AuthGuard component={<AudienceActivationPage />} />}
  />,
  <Route
    key="Pixel Listing"
    path="/pixel-listing"
    element={<AuthGuard component={<PixelListingPage />} />}
  />,
  <Route
    key="Create Pixel Listing"
    path="/create-Pixel"
    element={<AuthGuard component={<PixelSetupPage />} />}
  />,
  <Route
    key="Update Pixel Listing"
    path="/update-pixel/:id"
    element={<AuthGuard component={<PixelSetupPage />} />}
  />,
<Route
    key="Research"
    path="/research"
    element={<AuthGuard component={<ResearchListingPage />} />}
    />,
    <Route
    key="Create Research Listing"
    path="/add-research"
    element={<AuthGuard component={<AddResearch />} />}
  />,
  <Route
  key="Edit Research Listing"
  path="/update-research/:id"
  element={<AuthGuard component={<AddResearch />} />}
/>
];

export default AuthRoutes;
