/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import Single from './Single';
import {
  DateRangeType,
  IDeleteParams,
  IEachDropdownElementsType,
} from '../../../models/interfaces';
import DateRangePicker from '../DateRangePicker/DateRangePicker';
import { CommonElementLabel } from '../';
import dayjs, { Dayjs } from 'dayjs';

const DateDropdown: FC<{
  label: string;
  isMandatory?: boolean;
  options: IEachDropdownElementsType[];
  placeholder: string;
  isSearchable?: boolean;
  componentSpecificClassesDD?: string[];
  timeFrame: number | null;
  dateRange: DateRangeType;
  setTimeFrame: React.Dispatch<React.SetStateAction<number | null>>;
  setDateRange: React.Dispatch<React.SetStateAction<DateRangeType>>;
  deleteSetParams?: IDeleteParams;
  minDate?: Dayjs;
  maxDate?: Dayjs;
  featureSetNumber?: number;
  targetingSetNumber?: number;
  isDisabled?: boolean
}> = ({
  label,
  isMandatory = false,
  options,
  placeholder,
  isSearchable,
  componentSpecificClassesDD,
  timeFrame,
  dateRange,
  setTimeFrame,
  setDateRange,
  deleteSetParams,
  minDate,
  maxDate,
  featureSetNumber,
  targetingSetNumber,
  isDisabled
}) => {
  const [state, setState] = useState<number | null>(timeFrame),
    [ifShowCustomDateRange, setIfShowCustomDateRange] = useState<boolean>(true);

  useEffect(() => {
    if (state === 3) {
      setIfShowCustomDateRange(true);
    } else {
      setDateRange(null);
      setIfShowCustomDateRange(false);
    }
  }, [state]);

  return (
    <Row className="dualSelectorWrapper">
      <Col span={!ifShowCustomDateRange ? 24 : 12}>
        <CommonElementLabel
          label={label}
          isMandatory={isMandatory}
          deleteSetParams={deleteSetParams}
        />
        <Single
          options={options}
          placeholder={placeholder}
          isSearchable={isSearchable}
          onChange={(tf) => {
            setTimeFrame(tf);
            setState(tf);
          }}
          isDisabled={isDisabled}
          componentSpecificClasses={componentSpecificClassesDD}
          props={{ defaultValue: timeFrame }}
          targetingSetNumber={targetingSetNumber}
          featureSetNumber={featureSetNumber}
        />
      </Col>
      {ifShowCustomDateRange && (
        <Col span={12}>
          <CommonElementLabel
            label="Custom Timeframe"
            isMandatory={isMandatory && ifShowCustomDateRange}
          />
          <DateRangePicker
            dateRange={dateRange}
            ifDisabled={!ifShowCustomDateRange || isDisabled}
            setDateRange={setDateRange}
            props={{
              defaultValue: dateRange
                ? [dayjs(dateRange[0]), dayjs(dateRange[1])]
                : null,
              minDate: minDate ?? null,
              maxDate: maxDate ?? null,
            }}
            targetingSetNumber={17}
            featureSetNumber={featureSetNumber}
          />
        </Col>
      )}
    </Row>
  );
};

export default DateDropdown;
