import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Form, Input } from 'antd';
import {
  useAuth,
  usePostApiInterceptor,
  useShowToasterMessage,
} from '../../../hooks';
import { logoLogin } from '../../../assets/images';
import { IUserDetailsBody } from '../../../models/interfaces';
import { RoutesEnum } from '../../../models/enums/apiRoutes';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

interface SubmitButtonProps {
  label?: string;
  isLoading: boolean;
}

const SubmitButton: FC<PropsWithChildren<SubmitButtonProps>> = ({
  label = 'submit',
  isLoading,
}) => {
  return (
    <Button
      type="primary"
      htmlType="submit"
      disabled={isLoading}
      className={`primaryBtn loginBtn${
        !isLoading ? '' : ' loginButtonDisable'
      }`}
      loading={isLoading}
    >
      {label}
    </Button>
  );
};

const LoginPage: FC<{}> = () => {
  document.title = 'Login - BranchLab';

  const messageKey = 'LoginErrorMessage';

  const [body, setBody] = useState<string | null>(null),
    [isMounted, setIsMounted] = useState<boolean>(false),
    [messageObj, setMessageObj] = useState<any>({ isShowing: false }),
    [isLoadingLogin, setIsLoadingLogin] = useState<boolean>(false);

  const { login } = useAuth();

  const { contextHolder, destroyMessage } = useShowToasterMessage(messageObj);
  const { isError } = usePostApiInterceptor(
    isMounted,
    body,
    RoutesEnum.USER_INFORMATION,
    (status: number, data: any, error: any) => {
      setIsMounted(false);
      if (data && !error && status === 200) {
        const fetchUserInfo = data;
        if (fetchUserInfo?.length) {
          login(fetchUserInfo[0]);
        } else {
          updateErrorMessage();
        }
      } else if ((!error && status !== 200) || !data) {
        updateErrorMessage();
      }
      setIsLoadingLogin(false);
    }
  );

  const [form] = Form.useForm();
  const values = Form.useWatch([], form);

  function updateErrorMessage() {
    setMessageObj({
      key: messageKey,
      isShowing: true,
      type: 'error',
      messageContent: 'Incorrect credentials, please try again',
    });
  }

  const tryLogin = async () => {
    setIsLoadingLogin(true);
    setMessageObj({ isShowing: false });
    destroyMessage(messageKey);

    const tempBody: IUserDetailsBody = { ...values };

    setBody(JSON.stringify(tempBody));
    setIsMounted(true);
  };

  const renderEyeIcon = (visable: boolean) =>
    visable ? <EyeTwoTone /> : <EyeInvisibleOutlined />;

  useEffect(() => {
    if (isError) {
      updateErrorMessage();
    }
  }, [isError]);

  return (
    <div className="loginWrap">
      <div className="loginContentWrap">
        <div className="loginContent">
          {contextHolder}
          <h1><img src={logoLogin} alt="" />
          </h1>
          <Form
            form={form}
            labelCol={{ span: 24 }}
            name="bl_login"
            autoComplete="off"
            requiredMark={false}
            onFinish={tryLogin}
          >
            <Form.Item
              label="Email"
              name="email"
              validateDebounce={1000}
              validateFirst
              rules={[
                {
                  required: true,
                  message: 'Email is required',
                },
                {
                  type: 'email',
                  message: 'Enter a valid email',
                },
              ]}
            >
              <Input
                type="email"
                placeholder="Enter Email"
                className="loginPageField"
                disabled={isLoadingLogin}
                autoFocus
              />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              validateDebounce={1000}
              rules={[
                {
                  required: true,
                  message: 'Password is required',
                },
              ]}
            >
              <Input.Password
                placeholder="Enter Password"
                className="loginPageField"
                disabled={isLoadingLogin}
                iconRender={renderEyeIcon}
              />
            </Form.Item>

            <Form.Item>
              <SubmitButton label="Login" isLoading={isLoadingLogin} />
            </Form.Item>
          </Form>
          <p className="noAcc">
            Don’t have an account? <Link to="https://branchlab.com/contact/" target="_blank" rel="noopener noreferrer">Contact Us</Link>
          </p>
        </div>
        <ul className="externalLinks">
          <li>
            <Link to="https://branchlab.atlassian.net/wiki/spaces/platform/overview" target="_blank" rel="noopener noreferrer">Help</Link>
          </li>
          <li>
            <Link to="https://branchlab.com/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy</Link>
          </li>
          <li>
            <Link to="https://branchlab.com/terms-conditions/" target="_blank" rel="noopener noreferrer">Terms</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default LoginPage;
