/* eslint-disable react-hooks/exhaustive-deps */
import { message } from 'antd';
import { NoticeType } from 'antd/es/message/interface';
import { useEffect } from 'react';

const useShowToasterMessage = ({
  isShowing = false,
  key,
  type = 'success',
  messageContent = '',
  classNames = [],
  style = {},
}: {
  isShowing: boolean;
  key: number | string;
  type?: NoticeType;
  messageContent?: string;
  classNames?: string[];
  style?: any;
}) => {
  const [messageApi, contextHolder] = message.useMessage();
  const constructMessage = () => {
    messageApi.open({
      key,
      type,
      content: messageContent,
      className: `${classNames?.length ? ' ' + classNames.join(' ') : ''}`,
      style,
      duration: 2,
    });
  };

  const destroyMessage = (messageKey: number | string) => {
    messageApi.destroy(messageKey);
  };

  useEffect(() => {
    if (isShowing) {
      constructMessage();
    }
  }, [isShowing]);

  return { contextHolder, destroyMessage };
};

export default useShowToasterMessage;
