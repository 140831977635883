/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import {
  DateRangeType,
  IActiveAudienceListingParam,
  IEachDropdownElementsType,
  IMasterSspDDState,
} from '../../../../models/interfaces';
import { Col, Row } from 'antd';
import {
  CommonElementLabel,
  DateRangePicker,
  Single,
} from '../../../sharedComponent';
import { useMasterSSPElementsStore } from '../../../../stores/masterData.store';

function convertData(data: IEachDropdownElementsType[]) {
  const groupedData: { [key: string]: any } = {};

  // Group the data by partner
  data.forEach((item: any) => {
    const { partner, label, value } = item;

    if (!groupedData[partner]) {
      groupedData[partner] = {
        label: partner,
        title: partner,
        options: [],
      };
    }

    groupedData[partner].options.push({
      label: partner + ': ' + label,
      value: value,
    });
  });

  // Convert grouped data to an array
  return Object.values(groupedData);
}

const ActiveAudienceListingFilter: FC<{
  filterData: IActiveAudienceListingParam;
  setFilterData: React.Dispatch<
    React.SetStateAction<IActiveAudienceListingParam>
  >;
}> = ({ filterData, setFilterData }) => {
  const [dateRange, setDateRange] = useState<DateRangeType>(null),
    [partner, setPartner] = useState<(number | null)>(null),
    [isInitialLoading, setIsInitialLoading] = useState<boolean>(true);

  const
    { sspDDElements } = useMasterSSPElementsStore(
      (state: IMasterSspDDState) => state
    );

  const updateFilters = () => {
    setFilterData({
      ...filterData,
      dateRange,
      partner: partner ? partner : null,
      pageNumber: 1,
    });
  };

  useEffect(() => {
    if (isInitialLoading) {
      setIsInitialLoading(false);
      return;
    }
    updateFilters();
  }, [dateRange, partner]);

  return (
    <Row className="audienceListDateRangeFilterWrap">
      <Col span={12}>
        <CommonElementLabel label="Created On" />
        <DateRangePicker
          dateRange={dateRange}
          ifDisabled={false}
          setDateRange={setDateRange}
          props={{ autoFocus: false }}
        />
      </Col>
      <Col span={12}>
        <CommonElementLabel label="Activated To" />
        <Single
          options={convertData(sspDDElements)}
          placeholder="Select Activated To"
          onChange={setPartner}
        />
      </Col>
    </Row>
  );
};

export default ActiveAudienceListingFilter;
