import { FC, Fragment, useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Dropdown,
  Menu,
  MenuProps,
  Table,
  type TableColumnsType,
  type TableProps,
} from 'antd';
import { SortOrder } from 'antd/es/table/interface';
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';
import {
  IAudienceListingParam,
  IDebounceDropdownElements,
  IEachDropdownElementsType,
  ILoaderState,
  IMasterAdditionalOptionDDState,
  IMasterAudienceTypeDDState,
  IMasterGenderDDState,
  IMasterInsurancePlanProviderDDState,
  IMasterInsurancePlanTypeDDState,
  IMasterOperatorDDState,
  IMasterProviderSpecialtyDDState,
  IMasterRaceAndEthnicityDDState,
  IMasterStateDDState,
  IMasterStatusDDState,
  IMasterTimeFrameDDState,
  IUserInformationState,
} from '../../../../models/interfaces';
import {
  useNotificationMessage,
  usePostApiInterceptor,
  useRedirection,
  useShowToasterMessage,
} from '../../../../hooks';
import { RoutesEnum } from '../../../../models/enums/apiRoutes';
import {
  useLoaderStore,
  useUserInformationStore,
  useMasterStatusElementsStore,
  useMasterAudienceTypeElementsStore,
  useMasterGenderElementsStore,
  useMasterRaceAndEthnicityElementsStore,
  useMasterOperatorElementsStore,
  useMasterStateElementsStore,
  useMasterInsurancePlanTypeElementsStore,
  useMasterTimeFrameElementsStore,
  useMasterAdditionalOptionElementsStore,
  useMasterProviderSpecialtyElementsStore,
  useMasterInsurancePlanProviderElementsStore,
} from '../../../../stores';
import { ModalComponent } from '../../../sharedComponent';
import { moreIconVertical } from '../../../../assets/images';
import React from 'react';

type OnChange = NonNullable<TableProps<DataType>['onChange']>;
type OperationType = 'edit' | 'delete' | 'download' | 'clone';

interface DataType {
  key: number;
  title: string;
  status: string;
  audience_type: string;
  created_on: string;
}

const messageContents = {
  edit: 'Error occurred while fetching Audience Information',
  delete: 'Error occurred while deleting Audience Information',
  download: 'Error occurred while downloading Audience Information',
  clone: 'Error occurred while cloning Audience Information',
};

const items: MenuProps['items'] = [
  {
    key: '1',
    label: 'Activate',
  },
  {
    key: '2',
    label: 'Export',
  },
  {
    key: '3',
    label: 'Duplicate',
  },
  {
    key: '4',
    label: 'Delete',
  },
  {
    key: '5',
    label: 'View',
  },
];

const items1: MenuProps['items'] = [
  {
    key: '1',
    label: 'Activate',
  },
  {
    key: '2',
    label: 'Export',
  },
  {
    key: '3',
    label: 'Duplicate',
  },
  {
    key: '4',
    label: 'View',
  },
];

const ListingTable: FC<{
  audData: any;
  isLoading: boolean;
  paginationAndSortingData: IAudienceListingParam;
  setPaginationAndSortingData: React.Dispatch<
    React.SetStateAction<IAudienceListingParam>
  >;
}> = ({
  audData,
  isLoading,
  paginationAndSortingData,
  setPaginationAndSortingData,
}) => {
  const [typeOfOperation, setTypeOfOperation] = useState<OperationType>('edit'),
    [body, setBody] = useState<string>(''),
    [isMounted, setIsMounted] = useState<boolean>(false),
    [endPoint, setEndPoint] = useState<string>(''),
    [messageObj, setMessageObj] = useState<any>({ isShowing: false }),
    [notificationObj, setNotificationObj] = useState<any>({ isShowing: false }),
    [deletingInfo, setDeletingInfo] = useState<{
      key: number;
      title: string;
    } | null>(null),
    [modalToOpen, setModalToOpen] = useState<boolean>(false);

  const { setShowPageLoaderState } = useLoaderStore(
      (state: ILoaderState) => state
    ),
    { userInformation } = useUserInformationStore(
      (state: IUserInformationState) => state
    ),
    { audienceTypeDDElements } = useMasterAudienceTypeElementsStore(
      (state: IMasterAudienceTypeDDState) => state
    ),
    { statusDDElements } = useMasterStatusElementsStore(
      (state: IMasterStatusDDState) => state
    ),
    { genderDDElements } = useMasterGenderElementsStore(
      (state: IMasterGenderDDState) => state
    ),
    { raceAndEthnicityDDElements } = useMasterRaceAndEthnicityElementsStore(
      (state: IMasterRaceAndEthnicityDDState) => state
    ),
    { operatorDDElements } = useMasterOperatorElementsStore(
      (state: IMasterOperatorDDState) => state
    ),
    { stateDDElements } = useMasterStateElementsStore(
      (state: IMasterStateDDState) => state
    ),
    { insurancePlanTypeDDElements } = useMasterInsurancePlanTypeElementsStore(
      (state: IMasterInsurancePlanTypeDDState) => state
    ),
    { timeFrameDDElements } = useMasterTimeFrameElementsStore(
      (state: IMasterTimeFrameDDState) => state
    ),
    { additionalOptionDDElements } = useMasterAdditionalOptionElementsStore(
      (state: IMasterAdditionalOptionDDState) => state
    ),
    { providerSpecialtyDDElements } = useMasterProviderSpecialtyElementsStore(
      (state: IMasterProviderSpecialtyDDState) => state
    ),
    { insurancePlanProviderDDElements } =
      useMasterInsurancePlanProviderElementsStore(
        (state: IMasterInsurancePlanProviderDDState) => state
      );

  const {
    redirectAudienceSetup,
    redirectAudienceActivationSetup,
    redirectAudienceView,
  } = useRedirection();

  usePostApiInterceptor(
    isMounted,
    body,
    endPoint,
    (status: number, data: any, error: any) => {
      setIsMounted(false);
      setNotificationObj({ isShowing: false });
      if (typeOfOperation === 'download' && !error && data) {
        handleXLSXDownload(data.audience_info);
      } else if (
        (typeOfOperation === 'delete' || typeOfOperation === 'clone') &&
        !error
      ) {
        if (audData?.rows.length === 1) {
          setPaginationAndSortingData({
            ...paginationAndSortingData,
            pageNumber:
              paginationAndSortingData.pageNumber === 1
                ? 1
                : paginationAndSortingData.pageNumber - 1,
          });
        } else {
          setPaginationAndSortingData({ ...paginationAndSortingData });
        }
        setShowPageLoaderState(false);
      } else {
        updateErrorMessage(typeOfOperation);
        setShowPageLoaderState(false);
      }
    }
  );

  const { contextHolder, destroyMessage } = useShowToasterMessage(messageObj);
  const { context } = useNotificationMessage(notificationObj);

  const handleChange: OnChange = (pagination, filter, sorter: any) => {
    const { sortOrder, sortColumn, pageNumber, rowsPerPage } =
      paginationAndSortingData;

    setPaginationAndSortingData({
      ...paginationAndSortingData,
      sortOrder:
        sorter?.order === undefined ? sortOrder : getSortOrder(sorter.order),
      sortColumn: sorter?.columnKey ?? sortColumn,
      pageNumber: pagination?.current ?? pageNumber,
      rowsPerPage: pagination?.pageSize ?? rowsPerPage,
    });
  };

  const getSortOrder = (order: SortOrder) => {
    if (order === 'ascend') return 'ASC';
    if (order === 'descend') return 'DESC';
    return null;
  };

  const getStatusOptionClassName = (value: string) => {
    return statusDDElements.find((eachOption) => eachOption.value === value)
      ?.className;
  };

  const getStatusColumnValueJsx = (value: string) => {
    switch (value) {
      case 'Building':
        return (
          <span
            className={`statusChip ${getStatusOptionClassName(
              value?.toLowerCase()
            )}`}
            style={{ backgroundColor: '#FFF598', color: '#645A00' }}
          >
            {value}
          </span>
        );
      case 'Estimated':
        return (
          <span
            className={`statusChip ${getStatusOptionClassName(
              value?.toLowerCase()
            )}`}
            style={{ backgroundColor: '#F0F9FF', color: '#026AA2' }}
          >
            {value}
          </span>
        );
      case 'Activated':
        return (
          <span
            className={`statusChip ${getStatusOptionClassName(
              value?.toLowerCase()
            )}`}
            style={{ backgroundColor: '#ECFDF3', color: '#2D912B' }}
          >
            {value}
          </span>
        );
    }
  };

  const editOrDeleteFeatureSets = (
    audienceId: number,
    type: OperationType = 'edit'
  ) => {
    if (type === 'edit') {
      redirectAudienceSetup(audienceId);
      return;
    }

    setMessageObj({ isShowing: false });
    destroyMessage(typeOfOperation);
    setShowPageLoaderState(true);
    setTypeOfOperation(type);
    if (type === 'download') {
      setEndPoint(RoutesEnum.GET_FEATURE_SET_DATA);
      setBody(JSON.stringify({ audience_id: audienceId }));
    } else {
      setEndPoint(RoutesEnum.DELETE_FEATURE_SET_DATA);
      setBody(
        JSON.stringify({
          audience_id: audienceId,
          user_id: userInformation.user_id,
        })
      );
    }
    setIsMounted(true);
  };

  const handleXLSXDownload = (audienceInfo: any) => {
    const tempData: any = { ...audienceInfo },
      data = [];

    for (const key in tempData) {
      switch (key) {
        case 'title': {
          data.push([handler[key], tempData[key]]);
          break;
        }
        case 'status_id': {
          const formattedSelectedElemets = statusDDElements.find(
            (fe: IEachDropdownElementsType) => fe.value === tempData[key]
          )?.label;

          data.push([handler[key], formattedSelectedElemets]);
          break;
        }
        case 'audience_type_id': {
          const formattedSelectedElemets = audienceTypeDDElements.find(
            (fe: IEachDropdownElementsType) => fe.value === tempData[key]
          )?.label;

          data.push([handler[key], formattedSelectedElemets]);
          break;
        }
        case 'additional_option_id': {
          const additionalOptsArray = JSON.parse(tempData[key]),
            formattedSelectedElemets = additionalOptsArray
              .map(
                (el: number) =>
                  additionalOptionDDElements.find(
                    (fe: IEachDropdownElementsType) => fe.value === el
                  )?.label
              )
              .join(', ');

          data.push([handler[key], formattedSelectedElemets]);
          break;
        }
        case 'feature_sets': {
          if (tempData[key]?.length) {
            data.push([], []);
            const featureSetData = tempData[key].sort(
              (a: any, b: any) => a.feature_set_rank - b.feature_set_rank
            );

            data.push(
              ...formatFeatureSetData(
                featureSetData,
                genderDDElements,
                raceAndEthnicityDDElements,
                operatorDDElements,
                stateDDElements,
                insurancePlanTypeDDElements,
                timeFrameDDElements,
                additionalOptionDDElements,
                providerSpecialtyDDElements,
                insurancePlanProviderDDElements
              )
            );
          }
          break;
        }
        default:
        // none
      }
    }

    setShowPageLoaderState(false);

    const wb = XLSX.utils.book_new(),
      ws = XLSX.utils.aoa_to_sheet(data);

    data.forEach((el: any, i: number) => {
      if (el?.length && el[0] === 'Description:') {
        ws['!merges'] = ws['!merges'] || [];
        ws['!merges'].push({ s: { r: i, c: 1 }, e: { r: i, c: 3 } });
      }
    });

    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, 'audience.xlsx');
  };

  const createDuplicateAudience = (audience_id: number) => {
    setMessageObj({ isShowing: false });
    destroyMessage('clone');
    setTypeOfOperation('clone');
    setShowPageLoaderState(true);

    setEndPoint(RoutesEnum.DUPLICATE_AUDIENCE);
    setBody(JSON.stringify({ audience_id, user_id: userInformation.user_id }));
    setNotificationObj({
      isShowing: true,
      type: 'success',
      messageContent: 'Audience Duplicated',
    });
    setIsMounted(true);
  };

  const handleActions = (e: any, data: any) => {
    if (e.key === '1') {
      redirectAudienceActivationSetup(data.key);
    } else if (e.key === '2') {
      editOrDeleteFeatureSets(data.key, 'download');
    } else if (e.key === '3') {
      createDuplicateAudience(data.key);
    } else if (e.key === '4') {
      setDeletingInfo({ key: data.key, title: data.title });
      setModalToOpen(true);
    } else if (e.key === '5') {
      redirectAudienceView(data.key);
    }
  };

  const handleActions2 = (e: any, data: any) => {
    if (e.key === '1') {
      redirectAudienceActivationSetup(data.key);
    } else if (e.key === '2') {
      editOrDeleteFeatureSets(data.key, 'download');
    } else if (e.key === '3') {
      createDuplicateAudience(data.key);
    } else if (e.key === '4') {
      redirectAudienceView(data.key);
    }
  };

  const columns: TableColumnsType<DataType> = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (text, record) => (
        <>
          <NavLink
            to={
              record.status !== 'Activated'
                ? '/update-audience/' + record.key
                : '/view-audience/' + record.key
            }
            title={text}
          >
            {text}
          </NavLink>
        </>
      ),
      width: '250px',
      sorter: true,
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: 'Agency',
      dataIndex: 'agency',
      key: 'agency',
      width: '130px',
      sorter: true,
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: 'Advertiser',
      dataIndex: 'advertiser',
      key: 'advertiser',
      width: '130px',
      sorter: true,
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: 'Brand',
      dataIndex: 'brand',
      key: 'brand',
      width: '130px',
      sorter: true,
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => getStatusColumnValueJsx(text),
      width: '130px',
      sorter: true,
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: 'Type',
      dataIndex: 'audience_type',
      key: 'audience_type',
      width: '150px',
      sorter: true,
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: 'Date',
      dataIndex: 'created_on',
      key: 'created_on',
      render: (text) => dayjs(text).format('M/D/YYYY'),
      width: '150px',
      sorter: true,
      defaultSortOrder: 'descend',
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: '',
      dataIndex: '',
      key: 'x',
      width: '50px',
      sorter: false,
      ellipsis: true,
      render: (_, record) => {
        return (
          <Dropdown
            trigger={['click']}
            overlay={
              <Menu
                items={record.status === 'Activated' ? items1 : items}
                onClick={(e) => {
                  record.status === 'Activated'
                    ? handleActions2(e, record)
                    : handleActions(e, record);
                }}
              ></Menu>
            }
            placement="bottomRight"
            overlayClassName="actionMenu"
          >
            <img src={moreIconVertical} className="actionMenuIcon" alt="more" />
          </Dropdown>
        );
      },
    },
  ];

  function extractParenthesesContent(inputString: string) {
    const match = inputString.match(/\(([^)]+)\)/);
    return match ? match[1] : inputString;
  }

  const updateErrorMessage = (messageKey: OperationType) => {
    setMessageObj({
      key: messageKey,
      isShowing: true,
      type: 'error',
      messageContent: messageContents[messageKey],
    });
  };

  const handleModalAccept = () => {
    if (deletingInfo) {
      editOrDeleteFeatureSets(deletingInfo.key, 'delete');
      setDeletingInfo(null);
      setNotificationObj({
        isShowing: true,
        type: 'success',
        messageContent: 'Audience Deleted',
      });
    }
  };

  function manipulateDataArray(dataArray: any) {
    return dataArray?.map((item: any) => {
      return {
        ...item,
        audience_type: extractParenthesesContent(item.audience_type),
      };
    });
  }

  return (
    <Fragment>
      {contextHolder}
      {context}
      <ModalComponent
        modalHeader="Delete Audience"
        modalToOpen={modalToOpen}
        setModalToOpen={setModalToOpen}
        deleteSetParams={{
          name: `Audience #${deletingInfo?.key} (${deletingInfo?.title})`,
          handelOk: handleModalAccept,
        }}
      />
      <Table
        columns={columns}
        dataSource={manipulateDataArray(audData?.rows) ?? []}
        onChange={handleChange}
        className={`antTableElement audienceListTable${
          isLoading ? ' hideNoDataLabel' : ''
        }`}
        pagination={{
          current: paginationAndSortingData.pageNumber,
          total: audData?.total_row_count ?? 0,
          showSizeChanger: false,
          pageSize: paginationAndSortingData.rowsPerPage,
        }}
        loading={isLoading}
        sortDirections={['ascend', 'descend', 'ascend']}
        scroll={{ x: 'max-content' }}
      />
    </Fragment>
  );
};

export default ListingTable;

const handler: any = {
  title: 'Title:',
  status_id: 'Status:',
  audience_type_id: 'Audience Type:',
  feature_set_rank: 'FeatureSet',
  condition_id: 'OPERATOR',
  additional_option_id: 'Additional Options',
  feature_set_summary: 'Description:',
  1: 'Timeframe',
  2: 'Patient Gender',
  3: 'Patient Age',
  4: 'Race & Ethnicity',
  5: 'State',
  6: 'Provider Specialty',
  7: 'Custom NPI List',
  8: 'Hospital',
  9: 'Diagnosis',
  10: 'Drug',
  11: 'Procedure',
  12: 'Insurance',
  13: 'Additional Option',
  14: 'Additional Information',
  15: 'DMA',
  18: 'Generic Drug',
};

const formatFeatureSetData = (
  fsd: any,
  gd: IEachDropdownElementsType[],
  rad: IEachDropdownElementsType[],
  od: IEachDropdownElementsType[],
  sd: IEachDropdownElementsType[],
  ipd: IEachDropdownElementsType[],
  tfd: IEachDropdownElementsType[],
  aod: IEachDropdownElementsType[],
  psd: IEachDropdownElementsType[],
  ip: IEachDropdownElementsType[]
) => {
  const data: any = [],
    operator: any = [];

  fsd.forEach((el: any) => {
    for (const featureKey in el) {
      switch (featureKey) {
        case 'feature_set_rank': {
          data.push([
            handler[featureKey],
            '#' +
              (el['is_global'] === true ? 'GlobalFeatureSet' : el[featureKey]),
          ]);
          break;
        }
        case 'feature_set_summary': {
          data.push([handler[featureKey], el[featureKey]]);
          break;
        }
        case 'condition_id': {
          const formattedSelectedElemets = od.find(
            (fe: IEachDropdownElementsType) => fe.value === el[featureKey]
          )?.label;

          el[featureKey] &&
            operator.push([handler[featureKey], formattedSelectedElemets]);
          break;
        }
        case 'targeting': {
          const targetingDataSet = el[featureKey].sort(
            (a: any, b: any) => a.targeting_id - b.targeting_id
          );
          data.push(['Targets:']);

          data.push(
            ...formatTargetingDataSet(
              targetingDataSet,
              gd,
              rad,
              sd,
              ipd,
              tfd,
              aod,
              psd,
              ip
            )
          );
          break;
        }
        default:
        // None
      }
    }
    if (operator.length > 0) {
      data.push([], ...operator, []);
      operator.length = 0;
    }
  });

  return data;
};

const formatTargetingDataSet = (
  td: any,
  gd: IEachDropdownElementsType[],
  rad: IEachDropdownElementsType[],
  sd: IEachDropdownElementsType[],
  ipd: IEachDropdownElementsType[],
  tfd: IEachDropdownElementsType[],
  aod: IEachDropdownElementsType[],
  psd: IEachDropdownElementsType[],
  ip: IEachDropdownElementsType[]
) => {
  const sortedTd = td.sort(
      (a: any, b: any) => a.targeting_set_rank - b.targeting_set_rank
    ),
    rearrangedTd = getRearrangedArray(sortedTd);

  let data: any = [],
    selectedStates: number[] = [];

  rearrangedTd.forEach((el: any) => {
    switch (el.targeting_id) {
      case 1: {
        const formattedSelectedElemets = tfd.find(
          (fe: IEachDropdownElementsType) =>
            fe.value === Number(el.targeting_value)
        )?.label;

        data = [
          ...data,
          Number(el.targeting_value) === 3
            ? ['', handler[`${el.targeting_id}`]]
            : ['', handler[`${el.targeting_id}`], formattedSelectedElemets],
        ];
        break;
      }
      case 2: {
        const formattedSelectedElemets = gd.find(
          (fe: IEachDropdownElementsType) =>
            fe.value === Number(el.targeting_value)
        )?.label;

        data = [
          ...data,
          ['', handler[`${el.targeting_id}`], formattedSelectedElemets],
        ];
        break;
      }
      case 3: {
        data = [
          ...data,
          [
            '',
            handler[`${el.targeting_id}`],
            JSON.parse(el.targeting_value).join(' to '),
          ],
        ];
        break;
      }
      case 4: {
        const selectedElement = JSON.parse(el.targeting_value),
          formattedSelectedElemets = selectedElement
            .map(
              (el: number) =>
                rad.find((fe: IEachDropdownElementsType) => fe.value === el)
                  ?.label
            )
            .join(', ');

        data = [
          ...data,
          ['', handler[`${el.targeting_id}`], formattedSelectedElemets],
        ];
        break;
      }
      case 5: {
        selectedStates = [...JSON.parse(el.targeting_value)];

        const selectedElement = JSON.parse(el.targeting_value),
          formattedSelectedElemets = selectedElement
            .map(
              (el: number) =>
                sd.find((fe: IEachDropdownElementsType) => fe.value === el)
                  ?.label
            )
            .join(', ');

        data = [
          ...data,
          ['', 'Geography'],
          ['', '', handler[`${el.targeting_id}`], formattedSelectedElemets],
        ];
        break;
      }
      case 7: {
        data = [
          ...data,
          [
            '',
            handler[`${el.targeting_id}`],
            JSON.parse(el.targeting_value)
              .map((el: any) => el.displayName)
              .join(', '),
          ],
        ];
        break;
      }
      case 6: {
        const selectedElement = JSON.parse(el.targeting_value),
          formattedSelectedElemets = selectedElement
            .map(
              (el: number) =>
                psd.find((fe: IEachDropdownElementsType) => fe.value === el)
                  ?.label
            )
            .join(', ');

        data = [
          ...data,
          ['', handler[`${el.targeting_id}`], formattedSelectedElemets],
        ];
        break;
      }
      case 9:
      case 10:
      case 11: {
        const selectedElement = JSON.parse(el.targeting_value),
          formattedSelectedElemets = selectedElement
            .map(
              (el: IDebounceDropdownElements) =>
                [String(el.value)?.split('~')[0]] +
                ': ' +
                String(el.label)?.replaceAll('~', ' ')
            )
            .join(', ');

        data = [
          ...data,
          ['', handler[`${el.targeting_id}`], formattedSelectedElemets],
        ];
        break;
      }
      case 18: {
        data = [...data, ['', handler[`${el.targeting_id}`], 'All Selected']];
        break;
      }
      case 12: {
        const formattedSelectedElemets = ipd.find(
          (fe: IEachDropdownElementsType) =>
            fe.value === Number(el.targeting_value)
        )?.label;

        data = [
          ...data,
          ['', handler[`${el.targeting_id}`], formattedSelectedElemets],
        ];
        break;
      }
      case 13: {
        const selectedElement = JSON.parse(el.targeting_value),
          formattedSelectedElemets = selectedElement
            .map(
              (el: number) =>
                aod.find((fe: IEachDropdownElementsType) => fe.value === el)
                  ?.label
            )
            .join(', ');

        data = [
          ...data,
          ['', handler[`${el.targeting_id}`], formattedSelectedElemets],
        ];
        break;
      }
      case 14: {
        data = [
          ...data,
          ['', handler[`${el.targeting_id}`], el.targeting_value],
        ];
        break;
      }
      case 15: {
        let newDmsList: IEachDropdownElementsType[] = [];

        if (selectedStates?.length) {
          sd.forEach((el: any) => {
            if (selectedStates.includes(el.value)) {
              newDmsList = [...newDmsList, ...el.dma];
            }
          });
        } else {
          sd.forEach((el: any) => {
            newDmsList = [...newDmsList, ...el.dma];
          });
        }

        const selectedElement = JSON.parse(el.targeting_value),
          formattedSelectedElemets = selectedElement
            .map(
              (el: number) =>
                newDmsList.find(
                  (fe: IEachDropdownElementsType) => fe.value === el
                )?.label
            )
            .join(', ');

        if (!rearrangedTd.find((el: any) => el.targeting_id === 5)) {
          data = [
            ...data,
            ['', 'Geography'],
            ['', '', handler[`${el.targeting_id}`], formattedSelectedElemets],
          ];
        } else {
          data = [
            ...data,
            ['', '', handler[`${el.targeting_id}`], formattedSelectedElemets],
          ];
        }
        break;
      }
      case 16: {
        const selectedElement = JSON.parse(el.targeting_value),
          formattedSelectedElemets = selectedElement
            .map(
              (el: number) =>
                ip.find((i: IEachDropdownElementsType) => i.value === el)?.label
            )
            .join(', ');

        data[data.length - 1] = [
          ...data[data.length - 1],
          formattedSelectedElemets,
        ];
        break;
      }
      case 17: {
        const tempDateRange = JSON.parse(el.targeting_value),
          formattedDateRange = [
            dayjs(tempDateRange[0]).format('MM/DD/YYYY'),
            dayjs(tempDateRange[1]).format('MM/DD/YYYY'),
          ];

        data[data.length - 1] = [
          ...data[data.length - 1],
          formattedDateRange.join(' to '),
        ];
        break;
      }
      default:
      // none
    }
  });

  return data;
};

const getRearrangedArray = (td: any) => {
  const tempTd = [...td],
    index5 = tempTd.findIndex((el: any) => el.targeting_id === 5),
    index15 = tempTd.findIndex((el: any) => el.targeting_id === 15);

  if (index5 !== -1 && index15 !== -1 && index5 !== index15 - 1) {
    if (index5 < index15) {
      const [item15] = tempTd.splice(index15, 1);
      tempTd.splice(index5 + 1, 0, item15);
    } else {
      const [item5] = tempTd.splice(index5, 1);
      tempTd.splice(index15, 0, item5);
    }
  }

  return tempTd;
};
