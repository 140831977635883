import { FC } from 'react';
import { useRedirection } from '../../../hooks';

const LeftPanelActiveAudience: FC<{}> = () => {
  const { redirectAudienceActivations } = useRedirection();

  return (
    <ul>
      <li onClick={() => redirectAudienceActivations()}>Activation</li>
    </ul>
  );
};
export default LeftPanelActiveAudience;
