import { FC } from 'react';
import { useRedirection } from '../../../hooks';
import { errorIllustration } from '../../../assets/images';

const ErrorPage: FC<{}> = () => {
  const { redirectAudienceListing } = useRedirection();

  return (
    <div className="errorScreenWrap">
      <img src={errorIllustration} alt="" />
      <h1>Oops!</h1>
      <p>We can't seem to find the page you are looking for.</p>
      <button className="primaryBtn" onClick={redirectAudienceListing}>
        Go to Home
      </button>
    </div>
  );
};

export default ErrorPage;
