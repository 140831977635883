import { Tooltip } from 'antd';
import Single from './Single';
import Multi from './Multi';
import Tree from './Tree';
import DateDropdown from './DateDropdown';
import TreeMulti from './TreeMulti';
import MultiSingle from './MultiSingle';
import DebounceDropdown from './DebounceDropdown';
import DebounceDropdownSingleSelect from './DebounceDropdownSingleSelect';

export { Single, Multi, Tree, DateDropdown, TreeMulti, DebounceDropdown ,DebounceDropdownSingleSelect,MultiSingle};

export const renderTooltip = (omittedValues: any) => (
  <Tooltip title={omittedValues.map((el: any) => el.label).join(', ')}>
    <span className="multiSelectTooltip">
      + {omittedValues?.length ?? 0} ...
    </span>
  </Tooltip>
);
