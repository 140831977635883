import { FC, Fragment, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import ModalComponent from '../Modal/ModalComponent';
import { IDeleteParams } from '../../../models/interfaces';

const DeleteTargetingElement: FC<{
  deleteSetParams?: IDeleteParams;
}> = ({ deleteSetParams }) => {
  const [modalToOpen, setModalToOpen] = useState(false);
  return (
    <Fragment>
      <CloseOutlined
        className="deleteIconForElements"
        onClick={() => setModalToOpen(true)}
      />
      <ModalComponent
        modalHeader="Delete Element"
        modalToOpen={modalToOpen}
        setModalToOpen={setModalToOpen}
        deleteSetParams={deleteSetParams}
      />
    </Fragment>
  );
};

export default DeleteTargetingElement;
