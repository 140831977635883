import { Dispatch, FC, SetStateAction } from 'react';
import Modal from 'antd/es/modal/Modal';
import { useMasterSSPElementsStore } from '../../../../stores/masterData.store';
import {
  IEachDropdownElementsType,
  IMasterSspDDState,
} from '../../../../models/interfaces';
import { Row, Select, Tooltip } from 'antd';
import { CommonElementLabel, InputField } from '../../../sharedComponent';
import { FlattenOptionData } from 'rc-select/lib/interface';
import { BaseOptionType } from 'antd/es/select';
import { InfoCircleOutlined } from '@ant-design/icons';

function convertData(
  data: IEachDropdownElementsType[],
  selectedAudienceType: number
) {
  const groupedData: { [key: string]: any } = {};

  const filteredData: IEachDropdownElementsType[] = data.filter((item) => {
    const { partner } = item;
    if (selectedAudienceType === 1) {
      return partner === 'Transunion';
    } else if (selectedAudienceType === 2) {
      return partner === 'Semcasting' || partner === 'Throtle';
    }
    return false;
  });

  filteredData.forEach((item: any) => {
    const { partner, label, value } = item;

    if (!groupedData[partner]) {
      groupedData[partner] = {
        label: partner,
        title: partner,
        options: [],
      };
    }

    groupedData[partner].options.push({
      label: partner + ': ' + label,
      value: value,
    });
  });

  return Object.values(groupedData);
}

const AudienceDestinationModal: FC<{
  partner: string;
  modalToOpen: boolean;
  estimated_Size: string;
  activate: () => void;
  setPartner: Dispatch<SetStateAction<string>>;
  setModalToOpen: Dispatch<SetStateAction<boolean>>;
  setEstimated_Size: Dispatch<SetStateAction<string>>;
  selectedAudienceType: number | null;
}> = ({
  modalToOpen,
  setModalToOpen,
  setEstimated_Size,
  estimated_Size,
  partner,
  setPartner,
  activate,
  selectedAudienceType,
}) => {
  const cancelModal = () => {
    setPartner('');
    setModalToOpen(false);
  };

  const { sspDDElements } = useMasterSSPElementsStore(
    (state: IMasterSspDDState) => state
  );

  const isOkButtonDisabled = !partner;

  return (
    <Modal
      centered
      open={modalToOpen}
      onCancel={cancelModal}
      onOk={activate}
      okButtonProps={{
        disabled: isOkButtonDisabled,
      }}
      okText="Activate"
      className="commonModal"
      width={'550px'}
    >
      <div className="audienceDestinationModal modalGenericContent">
        <div className="modalGenericTextContent">
          <h3>Audience Destination</h3>
          <Row>
            <CommonElementLabel label="Partner:" isMandatory />
            <Select
              value={parseInt(partner) || undefined}
              onChange={(data: number) => {
                setPartner(data.toString());
              }}
              style={{ width: 'calc(100% - 135px)' }}
              placeholder="Select Partner"
              options={convertData(sspDDElements, selectedAudienceType ?? 1)}
              optionRender={(option) => optionRender(option)}
            />
          </Row>
          {selectedAudienceType !== 2 && (
            <>
              <h3>Partner Settings:</h3>
              <InputField
                label="Audience Size Override"
                defaultValue={estimated_Size}
                onChange={setEstimated_Size}
                fieldType="input"
                inputPlaceholder="Enter size"
                prefix={
                  <Tooltip title="The audience size must be within the range of 50,000 to 100,000,000.">
                    <InfoCircleOutlined />
                  </Tooltip>
                }
              />
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

const optionRender = (option: FlattenOptionData<BaseOptionType>) => {
  return <>{String(option?.label)?.split(': ')[1]}</>;
};

export default AudienceDestinationModal;
