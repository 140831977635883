import { FC, ReactNode } from 'react';
import { useAudienceSetUp } from '../../../stores/audienceSets.store';
import { IAudienceSetUpState } from '../../../models/interfaces';
import { useLocation } from 'react-router-dom';
import Breadcrumb from '../BreadCrumb/BreadCrumb';

type PageHeaderType = { title: ReactNode; componentSpecificClass?: string };

const BreadcrumbItem = [
  {
    key: 1,
    title: 'Setup',
  },
  {
    key: 2,
    title: 'Define',
  },
  {
    key: 3,
    title: 'Activate',
  },
];

const PageHeader: FC<PageHeaderType> = ({ title, componentSpecificClass }) => {
  const { pathname } = useLocation();
  const { audiencePageNumber } = useAudienceSetUp(
    (state: IAudienceSetUpState) => state
  );

  const isAudiencePath = (pathname: string) =>
    ['/create-audience', '/update-audience', '/audience-activation'].some(
      (substring) => pathname.includes(substring)
    );

  return (
    <div className="pageHeaderWrap">
      <h1
        className={`pageHeader${
          componentSpecificClass ? ' ' + componentSpecificClass : ''
        }`}
      >
        {title}
        {isAudiencePath(pathname) && (
          <Breadcrumb items={BreadcrumbItem} activeIndex={audiencePageNumber} />
        )}
      </h1>
    </div>
  );
};

export default PageHeader;
