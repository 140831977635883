import React, { Fragment, useEffect, useState } from 'react';
import {
  ModalComponent,
  PageHeader,
} from '../../../../components/sharedComponent';
import DefaultFeatureSet from './DefaultFeatureSet';
import {
  useCheckIfDataModifiedStore,
  useCheckSavingEnabilityStore,
  useFeatureSetDataCheckStore,
  useFeatureSetDataHandlingStore,
  useLoaderStore,
  useMasterAdditionalOptionElementsStore,
  useMasterAudienceTypeElementsStore,
} from '../../../../stores';
import {
  IAudienceSaveMethods,
  IAudienceSetUpState,
  IAudienceState,
  ICheckIfDataModifiedState,
  IEnableSaveState,
  IFeatureSetDataCheckState,
  IFeatureSetDataState,
  ILoaderState,
  IMasterAdditionalOptionDDState,
  IMasterAudienceTypeDDState,
} from '../../../../models/interfaces';
import { Button, Dropdown, MenuProps } from 'antd';
import TargetableFeatureSetup from './TargetableFeatureSetup';
import {
  useAudienceSaveMethods,
  useAudienceSetUp,
  useAudienceSets,
} from '../../../../stores/audienceSets.store';
import { CheckOutlined } from '@ant-design/icons';
import { useRedirection, useShowToasterMessage } from '../../../../hooks';
import { useParams } from 'react-router-dom';
import AudienceInsightsWrap from './AudienceInsights/AudienceInsightsWrap';
import { barChart, chevDown } from '../../../../assets/images';

type SavingOptionType = {
  key: '1' | '2' | '3' | '4';
  label:
  | 'Save and Exit'
  | 'Save and Activate'
  | 'Save and New'
  | 'Save and Duplicate';
  messagecontent: string;
};
export const savingOptions: SavingOptionType[] = [
  {
    key: '1',
    label: 'Save and Exit',
    messagecontent: 'Audience is saved',
  },
  {
    key: '2',
    label: 'Save and New',
    messagecontent: 'Audience is saved',
  },
];

const AudienceSetupFieldsWrap = () => {
  const { id } = useParams();
  const [selectedAudienceType, setSelectedAudienceType] = useState<
    number | null
  >(null),
    [modalToOpen, setModalToOpen] = useState<boolean>(false),
    [modalToOpenForCancel, setModalToOpenForCancel] = useState<boolean>(false),
    [selectedAdditionalOpts, setSelectedAdditionalOpts] = useState<
      (number | string)[]
    >([]),
    [focusOnTitle, setFocusOnTitle] = useState<0 | 1 | 2>(0),
    // [isClone, setIsClone] = useState<boolean>(false),
    [messageObj, setMessageObj] = useState<any>({ isShowing: false });

  const { audienceTypeDDElements } = useMasterAudienceTypeElementsStore(
    (state: IMasterAudienceTypeDDState) => state
  ),
    { additionalOptionDDElements } = useMasterAdditionalOptionElementsStore(
      (state: IMasterAdditionalOptionDDState) => state
    ),
    { ifDataModified, setIfDataModified } = useCheckIfDataModifiedStore(
      (state: ICheckIfDataModifiedState) => state
    ),
    { showBlankLoaderState, loaderState } = useLoaderStore(
      (state: ILoaderState) => state
    ),
    {
      advertiser,
      setAdvertiser,
      audiencePageNumber,
      setAudiencePageNumber,
      audienceTitle,
      audienceBrand,
      audienceAgency,
      setAudienceAgency,
      setAudiencebrand,
      setAudienceTitle,
    } = useAudienceSetUp((state: IAudienceSetUpState) => state),
    { setIsSaveInitiated } = useAudienceSaveMethods(
      (state: IAudienceSaveMethods) => state
    ),
    {
      isClone,
      setIsClone,
      isDataSaved,
      selectedSavingOpt,
      setSelectedSavingOpt,
      setGlobalFeatureData,
      setGlobalFeatureTargetting,
      setGlobalFeatureCount,
      isBacked,
      setIsBacked,
      setIsCollapsed,
      isCollapsed,
    } = useAudienceSets((state: IAudienceState) => state),
    { updatedDataSet } = useFeatureSetDataHandlingStore(
      (state: IFeatureSetDataState) => state
    ),
    { ifUpdated } = useFeatureSetDataCheckStore(
      (state: IFeatureSetDataCheckState) => state
    ),
    { setIisFeatureSetsFilled } = useCheckSavingEnabilityStore(
      (state: IEnableSaveState) => state
    ),
    { redirectAudienceListing } = useRedirection(),
    { contextHolder } = useShowToasterMessage(messageObj);

  useEffect(() => {
    return () => {
      setAudienceTitle('');
      setSelectedAudienceType(null);
      setAudiencePageNumber(1);
      setIisFeatureSetsFilled({});
      setAudienceAgency('');
      setAudiencebrand('');
      setAdvertiser('');
      setGlobalFeatureTargetting(null);
      setGlobalFeatureCount(0);
      setGlobalFeatureData(null);
    };
  }, []);

  const handleCancel = () => {
    if (ifDataModified) {
      setModalToOpenForCancel(true);
    } else {
      setAudienceTitle('');
      setAudienceAgency('');
      setAudiencebrand('');
      redirectAudienceListing();
      setSelectedAudienceType(null);
      setGlobalFeatureTargetting(null);
      setGlobalFeatureCount(0);
      setGlobalFeatureData(null);
    }
  };

  const onSavingMenuClick: MenuProps['onClick'] = (e) => {
    setSelectedSavingOpt(e.key);
    setIsSaveInitiated(true);
  };

  useEffect(() => {
    if (
      ifUpdated &&
      (selectedAudienceType ||
        audienceTitle ||
        selectedAdditionalOpts?.length ||
        audienceAgency ||
        audienceBrand ||
        advertiser)
    ) {
      setIfDataModified(true);
      return;
    }
  }, [selectedAudienceType, audienceTitle, selectedAdditionalOpts]);

  const getSaveLabel = () => {
    if (isDataSaved) {
      return (
        <>
          <CheckOutlined /> Saved
        </>
      );
    }
    if (showBlankLoaderState) {
      return <>Saving</>;
    }
    return <>Continue</>;
  };

  const handleBack = () => {
    setIsBacked(true);
  };

  useEffect(() => {
    if (ifUpdated) {
      const {
        title,
        audience_type_id,
        additional_option_id,
        advertiser,
        agency: audience_agency,
        brand: audience_brand,
      } = updatedDataSet;
      setAdvertiser(advertiser);
      setAudienceAgency(audience_agency);
      setAudiencebrand(audience_brand);
      setAudienceTitle(title);
      setSelectedAudienceType(audience_type_id);
      setSelectedAdditionalOpts(additional_option_id);
    }
  }, [ifUpdated]);

  const handleModalCancel = () => {
    setAudienceTitle('');
    setAudienceAgency('');
    setAudiencebrand('');
    setAdvertiser('');
    redirectAudienceListing();
    setSelectedAudienceType(null);
    setGlobalFeatureTargetting(null);
    setGlobalFeatureCount(0);
    setGlobalFeatureData(null);
    setAudiencePageNumber(1);
  };

  const handleContinue = () => {
    setAudiencePageNumber(audiencePageNumber + 1);
  };

  const handleBackConfirm = () => {
    setIsBacked(false);
    setAudiencePageNumber(audiencePageNumber - 1);
    setGlobalFeatureTargetting(null);
    setGlobalFeatureCount(0);
    setGlobalFeatureData(null);
  };

  const cancelBack = () => {
    setAudiencePageNumber(audiencePageNumber - 1);
    setIsBacked(false);
  };

  return (
    <>
      <div className="audienceCommonWrap">
        {contextHolder}
        <PageHeader
          title={
            id && !isClone ? (
              <>
                Update: <span className='lessOpacityText'>{audienceTitle}</span>
              </>
            ) : (
              'Create Audience'
            )
          }
        />
        {!loaderState && (
          <>
            {audiencePageNumber === 1 && (
              <div>
                <DefaultFeatureSet
                  defaultTitle={audienceTitle}
                  setTitle={setAudienceTitle}
                  audienceType={selectedAudienceType}
                  setAudienceType={(value: any) =>
                    setSelectedAudienceType(value)
                  }
                  advertiser={advertiser}
                  setAdvertiser={setAdvertiser}
                  defaultAgency={audienceAgency}
                  setAgency={setAudienceAgency}
                  defaultBrand={audienceBrand}
                  setBrand={setAudiencebrand}
                  selectedAdditionalOpts={selectedAdditionalOpts}
                  setSelectedAdditionalOpts={setSelectedAdditionalOpts}
                  audienceTypeDDElements={audienceTypeDDElements}
                  additionalOptionDDElements={additionalOptionDDElements}
                  focusOnTitle={focusOnTitle}
                />
              </div>
            )}
            {audiencePageNumber === 2 && (
              <div className="activationRightPanel">
                <div className="targetableFeatureAndAudienceInsight">
                  <TargetableFeatureSetup
                    setIsClone={setIsClone}
                    setMessageObj={setMessageObj}
                    setModalToOpen={setModalToOpen}
                    setFocusOnTitle={setFocusOnTitle}
                    selectedAudienceType={selectedAudienceType}
                    selectedAdditionalOpts={selectedAdditionalOpts}
                    setSelectedAudienceType={setSelectedAudienceType}
                    setModalToOpenForCancel={setModalToOpenForCancel}
                    setSelectedAdditionalOpts={setSelectedAdditionalOpts}
                  />
                </div>
                <AudienceInsightsWrap />
              </div>
            )}
          </>
        )}
      </div>
      <div className="newFeatureSetBtn">
        {audiencePageNumber !== 1 && (
          <button
            className="responsiveCTAForRightPanel"
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            <img src={barChart} alt="" />
          </button>
        )}

        <div className="editAudienceNewFeatureSetBtn">
          <ModalComponent
            modalHeader={
              'Cancel ' + (id && !isClone ? 'Update' : 'Create') + ' Audience'
            }
            modalToOpen={modalToOpenForCancel}
            setModalToOpen={setModalToOpenForCancel}
            deleteSetParams={{
              name:
                'Cancel ' +
                (id && !isClone ? 'Update' : 'Create') +
                ' Audience',
              handelOk: handleModalCancel,
              message:
                "You're about to cancel this form. If you proceed, any information you've entered will not be saved. Do you wish to continue?",
              okText: 'Yes',
              cancelText: 'No',
            }}
          />
          <ModalComponent
            modalHeader={
              'Discard ' + (id && !isClone ? 'Update' : 'Create') + ' Audience'
            }
            modalToOpen={isBacked}
            setModalToOpen={setIsBacked}
            deleteSetParams={{
              name:
                'Cancel ' +
                (id && !isClone ? 'Update' : 'Create') +
                ' Audience',
              handelOk: handleBackConfirm,
              message:
                "You're about to discard this changes. If you proceed, any information you've entered will not be saved. Do you wish to continue?",
              okText: 'Yes',
              cancelText: 'No',
              handleCancel: cancelBack,
            }}
          />
          <Button
            type="text"
            size="large"
            onClick={handleCancel}
            className="noBGBtn"
          >
            Cancel
          </Button>
          {audiencePageNumber === 2 && (
            <Button size="large" onClick={handleBack} className="secondaryBtn backBtn">
              Back
            </Button>
          )}
          {audiencePageNumber === 1 && (
            <Button
              type="primary"
              size="large"
              className="dropdownSaveButton primaryBtn"
              onClick={handleContinue}
              disabled={
                !(
                  selectedAudienceType &&
                  audienceTitle &&
                  audienceAgency &&
                  audienceBrand &&
                  advertiser
                )
              }
            >
              Continue
            </Button>
          )}
          {audiencePageNumber === 2 && (
            <Fragment>
              <Dropdown.Button
                trigger={['click']}
                type="primary"
                size="large"
                className="dropdownSaveButton"
                icon={<img src={chevDown} alt="arrow" />}
                loading={!isDataSaved && showBlankLoaderState}
                onClick={() => {
                  setIsSaveInitiated(true);
                  setSelectedSavingOpt('');
                }}
                disabled={
                  !(
                    selectedAudienceType &&
                    audienceTitle &&
                    audienceAgency &&
                    audienceBrand &&
                    advertiser
                  )
                }
                menu={{
                  selectedKeys: [selectedSavingOpt],
                  items: savingOptions,
                  onClick: onSavingMenuClick,
                }}
              >
                {getSaveLabel()}
              </Dropdown.Button>
            </Fragment>
          )}
        </div>
      </div>
    </>
  );
};

export default AudienceSetupFieldsWrap;
