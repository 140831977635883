import { notification } from 'antd';
import { useEffect } from 'react';

const useNotificationMessage = ({
  classNames = [],
  messageContent = '',
  type = 'success',
  isShowing = false,
  style = {},
}: {
  classNames?: string[];
  type?: 'success' | 'error' | 'info';
  messageContent: string;
  isShowing: boolean;
  style?: any;
}) => {
  const [api, context] = notification.useNotification();

  const constructMessage = () => {
    api[type]({
      message: messageContent,
      placement: 'bottomLeft',
      className: `${classNames?.length ? ' ' + classNames.join(' ') : ''}`,
      duration: 2,
      style:{
        backgroundColor: 'rgba(20, 20, 20, 0.9)',
        borderRadius: '8px',
      }
    });
  };

  useEffect(() => {
    if (isShowing) {
      constructMessage();
    }
  }, [isShowing]);

  return { context };
};

export default useNotificationMessage;
