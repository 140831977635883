/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import apiService from '../services/Api.service';

const usePostApiInterceptor = (
  isMounted: boolean,
  body: any,
  aPIEndpoint: string,
  callback?: Function
) => {
  const [data, setData] = useState<any>(null),
    [isLoading, setIsLoading] = useState<boolean>(true),
    [isError, setIsError] = useState<boolean>(false),
    [status, setStatus] = useState<number>(200),
    [dataCount, setDataCount] = useState<number>(0);

  const postData = (signal: AbortSignal) => {
    setIsLoading(true);
    setIsError(false);
    try {
      apiService
        .post<any>(process.env.REACT_APP_BASE_URL + aPIEndpoint, body, {
          signal,
        })
        .then(
          (res) => {
            const { body, statusCode } = res;
            if (statusCode === 200) {
              setStatus(statusCode);
              setData(JSON.parse(body as string));
              setDataCount(res?.data_count ?? 0);
            } else {
              console.error('Request is not 200');
              setIsError(true);
              setStatus(statusCode);
            }

            if (callback)
              callback(
                statusCode,
                body ? JSON.parse(body as string) : null,
                null
              );
            setIsLoading(false);
          },
          (err) => {
            if (signal.aborted) {
              console.info('Request was cancelled.');
            } else {
              console.error(err);
              setIsError(true);
              setStatus(499);
            }
            if (callback)
              callback(err?.response?.status, err?.response?.data, err);
            setIsLoading(false);
          }
        );
      console.info('Request Initiated !! ', aPIEndpoint);
    } catch (error: any) {
      console.error(error);
      setIsError(true);
      setStatus(500);
      if (callback) callback(500, { data: error?.message }, error?.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isMounted) {
      const controller = new AbortController();
      const signal = controller.signal;

      postData(signal);

      return () => {
        controller.abort();
      };
    }
  }, [body, aPIEndpoint, isMounted]);

  return { data, isLoading, isError, status, dataCount };
};

export default usePostApiInterceptor;
