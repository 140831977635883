import { Col, Input, InputRef, Row } from 'antd';
import React, { Dispatch, FC, SetStateAction, useRef } from 'react';
import { CommonElementLabel, InputField } from '../../../sharedComponent';
import { WarningOutlined } from '@ant-design/icons';

const FeatureSetup: FC<{
  defaultTitle: string;
  setTitle: Dispatch<SetStateAction<string>>;
  defaultDomain: string;
  setDomain: Dispatch<SetStateAction<string>>;
  valid: boolean;
}> = ({ defaultTitle, setTitle, defaultDomain, setDomain, valid }) => {
  const titleRef = useRef<InputRef>(null);
  
  const handleChange = (e:any) => {
    setDomain(e.target.value)
  }

  return (
    <Row>
      <Col span={24} className="mb-20">
        <InputField
          label="Title"
          isMandatory
          inputPlaceholder="Enter Title"
          onChange={setTitle}
          defaultValue={defaultTitle}
          props={{ ref: titleRef }}
        />
      </Col>
      <Col span={24}>
      <CommonElementLabel label="Pixel Domain" isMandatory/>
        <Input
          placeholder="Enter Pixel Domain"
          onChange={handleChange}
          value={defaultDomain}
          status={valid ? 'error' : ''}
          required={true}
          suffix={
            valid && <WarningOutlined className="validationErrorIcon" />
          }
        />
      </Col>
    </Row>
  );
};

export default FeatureSetup;
