import { useParams } from 'react-router-dom';
import { Button, Input } from 'antd';
import {
  CommonElementLabel,
  ModalComponent,
  PageHeader,
} from '../../../sharedComponent';
import FeatureSetup from './FeatureSetup';
import { Fragment, useEffect, useState } from 'react';
import {
  usePostApiInterceptor,
  useRedirection,
  useShowToasterMessage,
} from '../../../../hooks';
import { RoutesEnum } from '../../../../models/enums/apiRoutes';
import {
  useCheckIfDataModifiedStore,
  useLoaderStore,
  useUserInformationStore,
} from '../../../../stores';
import {
  ICheckIfDataModifiedState,
  IPixelDataCheckState,
  IPixelSetDataStore,
  IUserInformationState,
} from '../../../../models/interfaces';
import {
  usePixelDataCheckStore,
  usePixelDataHandlingStore,
} from '../../../../stores/saveAndUpdateData.store';
import { CheckCircleTwoTone, CopyOutlined } from '@ant-design/icons';

const PixelSetupFieldWrap = () => {
  const { id } = useParams();
  const { redirectPixelListing } = useRedirection();
  const { userInformation } = useUserInformationStore(
      (state: IUserInformationState) => state
    ),
    { ifDataModified, setIfDataModified } = useCheckIfDataModifiedStore(
      (state: ICheckIfDataModifiedState) => state
    ),
    { updatedDataSet } = usePixelDataHandlingStore(
      (state: IPixelSetDataStore) => state
    ),
    { ifUpdated, setIfUpdated } = usePixelDataCheckStore(
      (state: IPixelDataCheckState) => state
    );
  const { setLoaderState, updateLoaderState } = useLoaderStore();
  const [title, setTitle] = useState<string>(''),
    [body, setBody] = useState<string>(''),
    [domain, setDomain] = useState<string>(''),
    [isMounted, setIsMounted] = useState<boolean>(false),
    [modalToOpenForCancel, setModalToOpenForCancel] = useState<boolean>(false),
    [isDataSaving, setIsDataSaving] = useState<boolean>(false),
    [pixel_Id, setPixel_Id] = useState<null | number>(null),
    [pixel_Code, setPixel_Code] = useState<string | null>(null),
    [isCopied, setIsCopied] = useState<boolean>(false),
    [isCopiedImage, setIsCopiedImage] = useState<boolean>(false),
    [messageObj, setMessageObj] = useState<any>({ isShowing: false }),
    [isInvalidUrl, setIsInvalidUrl] = useState<boolean>(false);

  const { contextHolder, destroyMessage } = useShowToasterMessage(messageObj);

  const handleCancel = () => {
    ifDataModified ? setModalToOpenForCancel(true) : redirectPixelListing();
  };

  useEffect(() => {
    body && setIsMounted(true);
  }, [body]);

  usePostApiInterceptor(
    isMounted,
    body,
    RoutesEnum.SAVE_PIXEL_DATA,
    (status: number, data: any, error: any) => {
      setLoaderState(false);
      setBody('');
      setIsMounted(false);
      if (data && !error) {
        setIsDataSaving(false);
        updateErrorMessage('success', 'Pixel is saved');
        resetAllLocalState();
        redirectPixelListing();
      } else {
        updateErrorMessage('error', 'Error occurred while saving Pixel.');
      }
    }
  );

  const handleSave = () => {
    let validated = isValidUrl(domain);
    if(validated){
      setIsDataSaving(true);
      const pixelData = {
        user_id: userInformation.user_id,
        title,
        pixel_domain: domain,
        pixel_id: pixel_Id,
      };
      setLoaderState(true);
      setIfUpdated(false);
      destroyMessage('save');
      setBody(JSON.stringify(pixelData));
    }else{
      setIsInvalidUrl(true)
    }
  };

  useEffect(()=>{
    setIsInvalidUrl(false)
  },[domain])

  useEffect(() => {
    if (title?.length) {
      setIfDataModified(true);
      return;
    }
  }, [title]);

  const resetAllLocalState = () => {
    setTitle('');
    setDomain('');
    setModalToOpenForCancel(false);
    setIfDataModified(false);
  };

  useEffect(() => {
    if (id && ifUpdated && !updateLoaderState) {
      const { title, pixel_domain, pixel_id, pixel_code } = updatedDataSet;
      setPixel_Id(pixel_id);
      setTitle(title);
      setDomain(pixel_domain);
      setPixel_Code(pixel_code);
    }
  }, [ifUpdated, updateLoaderState, updatedDataSet, id]);

  const handleDelete = () => {
    resetAllLocalState();
    redirectPixelListing();
  };

  const updateErrorMessage = (type: string, messageContent: string) => {
    setMessageObj({
      key: 'save',
      isShowing: true,
      type,
      messageContent,
      duration: 5,
    });
  };

  useEffect(() => {
    if (isCopied || isCopiedImage) {
      setTimeout(() => {
        setIsCopied(false);
        setIsCopiedImage(false);
      }, 1000);
    }
  }, [isCopied, isCopiedImage]);

  const handleCopy = () => {
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_PIXEL_URL}${pixel_Id}`
    );
    setIsCopied(!isCopied);
  };

  const copyImage = () => {
    navigator.clipboard.writeText(
      `<img src=${process.env.REACT_APP_PIXEL_URL}${pixel_Code} height='1px' width='1px' />`
    );
    setIsCopiedImage(!isCopiedImage);
  };

  const urlRegex = new RegExp(
    /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i
  );
  
  function isValidUrl(url: string): boolean {
    return urlRegex.test(url);
  }

  return (
    <Fragment>
      <div className="audienceCommonWrap">
        {contextHolder}
        <PageHeader title={(id ? 'Update' : 'Create') + ' Pixel'} />
        {!updateLoaderState && (
          <FeatureSetup
            defaultTitle={title}
            setTitle={setTitle}
            defaultDomain={domain}
            setDomain={setDomain}
            valid={isInvalidUrl}
            
          />
        )}
        {id && pixel_Code && !updateLoaderState && (
          <div className='pixelUrlSec'>
            <div className="informationText">
              <CommonElementLabel label="Copy the options below and send it to the owner of the website it is to be placed on:" />
            </div>

            <div>
              <div className="mb-20">
                <CommonElementLabel label="Raw URL" />
                <Input
                  placeholder="Raw URL"
                  readOnly
                  value={`${process.env.REACT_APP_PIXEL_URL}${pixel_Code}`}
                  suffix={
                    <div onClick={handleCopy} className='copyAction'>
                      {isCopied ? (
                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                      ) : (
                        <CopyOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                      )}
                    </div>
                  }
                />
              </div>
              <div className="mb-20">
                <CommonElementLabel label="Image" />
                <Input
                  placeholder="Image"
                  readOnly
                  value={`<img src=${process.env.REACT_APP_PIXEL_URL}${pixel_Code} height='1px' width='1px' />`}
                  suffix={
                    <div onClick={copyImage} className='copyAction'>
                      {isCopiedImage ? (
                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                      ) : (
                        <CopyOutlined style={{ color: 'rgba(0,0,0,.45)' }} 
                        />
                      )}
                    </div>
                  }
                />
              </div>
            </div>
          </div>
        )}
      </div>
      {!updateLoaderState && (
        <div className="newFeatureSetBtn">
          <ModalComponent
            modalHeader={'Cancel ' + (id ? 'Update' : 'Create') + ' Pixel'}
            modalToOpen={modalToOpenForCancel}
            setModalToOpen={setModalToOpenForCancel}
            deleteSetParams={{
              name: 'Cancel ' + (id ? 'Update' : 'Create') + ' Pixel',
              handelOk: handleDelete,
              message:
                "You're about to cancel this form. If you proceed, any information you've entered will not be saved. Do you wish to continue?",
              okText: 'Yes',
              cancelText: 'No',
            }}
          />
          <Button className='noBGBtn' size="large" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            loading={isDataSaving}
            disabled={(!title || !domain)}
            size="large"
            className="primaryBtn"
            onClick={handleSave}
          >
            Save
          </Button>
        </div>
      )}
    </Fragment>
  );
};

export default PixelSetupFieldWrap;
