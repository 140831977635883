import { DeleteOutlined, EditOutlined, MoreOutlined } from '@ant-design/icons';
import {
  Dropdown,
  Menu,
  MenuProps,
  Space,
  Table,
  TableProps,
  Tooltip,
  type TableColumnsType,
} from 'antd';
import dayjs from 'dayjs';
import { FC, Fragment, useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
  usePostApiInterceptor,
  useRedirection,
  useShowToasterMessage,
} from '../../../../hooks';
import {
  ILoaderState,
  IPixelListingParam,
  IUserInformationState,
} from '../../../../models/interfaces';
import { SortOrder } from 'antd/es/table/interface';
import { ModalComponent } from '../../../sharedComponent';
import { useLoaderStore, useUserInformationStore } from '../../../../stores';
import { RoutesEnum } from '../../../../models/enums/apiRoutes';
import { moreIconVertical } from '../../../../assets/images';

const items: MenuProps['items'] = [
  {
    key: '1',
    label: 'Delete',
  },
];

type OnChange = NonNullable<TableProps<DataType>['onChange']>;

interface DataType {
  key: number;
  title: string;
  pixel_domain: string;
  created_on: string;
  pixel_id: number;
}

const PixelListTable: FC<{
  pixelData: any;
  isLoading: boolean;
  paginationAndSortingData: IPixelListingParam;
  setPaginationAndSortingData: React.Dispatch<
    React.SetStateAction<IPixelListingParam>
  >;
}> = ({
  pixelData,
  isLoading,
  paginationAndSortingData,
  setPaginationAndSortingData,
}) => {
  const { userInformation } = useUserInformationStore(
      (state: IUserInformationState) => state
    ),
    { setShowPageLoaderState } = useLoaderStore((state: ILoaderState) => state),
    { redirectPixelSetup } = useRedirection();

  const [deletingInfo, setDeletingInfo] = useState<{
      key: number;
      title: string;
      domain: string;
    } | null>(null),
    [body, setBody] = useState<string>(''),
    [modalToOpen, setModalToOpen] = useState<boolean>(false),
    [isMounted, setIsMounted] = useState<boolean>(false),
    [messageObj, setMessageObj] = useState<any>({ isShowing: false });

  const { contextHolder, destroyMessage } = useShowToasterMessage(messageObj);

  const { user_id } = userInformation;

  const handleActions = (e: any, data: any) => {
    if (e.key === '1') {
      handleDelete(data);
    }
  };

  const columns: TableColumnsType<DataType> = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (text, record) => (
        <NavLink to={'/update-pixel/' + record.pixel_id} title={text}>
          {text}
        </NavLink>
      ),
      width: 'auto',
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: 'Pixel ID',
      dataIndex: 'pixel_code',
      key: 'pixel_code',
      width: '350px',
      sorter: true,
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: 'Domain',
      dataIndex: 'pixel_domain',
      key: 'pixel_domain',
      width: '250px',
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: 'Created On',
      dataIndex: 'created_on',
      key: 'created_on',
      render: (text) => dayjs(text).format('M/D/YYYY'),
      width: '180px',
      sorter: true,
      defaultSortOrder: 'descend',
      showSorterTooltip: false,
    },
    {
      title: '',
      dataIndex: '',
      key: 'x',
      width: '50px',
      sorter: false,
      render: (_, record) => {
        return (
          <Dropdown
            trigger={['click']}
            overlay={
              <Menu
                items={items}
                onClick={(e) => {
                  handleActions(e, record);
                }}
              ></Menu>
            }
            placement="bottomRight"
          >
            <img src={moreIconVertical} className='actionMenuIcon' alt="more"/>
          </Dropdown>
        );
      },
    },
  ];

  const handleChange: OnChange = (pagination, filter, sorter: any) => {
    const { sortOrder, sortColumn, pageNumber, rowsPerPage } =
      paginationAndSortingData;

    setPaginationAndSortingData({
      ...paginationAndSortingData,
      sortOrder:
        sorter?.order === undefined ? sortOrder : getSortOrder(sorter.order),
      sortColumn: sorter?.columnKey ?? sortColumn,
      pageNumber: pagination?.current ?? pageNumber,
      rowsPerPage: pagination?.pageSize ?? rowsPerPage,
    });
  };

  const getSortOrder = (order: SortOrder) => {
    if (order === 'ascend') return 'ASC';
    if (order === 'descend') return 'DESC';
    return null;
  };

  const handleModalAccept = () => {
    if (deletingInfo) {
      setShowPageLoaderState(true);
      setIsMounted(true);
      setBody(
        JSON.stringify({
          pixel_id: deletingInfo.key,
          user_id,
        })
      );
      setMessageObj({ isShowing: false });
      destroyMessage('delete');
    }
  };

  usePostApiInterceptor(
    isMounted,
    body,
    RoutesEnum.DELETE_PIXEL_DATA,
    (status: number, data: any, error: any) => {
      setIsMounted(false);
      setShowPageLoaderState(false);
      setBody('');
      if (status === 200) {
        if (pixelData?.rows.length === 1) {
          setPaginationAndSortingData({
            ...paginationAndSortingData,
            pageNumber:
              paginationAndSortingData.pageNumber === 1
                ? 1
                : paginationAndSortingData.pageNumber - 1,
          });
        } else {
          setPaginationAndSortingData({ ...paginationAndSortingData });
        }
      } else {
        updateErrorMessage(data);
        setShowPageLoaderState(false);
      }
    }
  );

  const updateErrorMessage = (message: string) => {
    setMessageObj({
      key: 'delete',
      isShowing: true,
      type: 'error',
      messageContent: message,
    });
  };

  const handleDelete = (record: DataType) => {
    setDeletingInfo({
      key: record.pixel_id,
      title: record.title,
      domain: record.pixel_domain,
    });
    setModalToOpen(true);
  };

  return (
    <Fragment>
      {contextHolder}
      <ModalComponent
        modalHeader="Delete Pixel"
        modalToOpen={modalToOpen}
        setModalToOpen={setModalToOpen}
        deleteSetParams={{
          name: `Pixel #${deletingInfo?.key} (${deletingInfo?.title})`,
          handelOk: handleModalAccept,
        }}
      />
      <Table
        columns={columns}
        dataSource={pixelData?.rows ?? []}
        loading={isLoading}
        onChange={handleChange}
        pagination={{
          current: paginationAndSortingData.pageNumber,
          total: pixelData?.total_row_count ?? 0,
          showSizeChanger: false,
          pageSize: paginationAndSortingData.rowsPerPage,
        }}
        className={`antTableElement pixelListingTable audienceListTable ${
          isLoading ? ' hideNoDataLabel' : ''
        }`}
        sortDirections={['ascend', 'descend', 'ascend']}
      />
    </Fragment>
  );
};

export default PixelListTable;
