import {
  CheckFeatureSetsFilledType,
  ICheckIfDataModifiedState,
  ISaveAudienceActivationState,
  ICheckIfAllDataPopulatedType,
  IPixelSetDataStore,
  PixelSetStoreDataType,
  IPixelDataCheckState,
} from './../models/interfaces/store.interface';
import { create } from 'zustand';
import {
  CheckIfAllMessageCompletedType,
  FeatureSetStoreDataType,
  ResearchStoreDataType,
  IActivationTabData,
  IActivationUpdatedData,
  ICheckIfAllMessageAreCreatedState,
  IDependentTargetingSelectedState,
  IEnableSaveState,
  IFeatureSetData,
  IResearchData,
  IFeatureSetDataCheckState,
  IResearchDataCheckState,
  IFeatureSetDataState,
  IResearchDataState,
  IsEnableSaveForEachSetType,

} from '../models/interfaces';

export const useFeatureSetDataCheckStore = create<IFeatureSetDataCheckState>(
  (set) => ({
    isInitiatedSaving: false,
    setIsInitiatedSaving: (value: boolean) => set({ isInitiatedSaving: value }),

    ifUpdated: false,
    setIfUpdated: (value: boolean) => set({ ifUpdated: value }),

    ifUpdatedDataPopulated: false,
    setIfUpdatedDataPopulated: (value: boolean) =>
      set({ ifUpdatedDataPopulated: value }),
  })
);
export const useResearchDataCheckStore = create<IResearchDataCheckState>(
  (set) => ({
    isInitiatedSaving: false,
    setIsInitiatedSaving: (value: boolean) => set({ isInitiatedSaving: value }),

    ifUpdated: false,
    setIfUpdated: (value: boolean) => set({ ifUpdated: value }),

    ifUpdatedDataPopulated: false,
    setIfUpdatedDataPopulated: (value: boolean) =>
      set({ ifUpdatedDataPopulated: value }),
  })
);

export const usePixelDataCheckStore = create<IPixelDataCheckState>((set) => ({
  ifUpdated: false,
    setIfUpdated: (value: boolean) => set({ ifUpdated: value }),
}))

export const useCheckSavingEnabilityStore = create<IEnableSaveState>((set) => ({
  isEnableSaveForEachSet: {},
  setIsEnableSaveForEachSet: (value: CheckIfAllMessageCompletedType) =>
    set({ isEnableSaveForEachSet: value }),
  updateIsEnableSaveForEachSet: (setId: number, isSaveEnable: 0 | 1 | 2) =>
    set((state) => ({
      isEnableSaveForEachSet: {
        ...state.isEnableSaveForEachSet,
        [setId]: isSaveEnable,
      },
    })),

  isFeatureSetsFilled: {},
  setIisFeatureSetsFilled: (value: CheckFeatureSetsFilledType) =>
    set({ isFeatureSetsFilled: value }),
  updateIisFeatureSetsFilled: (
    featureSetId: number,
    targetingSetId: number,
    isFilled: boolean
  ) =>
    set((state) => {
      const targetedFeatureSet = {
        ...state.isFeatureSetsFilled[featureSetId],
        [targetingSetId]: isFilled,
      };
      return {
        isFeatureSetsFilled: {
          ...state.isFeatureSetsFilled,
          [featureSetId]: targetedFeatureSet,
        },
      };
    }),
}));

export const useFeatureSetDataHandlingStore = create<IFeatureSetDataState>(
  (set) => ({
    savingDataSet: {},
    setSavingDataSet: (value: FeatureSetStoreDataType) =>
      set({ savingDataSet: value }),
    updateSavingDataSet: (setId: number, featureSetData: IFeatureSetData) =>
      set((state) => ({
        savingDataSet: {
          ...state.savingDataSet,
          [setId]: featureSetData,
        },
      })),

    updatedDataSet: {},
    setUpdatedDataSet: (value: FeatureSetStoreDataType) =>
      set({ updatedDataSet: value }),
  })
);
export const useResearchDataHandlingStore = create<IResearchDataState>(
  (set) => ({
    savingDataSet: {},
    setSavingDataSet: (value: ResearchStoreDataType) =>
      set({ savingDataSet: value }),
    updateSavingDataSet: (setId: number, featureSetData: IResearchData) =>
      set((state) => ({
        savingDataSet: {
          ...state.savingDataSet,
          [setId]: featureSetData,
        },
      })),

    updatedDataSet: {},
    setUpdatedDataSet: (value: ResearchStoreDataType) =>
      set({ updatedDataSet: value }),
  })
);


export const usePixelDataHandlingStore = create<IPixelSetDataStore>((set) => ({
  updatedDataSet: {},
  setUpdatedDataSet: (value: PixelSetStoreDataType) =>
    set({ updatedDataSet: value }),
}));

export const useCheckDependentTargetingSelectedStore =
  create<IDependentTargetingSelectedState>((set) => ({
    isDependentTargetingSelectedForEachSet: {},
    setIsDependentTargetingSelectedForEachSet: (
      value: IsEnableSaveForEachSetType
    ) => set({ isDependentTargetingSelectedForEachSet: value }),
    updateIsDependentTargetingSelectedForEachSet: (
      setId: number,
      isSelected: boolean
    ) =>
      set((state) => ({
        isDependentTargetingSelectedForEachSet: {
          ...state.isDependentTargetingSelectedForEachSet,
          [setId]: isSelected,
        },
      })),
  }));

export const useCheckIfAllMessageAreCreatedStore =
  create<ICheckIfAllMessageAreCreatedState>((set) => ({
    ifAllMessageAreCreated: {},
    setIfAllMessageAreCreated: (value: CheckIfAllMessageCompletedType) =>
      set({ ifAllMessageAreCreated: value }),
    updateIfAllMessageAreCreated: (setId: number, ifCreated: 0 | 1 | 2) =>
      set((state) => ({
        ifAllMessageAreCreated: {
          ...state.ifAllMessageAreCreated,
          [setId]: ifCreated,
        },
      })),
  }));

export const useCheckIfDataModifiedStore = create<ICheckIfDataModifiedState>(
  (set) => ({
    ifDataModified: false,
    setIfDataModified: (value: boolean) => set({ ifDataModified: value }),
  })
);

export const useSaveAudienceActivationStore =
  create<ISaveAudienceActivationState>((set) => ({
    isSaveForActivationInitiated: false,
    setIsSaveForActivationInitiated: (value: boolean) =>
      set({ isSaveForActivationInitiated: value }),

    ifAllMandatoryFieldsPopulated: { audience_size: 0 },
    setIfAllMandatoryFieldsPopulated: (value: ICheckIfAllDataPopulatedType) =>
      set({ ifAllMandatoryFieldsPopulated: value }),
    updateIfAllMandatoryFieldsPopulated: (obj: ICheckIfAllDataPopulatedType) =>
      set((state) => ({
        ifAllMandatoryFieldsPopulated: {
          ...state.ifAllMandatoryFieldsPopulated,
          ...obj,
        },
      })),

    audienceActivationTagDataForSave: null,
    setAudienceActivationTagDataForSave: (value: IActivationTabData | null) =>
      set({ audienceActivationTagDataForSave: value }),
    updateAudienceActivationTagDataForSave: (value: IActivationTabData) =>
      set((state) => ({
        audienceActivationTagDataForSave: {
          ...state.audienceActivationTagDataForSave,
          ...value,
        },
      })),

    updatedAudienceActivationData: null,
    setUpdatedAudienceActivationData: (value: IActivationUpdatedData | null) =>
      set({ updatedAudienceActivationData: value }),
  }));