import Cookies from 'js-cookie';

type UserType = { user_id: number; email: string; timestamp?: number };

const useCookies = () => {
  const createCookie = (user: UserType) => {
    Cookies.set(
      'branch-lab',
      btoa(JSON.stringify({ ...user, timestamp: Date.now() })),
      {
        expires: Number(process.env.REACT_APP_SESSION_ACTIVE_DAY ?? 7),
      }
    );
  };

  const deleteCookie = () => {
    const token = Cookies.get('branch-lab');
    token && Cookies.remove('branch-lab');
  };

  return {
    createCookie,
    deleteCookie,
  };
};

export default useCookies;
