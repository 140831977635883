import React, { Fragment, useEffect, useState } from 'react';
import {
  CommonElementLabel,
  DateRangePicker,
  PageHeader,
} from '../../../sharedComponent';
import PixelListTable from './PixelListTable';
import { Col, Row } from 'antd';
import { DateRangeType, ILoaderState, IPixelListingParam } from '../../../../models/interfaces';
import { usePostApiInterceptor, useRedirection } from '../../../../hooks';
import { useLoaderStore } from '../../../../stores';
import { RoutesEnum } from '../../../../models/enums/apiRoutes';

type PixelBodyType = {
  end_date: string | null;
  start_date: string | null;
  limit: number;
  off_set: number;
  order_column: string | null;
  order_direction: string | null;
};

const baseListingParam = {
  sortOrder: 'DESC',
  sortColumn: 'created_on',
  pageNumber: 1,
  rowsPerPage: 15,
  dateRange: null,
};

const PixelListingWrap = () => {
  const [dateRange, setDateRange] = useState<DateRangeType>(null);
  const { redirectPixelSetup } = useRedirection();
  const [pixelListingParam, setPixelListingParam] =
    useState<IPixelListingParam>(baseListingParam);

  const [body, setBody] = useState<PixelBodyType | null>(null),
  [isMounted, setIsMounted] = useState<boolean>(false),
  [isInitialLoading, setIsInitialLoading] = useState<boolean>(true);

  const { data, isLoading } = usePostApiInterceptor(
    isMounted,
    JSON.stringify(body),
    RoutesEnum.PIXEL_LISTING_DATA,
    () => {
      setIsMounted(false);
    }
  )

  const { loaderState } = useLoaderStore((state: ILoaderState) => state);

  useEffect(() => {
    setIsMounted(true);
  }, [body]);

  useEffect(() => {
    setBody({
      end_date: pixelListingParam.dateRange?.length
        ? pixelListingParam.dateRange[1]
        : null,
      start_date: pixelListingParam.dateRange?.length
        ? pixelListingParam.dateRange[0]
        : null,
      limit: pixelListingParam.rowsPerPage,
      off_set:
        (pixelListingParam.pageNumber - 1) *
        pixelListingParam.rowsPerPage,
      order_column: pixelListingParam.sortColumn,
      order_direction: pixelListingParam.sortOrder,
    });
  }, [pixelListingParam]);

  const updateFilters = () => {
    setPixelListingParam({
      ...pixelListingParam,
      dateRange,
      pageNumber: 1,
    });
  };

  useEffect(() => {
    if (isInitialLoading) {
      setIsInitialLoading(false);
      return;
    }
    updateFilters();
  }, [dateRange]);

  return (
    <div className="audienceCommonWrap">
      <PageHeader title="Pixels" />
      <div className="audienceListingPageContent">
        
        {!loaderState && (
          <Fragment>
            <div className="filterWrap">
              <Row>
                <Col span={12}>
                  <CommonElementLabel
                    label="Created On"
                    specificWrapperClass="pixelListDateRangeFilterWrap"
                  />
                  <DateRangePicker
                    dateRange={dateRange}
                    ifDisabled={false}
                    setDateRange={setDateRange}
                    specificWrapperClass="pixelListDateRangeFilterWrap"
                    props={{ autoFocus: false }}
                  />
                </Col>
              </Row>
              <button
                className="primaryBtn addAudienceBtn"
                onClick={() => redirectPixelSetup()}
              >
                New Pixel
              </button>
            </div>
            <PixelListTable
              pixelData={data}
              isLoading={isLoading}
              paginationAndSortingData={pixelListingParam}
              setPaginationAndSortingData={setPixelListingParam}
            />
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default PixelListingWrap;

