/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import {
  CommonElementLabel,
  DateRangePicker,
  Multi,
  StatusDropdownMulti,
} from '../../../sharedComponent';
import {
  DateRangeType,
  IAudienceListingParam,
  IMasterAudienceTypeDDState,
  IMasterStatusDDState,
} from '../../../../models/interfaces';
import {
  useMasterAudienceTypeElementsStore,
  useMasterStatusElementsStore,
} from '../../../../stores';

const AudienceListingFilter: FC<{
  filterData: IAudienceListingParam;
  setFilterData: React.Dispatch<React.SetStateAction<IAudienceListingParam>>;
}> = ({ filterData, setFilterData }) => {
  const [dateRange, setDateRange] = useState<DateRangeType>(null),
    [selectedAudienceType, setSelectedAudienceType] = useState<
      (number | string)[]
    >([]),
    [selectedStatus, setSelectedStatus] = useState<(number | string)[]>([]),
    [isInitialLoading, setIsInitialLoading] = useState<boolean>(true);

  const { audienceTypeDDElements } = useMasterAudienceTypeElementsStore(
      (state: IMasterAudienceTypeDDState) => state
    ),
    { statusDDElements } = useMasterStatusElementsStore(
      (state: IMasterStatusDDState) => state
    );

  const updateFilters = () => {
    setFilterData({
      ...filterData,
      dateRange,
      audienceType: selectedAudienceType?.length
        ? selectedAudienceType.toString()
        : null,
      status: selectedStatus?.length ? selectedStatus.toString() : null,
      pageNumber: 1,
    });
  };

  useEffect(() => {
    if (isInitialLoading) {
      setIsInitialLoading(false);
      return;
    }
    updateFilters();
  }, [dateRange, selectedAudienceType, selectedStatus]);

  return (
    <Row className='audienceListDateRangeFilterWrap'>
      <Col span={7}>
        <CommonElementLabel
          label="Created On"
        />
        <DateRangePicker
          dateRange={dateRange}
          ifDisabled={false}
          setDateRange={setDateRange}
          props={{ autoFocus: false }}
        />
      </Col>
      <Col span={9}>
        <CommonElementLabel label="Audience Type" />
        <Multi
          options={audienceTypeDDElements}
          placeholder="Select Types"
          onChange={setSelectedAudienceType}
        />
      </Col>
      <Col span={8}>
        <StatusDropdownMulti
          label="Status"
          isMandatory={false}
          options={statusDDElements}
          placeholder="Select Status"
          componentSpecificClasses={['statusTagSelect']}
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
        />
      </Col>
    </Row>
  );
};

export default AudienceListingFilter;
