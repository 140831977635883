/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  FC,
  Fragment,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Select, SelectProps, Spin, Tag, Tooltip } from 'antd';
import { RoutesEnum } from '../../../models/enums/apiRoutes';
import apiService from '../../../services/Api.service';
import {
  DebounceSelectProps,
  IDebounceDropdownElements,
  IEnableSaveState,
  IEsParams,
} from '../../../models/interfaces';
import { useCheckSavingEnabilityStore } from '../../../stores';
import { WarningOutlined } from '@ant-design/icons';

type TagRender = SelectProps['tagRender'];

const debounce = <T extends (...args: any[]) => any>(
  func: T,
  delay: number
) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: Parameters<T>) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func.apply(this, args), delay);
  };
};

function DebounceSelect<
  ValueType extends {
    key?: string;
    label: React.ReactNode;
    value: string | number;
  } = any
>({
  fetchOptions,
  esKeyParam,
  debounceTimeout = 1000,
  ...props
}: Readonly<DebounceSelectProps<ValueType>>) {
  const [fetching, setFetching] = useState(false),
    [options, setOptions] = useState<ValueType[]>([]);
  const fetchRef = useRef(0);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  return (
    <div className="eachCommonElementWrapper debounceDropdownWrapper">
      <Select
        labelInValue
        // maxTagCount={5}
        showSearch={true}
        filterOption={false}
        onSearch={debounceFetcher}
        notFoundContent={fetching ? <Spin size="small" /> : null}
        {...props}
        className="multiSelectDropdown"
        options={options}
        optionRender={({ data }) => renderOptions(data, esKeyParam)}
      />
    </div>
  );
}

const DebounceDropdownSingleSelect: FC<{
  placeholder: string;
  esParams: IEsParams;
  onChange: React.Dispatch<React.SetStateAction<IDebounceDropdownElements[]>>;
  defaultValue?: IDebounceDropdownElements[];
  targetingSetNumber?: number;
  featureSetNumber?: number;
  isDisabled?: boolean;
  index?:number;
}> = ({
  placeholder,
  esParams,
  onChange,
  defaultValue = [],
  targetingSetNumber,
  featureSetNumber,
  isDisabled = false,
  index,
}) => {
  const [value, setValue] = useState<IDebounceDropdownElements[]>(defaultValue);
  const [ifShowError, setIfShowError] = useState<boolean>(false);

  const { isFeatureSetsFilled, updateIisFeatureSetsFilled } =
    useCheckSavingEnabilityStore((state: IEnableSaveState) => state);

  const {
    esIndex,
    esKeyParam,
    esValueParam,
    esMainLabelParam,
    esSubLabelParam1,
    esSubLabelParam2,
    esSearchParam,
  } = esParams;

  const getDropdownOptions = async (
    searchParam: string
  ): Promise<IDebounceDropdownElements[]> => {
    const payload = JSON.stringify({
      index: esIndex,
      query: {
        multi_match: {
          query: searchParam,
          fields: esSearchParam,
          type: 'phrase_prefix',
        },
      },
      size: 50,
    });

    return apiService
      .post<any>(
        process.env.REACT_APP_BASE_URL + RoutesEnum.GET_ES_RESULT,
        payload
      )
      .then((res) => {
        const { body, statusCode } = res;

        if (statusCode === 200) {
          const parsedBody = JSON.parse(body),
            uniqueElem = parsedBody.filter(
              (value: any, index: number, self: any) =>
                index ===
                self.findIndex((t: any) => t[esKeyParam] === value[esKeyParam])
            ),
            tempData = uniqueElem.map((el: any) => ({
              value: formatLabel(el, esValueParam, [], [], '~'),
              label: formatLabel(
                el,
                esMainLabelParam,
                esSubLabelParam1,
                esSubLabelParam2,
                ' '
              ),
              ...el,
            }));
          return tempData;
        } else {
          return [];
        }
      })
      .catch((error) => []);
  };

  const onFocus = () => {
    if (ifShowError) {
      updateIisFeatureSetsFilled(featureSetNumber, targetingSetNumber, 0);
    }
  };

  useEffect(() => {
   
    if (
      featureSetNumber &&
      targetingSetNumber &&
      isFeatureSetsFilled[`${featureSetNumber}`][`${targetingSetNumber}`] === 1
    ) {
      setIfShowError(true);
      return;
    }
    setIfShowError(false);
  }, [isFeatureSetsFilled]);

  useEffect(() => {
    if (isDisabled) {
      setValue([]);
      onChange([]);
    }
  }, [isDisabled]);

  const handleChange = (newValue: IDebounceDropdownElements[] | IDebounceDropdownElements) => {
    const updatedValue = Array.isArray(newValue) ? newValue : [newValue];
    const updatedData=updatedValue.map((item:any) => ({
      ...item,
      idByIndex: index? index:0
  }));
    setValue(updatedData);
    
    onChange(updatedData);

  };

  return (
    <DebounceSelect
      placeholder={placeholder}
      value={value}
      fetchOptions={getDropdownOptions}
      onChange={handleChange}
      defaultValue={defaultValue}
      popupClassName="dmaSelectionDDOptionWrap"
      esKeyParam={esKeyParam}
      onFocus={onFocus}
      status={ifShowError ? 'error' : ''}
      suffixIcon={
        ifShowError ? <WarningOutlined className="validationErrorIcon" /> : null
      }
      disabled={isDisabled}
    />
  );
};

export default DebounceDropdownSingleSelect;

const getAddedOrDeletedOpt = (c1: any, c2: any) => {
  const map = new Map(),
    updatedC1 = c1.filter((el: any) => Object.keys(el)?.length > 0);

  c2.forEach((el: any) => map.set(el.value, el));

  return updatedC1.find((el: any) => !map.has(el.value));
};

const formatLabel = (
  data: any,
  mp: string[],
  sp1: string[],
  sp2: string[],
  op: string
) => {
  const mainLabelArray: string[] = [],
    subLabelArray1: string[] = [],
    subLabelArray2: string[] = [];

  mp.forEach((el) => {
    const d = data[el];
    if (d) {
      mainLabelArray.push(d);
    }
  });

  if (sp1?.length) {
    sp1.forEach((el) => {
      const d = data[el];
      if (d) {
        subLabelArray1.push(d);
      }
    });
  }

  if (sp2?.length) {
    sp2.forEach((el) => {
      const d = data[el];
      if (d) {
        subLabelArray2.push(d);
      }
    });
  }

  const mainLabel = mainLabelArray.join(op),
    subLabel1 = subLabelArray1?.length ? '~' + subLabelArray1.join(' ') : '',
    subLabel2 = subLabelArray2?.length
      ? '~(' + subLabelArray2.join(' ') + ')'
      : '';

  return mainLabel + subLabel1 + subLabel2;
};

const renderOptions = (data: any, key: string) => {
  const { label } = data,
    labelArr = label?.split('~');

  return (
    <div title={label?.replaceAll('~', ' ')}>
      <div className="debounceDDOptionElemWrap">
        <div>{data[key]}:</div>
        <div>{labelArr[0]}</div>
      </div>
      {labelArr[1] ? (
        <div>
          <i>{labelArr[1]}</i> {labelArr[2]}
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

const renderTag: TagRender = (data) => {
  const { label, value, closable, onClose } = data,
    showingLabel =
      value?.split('~')[0] + ': ' + String(label)?.replaceAll('~', ' ');

  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tooltip
      title={<TooltipHtml ov={[data]} />}
      overlayStyle={{ maxWidth: '70%' }}
    >
      <Tag
        className="debounceDDTagWrapper"
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
      >
        {showingLabel}
      </Tag>
    </Tooltip>
  );
};

const renderTooltip = (omittedValues: any) => (
  <Tooltip
    title={<TooltipHtml ov={omittedValues} />}
    overlayStyle={{ maxWidth: '70%' }}
  >
    <span className="multiSelectTooltip">
      + {omittedValues?.length ?? 0} ...
    </span>
  </Tooltip>
);

const TooltipHtml: FC<{ ov: any }> = ({ ov }) => (
  <Fragment>
    {ov.map((el: any) => {
      const id = el.value?.split('~')[0];

      return (
        <div key={el.value} className="debounceDDOptionElemWrap">
          <div>{id}:</div>
          <div>{el?.label?.replaceAll('~', ' ')}</div>
        </div>
      );
    })}
  </Fragment>
);
