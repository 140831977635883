import React, { Dispatch, SetStateAction } from 'react';
import PageHeader from '../../sharedComponent/PageHeader/PageHeader';
import NewFeatureSetsWrap from './NewResearchElasticSearchComponents/NewFeatureSetsWrap';
interface AddResearchComponentProps {
  pageHeader: string;
  title: string;
  setTitle: Dispatch<SetStateAction<string>>;
}

const AddResearchComponent: React.FC<AddResearchComponentProps> = ({
  pageHeader,
  title,
  setTitle,
}) => {
  return (
    <div className="audienceCommonWrap">
      <PageHeader title={pageHeader} />
      <NewFeatureSetsWrap title={title} setTitle={setTitle} />
    </div>
  );
};

export default AddResearchComponent;
