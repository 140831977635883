import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';

class ApiService {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: 'url' as string,
      headers: {
        'Content-Type': 'application/json',
        // Authorization: `Bearer authtoken`,
      },
    });
  }

  private async handleError<T>(
    config: AxiosRequestConfig,
    error: AxiosError
  ): Promise<T> {
    throw error;
  }

  private async request<T>(config: AxiosRequestConfig): Promise<T> {
    try {
      const xApiKey = process.env.REACT_APP_API_KEY;
      const headers = {
        'Content-Type': 'application/json',
        'x-api-key': xApiKey,
        user: '',
      };

      const response: AxiosResponse<T> = await this.axiosInstance.request<T>({
        ...config,
        headers,
      });
      return response.data;
    } catch (error) {
      return this.handleError(config, error as AxiosError);
    }
  }

  public async get<T>(
    url: string,
    params?: any,
    config?: AxiosRequestConfig
  ): Promise<T> {
    return this.request<T>({
      ...config,
      url,
      method: 'get',
      params,
    });
  }

  public async post<T>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<T> {
    return this.request<T>({
      ...config,
      url,
      method: 'post',
      data: {
        stageVariables: {
          environment: process.env.REACT_APP_ENV,
        },
        body: data ?? '{}',
      },
    });
  }

  public async patch<T>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<T> {
    return this.request<T>({
      ...config,
      url,
      method: 'patch',
      data,
    });
  }

  public async put<T>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<T> {
    return this.request<T>({
      ...config,
      url,
      method: 'put',
      data,
    });
  }
}

const apiService = new ApiService();
export default apiService;
