import { FC, Fragment, useEffect, useState } from 'react';
import { AudienceListingFilter, ListingTable } from './';
import { PageHeader } from '../../../sharedComponent';
import { usePostApiInterceptor, useRedirection } from '../../../../hooks';
import { RoutesEnum } from '../../../../models/enums/apiRoutes';
import {
  IAudienceListingParam,
  ILoaderState,
} from '../../../../models/interfaces';
import { useLoaderStore } from '../../../../stores';

type AudienceBodyType = {
  audience_type: string | null;
  end_date: string | null;
  start_date: string | null;
  status_id: string | null;
  limit: number;
  off_set: number;
  order_column: string | null;
  order_direction: string | null;
  search: string | null;
  title: string | null;
  brand : string | null;
  agency : string | null;
  advertiser : string | null;
};

const baseListingParam = {
  sortOrder: 'DESC',
  sortColumn: 'created_on',
  pageNumber: 1,
  rowsPerPage: 15,
  dateRange: null,
  audienceType: null,
  status: null,
  agency : null,
  brand : null,
  advertiser : null
};

const AudienceListingWrap: FC = () => {
  const [audienceListingParam, setAudienceListingParam] =
    useState<IAudienceListingParam>(baseListingParam);

  const [body, setBody] = useState<AudienceBodyType | null>(null),
    [isMounted, setIsMounted] = useState<boolean>(false);

  const { data, isLoading } = usePostApiInterceptor(
    isMounted,
    JSON.stringify(body),
    RoutesEnum.AUDIENCE_LISTING_DATA,
    () => {
      setIsMounted(false);
    }
  ),
    { redirectAudienceSetup } = useRedirection();

  const { loaderState } = useLoaderStore((state: ILoaderState) => state);

  useEffect(() => {
    setIsMounted(true);
  }, [body]);

  useEffect(() => {
    setBody({
      audience_type: audienceListingParam.audienceType,
      end_date: audienceListingParam.dateRange?.length
        ? audienceListingParam.dateRange[1]
        : null,
      start_date: audienceListingParam.dateRange?.length
        ? audienceListingParam.dateRange[0]
        : null,
      status_id: audienceListingParam.status,
      limit: audienceListingParam.rowsPerPage,
      off_set:
        (audienceListingParam.pageNumber - 1) *
        audienceListingParam.rowsPerPage,
      order_column: audienceListingParam.sortColumn,
      agency : audienceListingParam.agency,
      brand : audienceListingParam.brand,
      order_direction: audienceListingParam.sortOrder,
      search: null,
      title: null,
      advertiser : null
    });
  }, [audienceListingParam]);

  return (
    <div className="audienceCommonWrap">
      <PageHeader title="Audiences" />
      <div className="audienceListingPageContent">
        
        {!loaderState && (
          <Fragment>
            <div className="filterWrap">
              <AudienceListingFilter
                filterData={audienceListingParam}
                setFilterData={setAudienceListingParam}
              />
              <button
                className="primaryBtn addAudienceBtn"
                onClick={() => redirectAudienceSetup()}
              >
                New Audience
              </button>
            </div>
            <ListingTable
              audData={data}
              isLoading={isLoading}
              paginationAndSortingData={audienceListingParam}
              setPaginationAndSortingData={setAudienceListingParam}
            />
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default AudienceListingWrap;
