/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ErrorPage } from './components/pages';
import { AuthRoutes, UnAuthRoutes } from './routes';
import { ConfigProvider, Spin } from 'antd';
import { useLoaderStore, useUserInformationStore } from './stores';
import { ILoaderState, IUserInformationState } from './models/interfaces';
import { useCookies } from './hooks';

import '../src/assets/css/custom.css';
import '../src/assets/css/dev.css';

const App: FC<{}> = () => {
  const [updateCookieCounter, setUpdateCookieCounter] = useState<any>(null);

  const { createCookie, deleteCookie } = useCookies();

  const {
      loaderState,
      updateLoaderState,
      showPageLoaderState,
      showBlankLoaderState,
    } = useLoaderStore((state: ILoaderState) => state),
    { userInformation, userIsLoggedIn } = useUserInformationStore(
      (state: IUserInformationState) => state
    );

  const cookieCreation = () => {
    deleteCookie();

    const { user_id, email } = userInformation;

    if (user_id && email) {
      const newUser = { user_id, email };
      createCookie(newUser);
    }
  };

  useEffect(() => {
    if (userIsLoggedIn) {
      setUpdateCookieCounter(
        setInterval(
          cookieCreation,
          1000 *
            60 *
            10 *
            (Number(process.env.REACT_APP_SESSION_ACTIVE_DAY ?? 7) * 24 * 6 - 1)
        )
      );
      return;
    }

    if (!userIsLoggedIn && updateCookieCounter) {
      clearInterval(updateCookieCounter);
      setUpdateCookieCounter(null);
    }
  }, [userIsLoggedIn]);

  useEffect(() => {
    const bodyElement = document.querySelector('body');

    if (
      loaderState ||
      updateLoaderState ||
      showPageLoaderState ||
      showBlankLoaderState
    ) {
      if (!bodyElement?.classList.contains('disableScroll')) {
        bodyElement?.classList.add('disableScroll');
      }
    } else if (bodyElement?.classList.contains('disableScroll')) {
      bodyElement?.classList.remove('disableScroll');
    }
  }, [
    loaderState,
    updateLoaderState,
    showPageLoaderState,
    showBlankLoaderState,
  ]);

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: '"Inter", sans-serif',
        },
      }}
    >
      <Spin
        className='blankLoaderWrapper'
        spinning={
          loaderState ||
          updateLoaderState ||
          showPageLoaderState ||
          showBlankLoaderState
        }
        fullscreen
      ></Spin>
      <BrowserRouter>
        <Routes>
          {AuthRoutes}
          {UnAuthRoutes}
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  );
};

export default App;
